import { TimeFormattingPipe } from '../../../common-pipes/time-formatting';
import { diningEventObject } from './diningEventObject';
import { DiningObject } from './DiningObject';
import { VisitingChef } from './VisitingChef';

/**
 * @class This model contains all of the dining objects given to us through our service call.
 */
export class AllDiningObject {
  allDining: DiningObject[] = [];
  visitingChefs: VisitingChef[] = [];
  timePipe: TimeFormattingPipe = new TimeFormattingPipe();

  /**
   * This constructor decides if it's today or any other day in the future, and then calls this.mapData or
   * this.updateMappedData, respectively.
   * @param data
   * @param {string} date
   */
  constructor(data, date?: string) {
    let isUpdate;
    if (date && this.parseDate(date).toDateString() == new Date().toDateString()) {
      isUpdate = false;
    } else {
      isUpdate = true;
    }

    for (let loc in data.locations) {
      let newDining = new DiningObject(data.locations[loc], isUpdate, date);
      if (data.hasOwnProperty('eventMap') && data.hasOwnProperty('menuMap')) {
        if (!isUpdate) {
          newDining.events = [];
          newDining.mapData(data.eventMap[newDining.locationId], data.menuMap[newDining.locationId], date);
        } else {
          DiningObject.updateMappedData(
            newDining,
            data.eventMap[newDining.locationId],
            data.menuMap[newDining.locationId],
            date
          );
        }
      }

      if (date) {
        newDining.eventSelectedDate = date;
      }
      this.allDining.push(newDining);
    }

    this.extractVisitingChefs();
  }

  /**
   * Searches in the events array of the dining objects to see if visiting chefs is present.
   *
   * If it is, it calls this.addVisitingChef
   */
  private extractVisitingChefs(): void {
    for (let diningLoc of this.allDining) {
      for (let event of diningLoc.events) {
        /*if (event.name && event.name.indexOf("Visiting Chef") > -1){
          this.addVisitingChef(event, diningLoc.locationId)
        }*/

        if (event.categories['Visiting Chef']) {
          console.log(diningLoc.name);
          for (let vChef of event.categories['Visiting Chef']) {
            this.addVisitingChef(diningLoc, event, diningLoc.locationId, new Array(vChef.name));
          }
        }
        if (event.categories['Visiting Chefs']) {
          this.addVisitingChef(
            diningLoc,
            event,
            diningLoc.locationId,
            new Array(event.categories['Visiting Chefs'][0].name)
          );
        }
        if (event.categories['Visiting Chef - Dinner']) {
          this.addVisitingChef(
            diningLoc,
            event,
            diningLoc.locationId,
            new Array(event.categories['Visiting Chef - Dinner'][0].name)
          );
        }
        if (event.categories['Visiting Chef - Lunch']) {
          this.addVisitingChef(
            diningLoc,
            event,
            diningLoc.locationId,
            new Array(event.categories['Visiting Chef - Lunch'][0].name)
          );
        }
      }
    }
  }

  /**
   * Calls this.isDuplicate and if it's not a duplicate it will then set the attributes of visit {} and create a new visiting
   * chef wih these details and push it onto the this.visitingChefs array.
   *
   * @param {diningEventObject} event
   * @param locationId
   */
  private addVisitingChef(diningLoc, event, locationId, visitingChefNames: Array<any>): void {
    let found = false;
    let chefVisitPattern = /^[ ]*(Visiting)[ ]+(Chef)(([ ]+[-])|([-]|[:]))+/;

    let visit: any = {};

    let visitStartTime;
    let visitEndTime;

    if (!this.isDuplicate(event, locationId)) {
      //visit.name = diningLoc.name.replace(chefVisitPattern, '');
      visit.name = diningLoc.name + '\n';
      visit.visitingChefLocationNames = visitingChefNames;
      visit.timeRange = this.timePipe.transform(event.startTime, event.endTime);
      visit.locationId = locationId;
      this.visitingChefs.push(new VisitingChef(visit));
    } else {
      for (let i = 0; i < this.visitingChefs.length; i++) {
        if (this.visitingChefs[i].locationId === locationId) {
          this.visitingChefs[i].visitingChefLocationNames.push(visitingChefNames[0]);
        }
      }
    }
  }

  /**
   * Returns whether or not this event and given locationID are already present in the visiting chefs array.
   * @param event
   * @param locationId
   * @returns {boolean}
   */
  private isDuplicate(event, locationId): boolean {
    let found = false;

    for (let visitingChef of this.visitingChefs) {
      if (visitingChef.locationId == locationId) {
        found = true;
        break;
      }
    }

    return found;
  }

  /**
   * This function transforms a date string into a date object, accounting for time zone.
   * @param {string} date a Date string
   * @returns {Date} a Date object
   */
  private parseDate(date: string): Date {
    let dateObj;
    dateObj = new Date(date);
    dateObj = new Date(dateObj.getTime() - dateObj.getTimezoneOffset() * -60000);
    return dateObj;
  }
}
