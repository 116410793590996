/**
 * Created by kjsisd1 on 11/30/17.
 */
import { Component, Input } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AppService } from '../app.service';

@Component({
  selector: 'check-maintenance',
  templateUrl: './check-maintenance.template.html',
  styleUrls: ['./check-maintenance.style.css'],
})
export class CheckMaintenanceComponent {
  /**
   * Whether or not we are currently routed to a SIS dependent service.
   * @type {boolean}
   */
  sisUrl: boolean = false;
  /**
   * Whether or not we are currently routed to a CLiC dependent service.
   * @type {boolean}
   */
  clicUrl: boolean = false;
  /**
   * Whether or not we are currently routed to an eServices dependent service.
   * @type {boolean}
   */
  eServicesUrl: boolean = false;

  /**
   * For getting name of page for page level takedowns
   */

  @Input() pageName: String;

  /**
   * This constructor keeps track of the routing going on in the application in order to know when or when not to display
   * different types of maintenance pages
   *
   * **IMPORTANT: You must add new features as else if cases if you want maintenance to continue to work with all new features.
   * @param router
   * @param appService
   */
  constructor(router: Router, public appService: AppService) {
    router.events.subscribe((event) => {
      const LANDING_SLASH: string = '/landing';
      const ALT_LANDING: string = '/';
      if (event instanceof NavigationStart || event instanceof NavigationEnd) {
        // external service independent URLs
        if (event.url === LANDING_SLASH) {
          this.sisUrl = false;
          this.clicUrl = false;
          this.eServicesUrl = false;
        } else if (event.url === ALT_LANDING) {
          this.sisUrl = false;
          this.clicUrl = false;
          this.eServicesUrl = false;
        } else if (event.url === '/api/gpa-calc') {
          this.sisUrl = false;
          this.clicUrl = false;
          this.eServicesUrl = false;
        } else if (event.url === '/api/resources') {
          this.sisUrl = false;
          this.clicUrl = false;
          this.eServicesUrl = false;
        }

        // SIS dependent URLs
        else if (event.url === '/api/class-search') {
          this.sisUrl = true;
          this.clicUrl = false;
          this.eServicesUrl = false;
        } else if (event.url === '/login_shib/tc/enrollment') {
          this.sisUrl = true;
          this.clicUrl = false;
          this.eServicesUrl = false;
        } else if (event.url === '/api/class-compare') {
          this.sisUrl = true;
          this.clicUrl = false;
          this.eServicesUrl = false;
        } else if (event.url === '/login_shib/tc/academics') {
          this.sisUrl = true;
          this.clicUrl = false;
          this.eServicesUrl = false;
        }

        // CLiC dependent URLs
        else if (event.url === '/login_shib/tc/hours-and-locations') {
          this.sisUrl = false;
          this.clicUrl = true;
          this.eServicesUrl = false;
        }

        // eServices dependent URLs
        else if (event.url === '/login_shib/tc/dining-balances') {
          this.sisUrl = false;
          this.clicUrl = false;
          this.eServicesUrl = true;
        }
        // add more else if conditions for new features implemented
      }
    });
  }
}
