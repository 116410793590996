/**
 * @author Created by sebisd on 6/19/17.
 */

import { Occurence } from '../../../ModelInterfaces/Occurence';
import { menuItem } from './MenuObject';
const allDays = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

/**
 * @class This model contains all things associated with an even in dining, including the logic used to determine if they
 * are open.
 */
export class diningEventObject extends Occurence {
  eventId = null;
  daysOfWeek: string[] = null;
  open = null;
  divHidden = false;
  infinite = false;
  menuTypes = [];
  categories = {};
  openNow: number = 0;
  exceptions: any[] = [];

  /**
   * This constructor instantiates the variables.
   *
   * @param data
   */
  constructor(data) {
    super(data);
    this.daysOfWeek = data.daysOfWeek;
    this.open = data.open;

    if (data.hasOwnProperty('eventId')) {
      this.eventId = data.eventId;
    } else {
      this.eventId = data.id;
      this.infinite = data.infinite;
      this.menuTypes = data.menuTypes;
      this.exceptions = data.exceptions;
    }
  }

  /*
   * 0 = Gracefully Fail
   * 1 = Closed
   * 2 = Closing Soon (closing within 30 mins)
   * 3 = Open
   */
  /**
   * This function determines the open status of the dining object at the event level (for a singular event).
   * @param {Date} day
   * @returns {number} An integer as follows:
   * > * 0 => Gracefully Fail
   * > * 1 => Closed
   * > * 2 => Closing Soon
   * > * 3 => Open
   */
  determineEventOpen(day: Date): number {
    let dayOfWeek = allDays[day.getDay()];

    let currHour = day.getHours();
    let currMin = day.getMinutes();

    let startTimeHour = Number(this.startTime.split(':')[0]);
    let startTimeMin = Number(this.startTime.split(':')[1]);
    let endTimeHour = Number(this.endTime.split(':')[0]);
    let endTimeMin = Number(this.endTime.split(':')[1]);

    if (this.daysOfWeek.length == 0) {
      // oops
      this.openNow = 0;
      return 0;
    }

    if (endTimeHour < startTimeHour) {
      endTimeHour += 24;
    }

    if (this.daysOfWeek.indexOf(dayOfWeek) !== -1) {
      if (currHour < startTimeHour) {
        // go to sleep bruh, closed
        this.openNow = 1;
        return 1;
      } else if (currHour === startTimeHour) {
        if (currMin < startTimeMin) {
          // closed, but opening soon
          this.openNow = 1;
          return 1;
        } else {
          // open
          this.openNow = 3;
          return 3;
        }
      } else if (currHour > startTimeHour) {
        if (currHour < endTimeHour) {
          if (endTimeHour - currHour === 1) {
            if (currMin > endTimeMin) {
              let temp = currMin - endTimeMin;
              if (temp >= 30) {
                // closing soon
                this.openNow = 2;
                return 2;
              } else {
                // open
                this.openNow = 3;
                return 3;
              }
            } else {
              // closed
              this.openNow = 1;
              return 1;
            }
          } else {
            // open
            this.openNow = 3;
            return 3;
          }
        } else if (currHour === endTimeHour) {
          if (currMin < endTimeMin) {
            let temp = endTimeMin - currMin;
            if (temp <= 30) {
              // closing soon
              this.openNow = 2;
              return 2;
            } else {
              // open
              this.openNow = 3;
              return 3;
            }
          } else {
            // closed
            this.openNow = 1;
            return 1;
          }
        } else if (currHour > endTimeHour) {
          // closed
          this.openNow = 1;
          return 1;
        }
      }
    } else {
      // closed this day
      this.openNow = 1;
      return 1;
    }
  }

  /**
   * Checks if the date passed in is included in the days that an event is occurring (e.g. if today is Tue and the event
   * is open Mon/Wed/Fri, then this function will return *false*)
   *
   * @param day
   * @returns {boolean}
   */
  hasDayOfWeek(day): boolean {
    let dayOfWeek = allDays[day.getDay()];
    return this.daysOfWeek.indexOf(dayOfWeek) > -1;
  }

  /**
   * This function loops through the array received from the http response and passes the data to create a new menuItem
   * for each instance.
   * @param data
   */
  mapMenuData(data) {
    for (let item of data) {
      if (item.eventId === this.eventId) {
        if (this.categories.hasOwnProperty(item.category)) {
          this.categories[item.category].push(new menuItem(item));
        } else {
          this.categories[item.category] = [];
          this.categories[item.category].push(new menuItem(item));
        }
      }
    }
  }
}
