/**
 * Created by jtkisd on 10/30/17.
 */

export class FinalGradesObject {
  academicFinalGrades = null;

  constructor(data) {
    this.academicFinalGrades = data;
  }

  static update(newValue, object) {
    object.academicFinalGrades = newValue.gradeClasses;

    return object;
  }
}
