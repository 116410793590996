import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './LandingPage/landing-page.component';
import { LockoutComponent } from './Lockout/lockout.component';
import { CheckMaintenanceComponent } from './Maintenance/check-maintenance.component';
import { PageNotFoundComponent } from './PageNotFound/page-not-found.component';
import { HomepageResolver } from './Resolvers/HomepageResolver';
import { TooManyReqComponent } from './TooManyRequests/toomanyreq.component';

/**
 * Defines the url paths for all the features in Tiger Center, with their components attached.
 * > Note: *IMPORTANT: ALWAYS LEAVE THE WILDCARD PATH ** AS THE LAST PATH DEFINED IN THIS LIST*
 */
const routes: Routes = [
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  { path: 'landing', component: LandingPageComponent, resolve: { auth: HomepageResolver } },
  { path: 'maintenance', component: CheckMaintenanceComponent },
  { path: 'lockout', component: LockoutComponent },
  { path: 'slowdown', component: TooManyReqComponent },
  { path: 'api', loadChildren: () => import('./Modules/unauthenticated.module').then((m) => m.UnauthenticatedModule) },
  {
    path: 'login_shib/tc',
    loadChildren: () => import('./Modules/authenticated.module').then((m) => m.AuthenticatedModule),
  },
  // IMPORTANT: You must leave the following wildcard path as the last path no matter what. You've been warned.
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
  providers: [HomepageResolver],
})
export class AppRoutingModule {}
