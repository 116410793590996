// Module Imports
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule } from 'ng2-charts';
import { Ng2CompleterModule } from 'ng2-completer';
import { Daterangepicker } from 'ng2-daterangepicker';
import { KonamiModule } from 'ngx-konami';
import { NgxPaginationModule } from 'ngx-pagination';
import { AcademicsService } from './Academics/academics.service';
import { AppRoutingModule } from './app-routing.module';
// Component Imports
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { ClassCompareService } from './ClassCompare/class-compare.service';
import { ClassSearchService } from './ClassSearch/class-search.service';
import { CourseMapService } from './ClassSearch/CourseMapping/course-map.service';
import { ClassSearchDialogsModule } from './ClassSearch/Dialogs/class-search.dialogs.module';
import { DiningBalancesService } from './DiningBalances/dining-balances.service';
import { ClassScheduleService } from './Enrollment/ClassSchedule/class-schedule.tab.service';
import { EnrollmentService } from './Enrollment/enrollment.service';
import { TextbookService } from './Enrollment/textbook.service';
import { GoogleAnalyticsEventsService } from './GoogleAnalytics/google-analytics-events-service';
import { HomepageService } from './Homepage/homepage.service';
// Service and Pipe Imports
import { DiningHoursService } from './HoursAndLocations/Dining/dining.tab.service';
import { LabDataService } from './HoursAndLocations/Labs/labs.tab.service';
import { StudentAffairsDataService } from './HoursAndLocations/StudentAffairs/student-affairs.tab.service';
import { HTTPInterceptorService } from './HTTPInterceptor/HTTPInterceptor.service';
import { RxJsInterceptor } from './HTTPInterceptor/RxJsInterceptor.service';
import { LandingPageComponent } from './LandingPage/landing-page.component';
import { StarWarsComponent } from './LandingPage/StarWars/star-wars.component';
import { LockoutComponent } from './Lockout/lockout.component';
import { EssentialModule } from './Modules/essential.module';
import { SharedModule } from './shared.module';
import { UserInfoService } from './UserInfo/user.info.service';

@NgModule({
  declarations: [AppComponent, LandingPageComponent, StarWarsComponent, LockoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    ClassSearchDialogsModule,
    MatButtonModule,
    MatInputModule,
    MatTabsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatInputModule,
    MatTooltipModule,
    MatListModule,
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatRadioModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    SharedModule,
    KonamiModule,
    NgxPaginationModule,
    Ng2CompleterModule,
    ChartsModule,
    Daterangepicker,
    MatDatepickerModule,
    MatNativeDateModule,
    EssentialModule,
    MatCardModule,
  ],
  providers: [
    AppService,
    DiningHoursService,
    LabDataService,
    StudentAffairsDataService,
    UserInfoService,
    ClassSearchService,
    EnrollmentService,
    DiningBalancesService,
    HomepageService,
    ClassCompareService,
    AcademicsService,
    TextbookService,
    ClassScheduleService,
    CurrencyPipe,
    DatePipe,
    GoogleAnalyticsEventsService,
    CourseMapService,
    HTTPInterceptorService,
    RxJsInterceptor,
    { provide: HTTP_INTERCEPTORS, useClass: RxJsInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
