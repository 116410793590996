/**
 * Created by kjsisd1 on 10/26/17.
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import axios from 'axios';
import { Observable } from 'rxjs/Observable';
import { shareReplay, startWith } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { HTTPInterceptorService } from './HTTPInterceptor/HTTPInterceptor.service';
import { CurrTermResponse } from './ModelInterfaces/CurrTermResponse';
import { LinksResponse } from './ModelInterfaces/LinksResponse';

const appCurrAndFutureTermServiceAPI = axios.create({
  baseURL: environment.TC,
});

const appMaintenanceServiceAPI = axios.create({
  baseURL: environment.TC,
});

@Injectable()
export class AppService {
  constructor(httpInterceptor: HTTPInterceptorService, private http: HttpClient) {
    appCurrAndFutureTermServiceAPI.interceptors.response.use(
      (response) => httpInterceptor.responseHandler(response),
      (error) => {
        httpInterceptor.errorHandler(error);
      }
    );

    appMaintenanceServiceAPI.interceptors.response.use(
      (response) => httpInterceptor.responseHandler(response),
      (error) => {
        httpInterceptor.errorHandler(error);
      }
    );
  }

  public currFutTermResObj: any;

  /**
   * This can be accessed by any component by injecting this services and directly accessing the variable.
   * It is the current and future term (i.e. ['2171', '2175'])
   * @type {Array}
   */
  public currFutureTerms: string[] = [];

  /**
   * This can be accessed by any component by injecting this service and directly accessing the variable.
   * (This was done to mimic the currFutureTerms functionality, but provide the names we are given, instead of building!)
   * It is the current and future term long description (i.e. ['2017-18 Fall (2171)', '2017-18 Spring (2175)'])
   * @type {Array}
   */
  public currFutureTermNames: string[] = [];

  /**
   * This flag is in charge of whether or not the entire application should be shut down in the event of a SEV 1 issue.
   * @type {boolean}
   */
  public forcedDown: boolean = false;

  /**
   * This string is filled by the maintenance message. It may be empty if there isn't a message.
   * @type {string}
   */
  public maintenanceMessage: string = '';

  /**
   * This flag is in charge of whether or not SIS is down for maintenance.
   * @type {boolean}
   */
  public sisDown: boolean = false;
  /**
   * This flag is in charge of whether or not CLiC is down for maintenance.
   * @type {boolean}
   */
  public clicDown: boolean = false;

  /**
   * This flag is in charge of whether or not eServices is down for maintenance.
   * @type {boolean}
   */
  public eservicesDown: boolean = false;

  /**
   * These flags control the running of individual pages
   * @type {boolean}
   */
  public homeDown: boolean = false;
  public searchDown: boolean = false;
  public cartDown: boolean = false;
  public academicsDown: boolean = false;
  public hoursDown: boolean = false;
  public diningDown: boolean = false;
  public gpaDown: boolean = false;
  public compareDown: boolean = false;
  public resourcesDown: boolean = false;
  public mycoursesDown: boolean = false;

  private terms$: Observable<CurrTermResponse> = new Observable<CurrTermResponse>((observer) => {
    appCurrAndFutureTermServiceAPI
      .get('currentTerms')
      .then((response) => {
        this.currFutTermResObj = response.data as CurrTermResponse;
        for (let i = 0; i < this.currFutTermResObj.response.docs.length; i++) {
          this.currFutureTerms[i] = this.currFutTermResObj.response.docs[i].strm;
          this.currFutureTermNames[i] = this.currFutTermResObj.response.docs[i].descr;
        }
        observer.next(this.currFutTermResObj);
        observer.complete();
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            // Service Unavailable
            case 503:
              this.sisDown = true;
              break;
          }
        }

        console.error(err);
        observer.error(err);
      });
  }).pipe(shareReplay(1));

  /**
   * Makes a call to the terms endpoint and stores the current and future term in a string array *currFutureTerms*
   * @returns {Observable}
   */
  getCurrAndFutureTerms(): Observable<CurrTermResponse> {
    return this.terms$;
  }
  private sisLink$: Observable<any> = this.http
    .get(environment.TCAuth + 'SIS-links', { responseType: 'text' })
    .pipe(shareReplay(1));
  /**
   * Makes a call to the sisLinks endpoint and stores the sisLink as a string *sisLinks*
   * @returns {Observable}
   */
  getSISLinks(): Observable<any> {
    console.log(this.sisLink$);
    return this.sisLink$;
  }

  /**
   * The API has a URL endpoint that returns all of the links utilized by the website as a LinksResponse object
   * To use this in components, you can subscribe to getLinks() inside of its ngOnInit() and set a local
   * LinksResponse object equal to the response
   */
  links$: Observable<LinksResponse> = this.http
    .get(environment.TC + 'url', { responseType: 'json' })
    .pipe(startWith({}), shareReplay(1));

  getLinks(): Observable<LinksResponse> {
    return this.links$;
  }

  /**
   * Makes a call to the maintenance endpoint and returns the four booleans regarding the different types of maintenance flags
   * > Note: These can be changed in the application.yml file (or, maybe sometime in the future, via a database).
   * @returns {Observable}
   */
  getMaintenanceFlags(): Observable<any> {
    return new Observable((observer) => {
      appMaintenanceServiceAPI
        .get('maintenance')
        .then((response) => {
          this.forcedDown = response.data.allDown;
          this.maintenanceMessage = response.data.maintenanceMessage;
          this.sisDown = response.data.sisDown;
          this.clicDown = response.data.clicDown;
          this.eservicesDown = response.data.eservicesDown;
          this.homeDown = response.data.homeDown;
          this.searchDown = response.data.searchDown;
          this.cartDown = response.data.cartDown;
          this.academicsDown = response.data.academicsDown;
          this.hoursDown = response.data.hoursDown;
          this.diningDown = response.data.diningDown;
          this.gpaDown = response.data.gpaDown;
          this.compareDown = response.data.compareDown;
          this.resourcesDown = response.data.resourcesDown;
          this.mycoursesDown = response.data.mycoursesDown;
          observer.complete();
        })
        .catch((err) => {
          console.error(err);
          observer.error(err);
        });
    });
  }

  /**
   * This function takes in an unformatted term (e.g. 2171) and transforms it into a nicely formatted string (e.g. "2017-18 Fall (2171)").
   * > 2171 becomes 2017-18 Fall
   * > 2175 becomes 2017-18 Spring
   * > 2178 becomes 2017-18 Summer
   * @param term
   * @returns {string}
   */
  formatTermString(term: string): string {
    let startYear = term.substring(1, 3);
    let endYear = parseInt(startYear) + 1 + '';
    let semesterId = term.substring(3);
    let semesterName = '';
    switch (semesterId) {
      case '1':
        semesterName = 'Fall';
        break;
      case '5':
        semesterName = 'Spring';
        break;
      case '8':
        semesterName = 'Summer';
        break;
      default:
        semesterName = 'Term ' + semesterId;
    }
    return '20' + startYear + ' - ' + endYear + ' ' + semesterName + ' (' + term + ')';
  }
}
