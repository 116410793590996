/**
 * Created by kjsisd1 on 6/20/17.
 */
import { Pipe, PipeTransform } from '@angular/core';

/*
 * This Pipe takes military time and converts it into a proper AM - PM formatted string
 */

@Pipe({ name: 'timeFormatting' })
/**
 * @class This Angular 4 Pipe is in charge of all time formatting done across all three tabs present in Hours & Locations.
 */
export class TimeFormattingPipe implements PipeTransform {
  /**
   * This function takes in two strings of military time (Start Time and End Time) and converts it to a nicely formatted
   * AM,PM string.
   * @param {string} start
   * @param {string} end
   * @returns {string}
   */
  transform(start: string, end: string): string {
    let startTimeHour = Number(start.split(':')[0]);
    let endTimeHour = Number(end.split(':')[0]);
    let startTimeMin = ('0' + Number(start.split(':')[1])).slice(-2); // pad with zeroes if needed (single digit numbers)
    let endTimeMin = ('0' + Number(end.split(':')[1])).slice(-2); // pad with zeroes if needed (single digit numbers)

    if (startTimeHour < 12 && endTimeHour < 12) {
      // here we check if 12:00AM should be displayed for startTimeHour and/or endTimeHour(e.g. 00:00 in military time).
      if (startTimeHour === 0 && endTimeHour === 0) {
        return startTimeHour + 12 + ':' + startTimeMin + 'AM - ' + (endTimeHour + 12) + ':' + endTimeMin + 'AM';
      } else if (startTimeHour === 0) {
        return startTimeHour + 12 + ':' + startTimeMin + 'AM - ' + (endTimeHour % 12) + ':' + endTimeMin + 'AM';
      } else if (endTimeHour === 0) {
        return (startTimeHour % 12) + ':' + startTimeMin + 'AM - ' + (endTimeHour + 12) + ':' + endTimeMin + 'AM';
      }
      return (startTimeHour % 12) + ':' + startTimeMin + 'AM - ' + (endTimeHour % 12) + ':' + endTimeMin + 'AM';
    } else if (startTimeHour < 12 && endTimeHour >= 12) {
      // here we check if 12:00AM should be displayed for startTimeHour (e.g. 00:00 in military time).
      // also check if 12:00PM should be displayed for endTimeHour (e.g. 12:00 in military time).
      if (startTimeHour === 0 && endTimeHour === 12) {
        return startTimeHour + 12 + ':' + startTimeMin + 'AM - ' + endTimeHour + ':' + endTimeMin + 'PM';
      } else if (startTimeHour === 0) {
        return startTimeHour + 12 + ':' + startTimeMin + 'AM - ' + (endTimeHour % 12) + ':' + endTimeMin + 'PM';
      } else if (endTimeHour === 12) {
        return (startTimeHour % 12) + ':' + startTimeMin + 'AM - ' + endTimeHour + ':' + endTimeMin + 'PM';
      }
      return (startTimeHour % 12) + ':' + startTimeMin + 'AM - ' + (endTimeHour % 12) + ':' + endTimeMin + 'PM';
    } else if (startTimeHour >= 12 && endTimeHour < 12) {
      // here we check if 12:00PM should be displayed for startTimeHour (e.g. 12:00 in military time).
      // also check if 12:00AM should be displayed for endTimeHour (e.g. 00:00 in military time).
      if (startTimeHour === 12 && endTimeHour === 0) {
        return startTimeHour + ':' + startTimeMin + 'PM - ' + (endTimeHour + 12) + ':' + endTimeMin + 'AM';
      } else if (startTimeHour === 12) {
        return startTimeHour + ':' + startTimeMin + 'PM - ' + (endTimeHour % 12) + ':' + endTimeMin + 'AM';
      } else if (endTimeHour === 0) {
        return (startTimeHour % 12) + ':' + startTimeMin + 'PM - ' + (endTimeHour + 12) + ':' + endTimeMin + 'AM';
      }
      return (startTimeHour % 12) + ':' + startTimeMin + 'PM - ' + (endTimeHour % 12) + ':' + endTimeMin + 'AM';
    } else if (startTimeHour >= 12 && endTimeHour >= 12) {
      // here we check if 12:00PM should be displayed for startTimeHour and/or endTimeHour(e.g. 12:00 in military time).
      if (startTimeHour === 12 && endTimeHour === 12) {
        return startTimeHour + ':' + startTimeMin + 'PM - ' + endTimeHour + ':' + endTimeMin + 'PM';
      } else if (startTimeHour === 12) {
        return startTimeHour + ':' + startTimeMin + 'PM - ' + (endTimeHour % 12) + ':' + endTimeMin + 'PM';
      } else if (endTimeHour === 12) {
        return (startTimeHour % 12) + ':' + startTimeMin + 'PM - ' + endTimeHour + ':' + endTimeMin + 'PM';
      }
      return (startTimeHour % 12) + ':' + startTimeMin + 'PM - ' + (endTimeHour % 12) + ':' + endTimeMin + 'PM';
    }
  }
}
