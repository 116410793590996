/**
 * Created by kjsisd1 on 10/26/17.
 */
import { NgModule } from '@angular/core';
import { KeysPipe } from './common-pipes/key-value-extraction';

/**
 * Purpose of this module is to allow for custom made things such as our common pipes to be used in more than one module (e.g. dialogs require their own module)
 */
@NgModule({
  declarations: [KeysPipe],
  exports: [KeysPipe],
})
export class SharedModule {}
