/**
 * Created by kjsisd1 on 7/25/17.
 */
import { Injectable } from '@angular/core';
import axios from 'axios';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';
import { AppService } from '../../app.service';
import { HTTPInterceptorService } from '../../HTTPInterceptor/HTTPInterceptor.service';
import { AllStudentAffairsObj } from './Models/AllStudentAffairsObj';
import { StudentAffairsObject } from './Models/StudentAffairsObject';

const studentAffairsServiceAPI = axios.create({
  baseURL: environment.TC,
  withCredentials: true,
  params: {
    date: null,
    locId: null,
  },
});

@Injectable()
/**
 * @class This service makes a call to the student affairs endpoint, gets the data requested (all locations or only a
 * single) and creates a new AllStudentAffairsObj or StudentAffairsObject, respectively.
 */
export class StudentAffairsDataService {
  constructor(private appService: AppService, httpInterceptor: HTTPInterceptorService) {
    studentAffairsServiceAPI.interceptors.response.use(
      (response) => httpInterceptor.responseHandler(response),
      (error) => {
        httpInterceptor.errorHandler(error);
      }
    );
  }

  /**
   * This function kicks off all Student Affairs locations by getting the response from the endpoint and creating a
   * new AllStudentAffairsObj.
   * @param {string} date The date that the data is valid for.
   * @returns {Observable<any>}
   */
  getStudentAffairsData(date: string): Observable<any> {
    return new Observable((observer) => {
      studentAffairsServiceAPI
        .get('student-affairs-all?date=' + date)
        .then((response) => {
          let resObj = new AllStudentAffairsObj(response.data, date);
          observer.next(resObj);
          observer.complete();
        })
        .catch((err) => {
          switch (err.response.status) {
            // Service Unavailable
            case 503:
              this.appService.clicDown = true;
              break;
          }
          console.error(err);
          observer.error(err);
        });
    });
  }

  /**
   * This function kicks off a single Student Affairs location by getting the response from the endpoint and creating a
   * new StudentAffairsObject.
   * @param {string} date The date that the data is valid for.
   * @param {number} locId The ID associated with the single location
   * @returns {Observable<any>}
   */
  getSingleStudentAffairsData(date: string, locId: number): Observable<any> {
    return new Observable((observer) => {
      studentAffairsServiceAPI
        .get('student-affairs-all')
        .then((response) => {
          let newStudentAffairs = new StudentAffairsObject(response.data[0], date);
          observer.next(newStudentAffairs);
          observer.complete();
        })
        .catch((err) => {
          switch (err.response.status) {
            // Service Unavailable
            case 503:
              this.appService.clicDown = true;
              break;
          }
          console.error(err);
          observer.error(err);
        });
    });
  }
}
