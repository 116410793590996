import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AppService } from '../app.service';
import { AcademicsData } from './ResponseModels/academics-data';
import { DiningHoursData } from './ResponseModels/dining-hours-data';
import { EnrolledEvents } from './ResponseModels/enrolled-events';
import { EnrollmentDatesData } from './ResponseModels/enrollment-dates-data';
import { ImportantDates } from './ResponseModels/important-dates';

@Injectable()
/**
 * @class This service makes a call to the homeData endpoint to grab the necessary information for the homepage
 */
export class HomepageService {
  constructor(private appService: AppService, private http: HttpClient) {}

  getAcademicsData() {
    return this.http.get<AcademicsData>(environment.TCAuth + 'academicsData?');
  }

  getDiningHoursData() {
    return this.http.get<DiningHoursData>(environment.TCAuth + 'diningHoursData?');
  }

  getEnrollmentDatesData() {
    return this.http.get<EnrollmentDatesData>(environment.TCAuth + 'enrollmentDatesData?');
  }

  getEnrollmentDates(currentTerm: string) {
    return this.http.get<ImportantDates>(environment.TCAuth + 'homeEvents?currTerm=' + currentTerm);
  }

  getEnrollmentEventsByTerm(term: string, career: string) {
    return this.http.get<EnrolledEvents>(environment.TCAuth + 'enrolledHomeEvents?term=' + term + '&career=' + career);
  }
}
