import { Component, OnInit } from '@angular/core';
import { AppService } from 'app/app.service';
import { LinksResponse } from 'app/ModelInterfaces/LinksResponse';
import { UserInfoService } from '../UserInfo/user.info.service';
import { UserModel } from './Models/UserModel';

@Component({
  selector: 'user-info',
  templateUrl: './user.info.template.html',
  styleUrls: ['./user.info.style.css'],
})
export class UserInfoComponent implements OnInit {
  userInfo: UserModel | { username: string; firstName: string; lastName: string; universityId: string } = {
    username: 'Public User',
    firstName: 'Public',
    lastName: 'User',
    universityId: null,
  };
  isPublicUser: boolean = true;
  loadingComplete = false;
  loggedIn = false;

  uid = '';
  showingUID: boolean = false;

  links: LinksResponse;

  /**
   * This constructor uses the userInfoService to incorporate shib information into the view.
   * @param {UserInfoService} userInfoService
   */
  constructor(private userInfoService: UserInfoService, private appService: AppService) {}

  ngOnInit() {
    this.setUpComponent();

    this.appService.getLinks().subscribe((links) => (this.links = links));
  }

  setUpComponent() {
    this.userInfoService.getUserInfo().subscribe(
      (res) => {
        this.userInfo = res;
      },
      (error) => {
        if (this.userInfoService.sisLockedUser) {
          this.userInfo = this.userInfoService.sisLockedUser;
          this.isPublicUser = false;
          if (this.userInfo.universityId) {
            this.uid = this.userInfo.universityId;
          }
        } else {
          this.isPublicUser = true;
          this.userInfo = {
            username: 'Public User',
            firstName: 'Public',
            lastName: 'User',
            universityId: null,
          };
        }
        this.loadingComplete = true;
      },
      () => {
        if (this.userInfo.universityId) {
          this.loggedIn = true;
          this.isPublicUser = false;
          this.uid = this.userInfo.universityId;
        }
        this.loadingComplete = true;
      }
    );
  }
}
