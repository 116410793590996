/**
 * Created by sebisd on 7/10/17.
 */
export class LabHardware {
  name: string;
  hardwareId: string;
  descriptions: string[];
  quantity: string;
  macFlag: boolean = false;
  windowFlag: boolean = false;
  //TODO Linux flag? linuxFlag:boolean = false;

  /**
   * This constructor handles the data initialization of the hardware list within a lab and determines whether a
   * printer, windows, and/or mac is/are available within the lab.
   * @param data
   */
  constructor(data) {
    this.name = data['name'];
    this.hardwareId = data['hardware_id'];
    this.descriptions = data['descriptions'];
    this.quantity = data['quantity'];
    if (this.descriptions.length > 0) {
      for (let desc of this.descriptions) {
        let ele = desc.toLowerCase();
        if (ele.includes('window') || ele.includes('pc') || ele.includes('dell') || ele.includes('hp')) {
          this.windowFlag = true;
        }

        if (ele.includes('mac') || ele.includes('apple') || ele.includes('osx') || ele.includes('macintosh')) {
          this.macFlag = true;
        }
      }
    }
  }
}
