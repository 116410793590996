/**
 * Created by kjsisd1 on 9/14/17.
 */

import { ClassSearchObj } from './ClassSearchObj';

/**
 * @class This model contains all of the class search objects given to us through our service call.
 */
export class AllClassSearchObj {
  allClassSearch: ClassSearchObj[] = [];

  constructor(data, currTerm) {
    for (let res in data) {
      let newClassSearch = new ClassSearchObj(data[res], currTerm);
      this.allClassSearch.push(newClassSearch);
    }
  }

  public sortBy(type, invert) {
    if (invert == false) {
      switch (type) {
        case 1:
          this.allClassSearch.sort(this.sortCourseTitle);
          break;
        case 2:
          this.allClassSearch.sort(this.sortStatus);
          break;
        case 3:
          this.allClassSearch.sort(this.sortType);
          break;
        case 4:
          //this.allClassSearch.sort(this.sortAttributes);
          break;
        case 5:
          this.allClassSearch.sort(this.sortCredits);
          break;
        case 6:
          this.allClassSearch.sort(this.sortTime);
          break;
        case 7:
          this.allClassSearch.sort(this.sortLocation);
          break;
        case 8:
          this.allClassSearch.sort(this.sortInstructor);
          break;
        default:
          this.allClassSearch.sort(this.sortComboName);
          break;
      }
    } else if (invert == true) {
      switch (type) {
        case 1:
          this.allClassSearch.sort(this.sortCourseTitleInvert);
          break;
        case 2:
          this.allClassSearch.sort(this.sortStatusInvert);
          break;
        case 3:
          this.allClassSearch.sort(this.sortTypeInvert);
          break;
        case 4:
          //this.allClassSearch.sort(this.sortAttributesInvert);
          break;
        case 5:
          this.allClassSearch.sort(this.sortCreditsInvert);
          break;
        case 6:
          this.allClassSearch.sort(this.sortTimeInvert);
          break;
        case 7:
          this.allClassSearch.sort(this.sortLocationInvert);
          break;
        case 8:
          this.allClassSearch.sort(this.sortInstructorInvert);
          break;
        default:
          this.allClassSearch.sort(this.sortComboNameInvert);
          break;
      }
    }
  }

  /**
   * Compare function
   */
  private sortRes(nameA, nameB) {
    if (nameA > nameB) {
      return 1;
    } else if (nameA < nameB) {
      return -1;
    }
  }

  /**
   * Sort Functions
   * @param a
   * @param b
   */
  private sortCourseTitle(a, b) {
    const nameA = a.courseTitleLong;
    const nameB = b.courseTitleLong;

    return AllClassSearchObj.prototype.sortRes(nameA, nameB);
  }

  private sortStatus(a, b) {
    const nameA = a.enrollmentTotal;
    const nameB = b.enrollmentTotal;

    return AllClassSearchObj.prototype.sortRes(nameA, nameB);
  }

  private sortType(a, b) {
    const nameA = a.componentLong;
    const nameB = b.componentLong;

    return AllClassSearchObj.prototype.sortRes(nameA, nameB);
  }

  private sortAttributes(a, b) {
    const nameA = a.attributeKeys[0];
    const nameB = b.attributeKeys[0];

    return AllClassSearchObj.prototype.sortRes(nameA, nameB);
  }

  private sortCredits(a, b) {
    const nameA = a.minimumUnits;
    const nameB = b.minimumUnits;

    return AllClassSearchObj.prototype.sortRes(nameA, nameB);
  }

  private sortTime(a, b) {
    const nameA = a.meetingsSortDayTimes[0].time;
    const nameB = b.meetingsSortDayTimes[0].time;

    return AllClassSearchObj.prototype.sortRes(nameA, nameB);
  }

  private sortLocation(a, b) {
    const nameA = a.meetingsSortDayTimes[0].locationShort;
    const nameB = b.meetingsSortDayTimes[0].locationShort;

    return AllClassSearchObj.prototype.sortRes(nameA, nameB);
  }

  private sortInstructor(a, b) {
    const nameA = a.meetings[0].instructor;
    const nameB = b.meetings[0].instructor;

    return AllClassSearchObj.prototype.sortRes(nameA, nameB);
  }

  /**
   *   not really used
   */
  private sortComboName(a, b) {
    const nameA = a.combinedName;
    const nameB = b.combinedName;

    return AllClassSearchObj.prototype.sortRes(nameA, nameB);
  }

  private sortCatalogNumber(a, b) {
    const nameA = a.catalogNumber;
    const nameB = b.catalogNumber;

    return AllClassSearchObj.prototype.sortRes(nameA, nameB);
  }

  /**
   * Invert Sort Functions
   * @param a
   * @param b
   */
  private sortCourseTitleInvert(a, b) {
    const nameA = b.courseTitleLong;
    const nameB = a.courseTitleLong;

    return AllClassSearchObj.prototype.sortRes(nameA, nameB);
  }

  private sortStatusInvert(a, b) {
    const nameA = b.enrollmentTotal;
    const nameB = a.enrollmentTotal;

    return AllClassSearchObj.prototype.sortRes(nameA, nameB);
  }

  private sortTypeInvert(a, b) {
    const nameA = b.componentLong;
    const nameB = a.componentLong;

    return AllClassSearchObj.prototype.sortRes(nameA, nameB);
  }

  private sortAttributesInvert(a, b) {
    const nameA = b.attributeKeys[0];
    const nameB = a.attributeKeys[0];

    return AllClassSearchObj.prototype.sortRes(nameA, nameB);
  }

  private sortCreditsInvert(a, b) {
    const nameA = b.minimumUnits;
    const nameB = a.minimumUnits;

    return AllClassSearchObj.prototype.sortRes(nameA, nameB);
  }

  private sortTimeInvert(a, b) {
    const nameA = b.meetingsSortDayTimes[0].time;
    const nameB = a.meetingsSortDayTimes[0].time;

    return AllClassSearchObj.prototype.sortRes(nameA, nameB);
  }

  private sortLocationInvert(a, b) {
    const nameA = b.meetingsSortDayTimes[0].locationShort;
    const nameB = a.meetingsSortDayTimes[0].locationShort;

    return AllClassSearchObj.prototype.sortRes(nameA, nameB);
  }

  private sortInstructorInvert(a, b) {
    const nameA = b.meetings[0].instructor;
    const nameB = a.meetings[0].instructor;

    return AllClassSearchObj.prototype.sortRes(nameA, nameB);
  }

  /**
   *   not really used
   */
  private sortComboNameInvert(a, b) {
    const nameA = b.combinedName;
    const nameB = a.combinedName;

    return AllClassSearchObj.prototype.sortRes(nameA, nameB);
  }

  private sortCatalogNumberInvert(a, b) {
    const nameA = b.catalogNumber;
    const nameB = a.catalogNumber;

    return AllClassSearchObj.prototype.sortRes(nameA, nameB);
  }
}
