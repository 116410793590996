import { Occurence } from '../../../ModelInterfaces/Occurence';

/**
 * @class The exceptionObject model only contains metadata like startTime endTime, daysOfWeek, status, etc...
 * Exceptions are taken into consideration when determining if an event is open.
 */
export class exceptionObject extends Occurence {
  status = null;
  daysOfWeek: string[] = null;

  //static readonly OPEN_EXCEPTION = "Open";
  //static readonly CLOSED_EXCEPTION = "Closed";
  //static readonly RESERVED_EXCEPTION = "Reserved";

  /**
   * This constructor initializes variables.
   * @param data
   */
  constructor(data) {
    super(data);
    this.daysOfWeek = data.daysOfWeek;
    this.status = data.open;
  }
}
