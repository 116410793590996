/**
 * Created by sebisd on 7/10/17.
 */
export class LabSoftware {
  softwareId: string;
  name: string;
  activeFlag: string;

  /**
   * This constructor initializes the variables.
   * @param data
   */
  constructor(data) {
    this.name = data['description'];
    this.softwareId = data['software_id'];
    this.activeFlag = data['active_flag'];
  }
}
