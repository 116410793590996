<div *ngIf="(appService.forcedDown || appService.clicDown || appService.eservicesDown || appService.sisDown)">
  <br />
  <br />
  <div style="text-align: center">
    <img src="./assets/svgLib/TC_MaintenanceLogo.svg" style="width: 65%" alt="Tiger Center" />
    <hr />
  </div>
  <br />

  <!-- Tablet and higher maintenance content -->
  <div class="row hidden-xs">
    <div class="col-xs-3 col-xs-offset-2" style="text-align: center; margin-left: 10%">
      <img
        *ngIf="(appService.forcedDown || appService.clicDown || appService.eservicesDown || appService.sisDown)"
        src="./assets/images/sadTigerNew.png"
        style="width: 70%"
        alt="Sad Tiger"
      />
      <img
        *ngIf="(!appService.forcedDown && !appService.clicDown && !appService.eservicesDown && !appService.sisDown)"
        src="./assets/images/happyTigerNew.png"
        style="width: 70%"
        alt="Happy Tiger"
      />
    </div>

    <div
      *ngIf="(appService.forcedDown || appService.clicDown || appService.eservicesDown || appService.sisDown)"
      class="col-xs-6"
    >
      <div *ngIf="appService.forcedDown" class="alert alert-danger" style="text-align: center">
        Tiger Center is currently unavailable.
      </div>
      <div
        *ngIf="appService.clicDown && (pageName == 'Hours and Locations')"
        class="alert alert-danger"
        style="text-align: center"
      >
        Hours and locations are currently unavailable on TC.
      </div>
      <div
        *ngIf="appService.eservicesDown && (pageName == 'Dining balances')"
        class="alert alert-danger"
        style="text-align: center"
      >
        Dining services are currently unavailable on TC.
      </div>
      <div
        *ngIf="appService.sisDown && (pageName == 'Homepage' || pageName == 'Class search' || pageName == 'Enrollment' || pageName =='Academics' || pageName == 'Class compare')"
        class="alert alert-danger"
        style="text-align: center"
      >
        SIS dependent pages are currently unavailable.
      </div>
      <div
        class="alert alert-danger"
        *ngIf="pageName != null && ((!appService.sisDown) && (pageName == 'Homepage' || pageName == 'Class search' || pageName == 'Enrollment' || pageName =='Academics' || pageName == 'Class compare')) || ((!appService.clicDown) && (pageName == 'Hours and Locations')) || ((!appService.eservicesDown) && (pageName == 'Dining balances')) || pageName == 'GPA calculator' || pageName == 'The resources page'"
        style="text-align: center"
      >
        {{pageName}} is currently unavailable.
      </div>
      <div style="text-align: center" *ngIf="appService.maintenanceMessage != ''">
        {{appService.maintenanceMessage}}
      </div>
    </div>

    <div
      *ngIf="(!appService.forcedDown && !appService.clicDown && !appService.eservicesDown && !appService.sisDown)"
      class="col-xs-6"
    >
      <div class="alert alert-success" style="text-align: center">Tiger Center should be operating normally.</div>
      <div>
        All services and features should be operational. If you have encountered an issue, please submit a report
        through Feedback &amp; Support..
      </div>
    </div>
  </div>

  <!-- Mobile maintenance content -->
  <div class="row hidden-sm hidden-md hidden-lg">
    <div *ngIf="(appService.forcedDown || appService.clicDown || appService.eservicesDown || appService.sisDown)">
      <div
        *ngIf="appService.forcedDown"
        class="alert alert-danger col-xs-10 col-xs-offset-1"
        style="text-align: center"
      >
        Tiger Center is currently unavailable.
      </div>
      <div
        *ngIf="appService.clicDown && (pageName == 'Hours and Locations')"
        class="alert alert-danger col-xs-10 col-xs-offset-1"
        style="text-align: center"
      >
        Hours and locations are currently unavailable on TC.
      </div>
      <div
        *ngIf="appService.eservicesDown && (pageName == 'Dining balances')"
        class="alert alert-danger col-xs-10 col-xs-offset-1"
        style="text-align: center"
      >
        Dining services are currently unavailable on TC.
      </div>
      <div
        *ngIf="appService.sisDown && (pageName == 'Homepage' || pageName == 'Class search' || pageName == 'Enrollment' || pageName =='Academics' || pageName == 'Class compare')"
        class="alert alert-danger col-xs-10 col-xs-offset-1"
        style="text-align: center"
      >
        SIS dependent pages are currently unavailable.
      </div>
      <div
        class="alert alert-danger"
        *ngIf="pageName != null && ((!appService.sisDown) && (pageName == 'Homepage' || pageName == 'Class search' || pageName == 'Enrollment' || pageName =='Academics' || pageName == 'Class compare')) || ((!appService.clicDown) && (pageName == 'Hours and Locations')) || ((!appService.eservicesDown) && (pageName == 'Dining balances')) || pageName == 'GPA calculator' || pageName == 'The resources page'"
        style="text-align: center"
      >
        {{pageName}} is currently unavailable.
      </div>
      <div *ngIf="appService.maintenanceMessage != ''" class="col-xs-10 col-xs-offset-1">
        <div style="text-align: center">
          {{appService.maintenanceMessage}}
          <hr />
        </div>
      </div>
      <div class="col-xs-10 col-xs-offset-1" style="text-align: center">
        <img src="./assets/images/sadTigerNew.png" style="width: 50%" alt="Sad Tiger" />
      </div>
    </div>

    <div *ngIf="(!appService.forcedDown && !appService.clicDown && !appService.eservicesDown && !appService.sisDown)">
      <div class="alert alert-success col-xs-10 col-xs-offset-1" style="text-align: center">
        Tiger Center is operating normally.
      </div>
      <div class="col-xs-10 col-xs-offset-1">
        <div>
          All services and features should be operational. Please submit a ticket using Feedback &amp; Support if found
          to be otherwise.
          <hr />
        </div>
      </div>
      <div class="col-xs-10 col-xs-offset-1" style="text-align: center">
        <img src="./assets/assets/happyTigerNew.png" style="width: 50%" alt="Happy Tiger" />
      </div>
    </div>
    <br />
  </div>
</div>
<div
  *ngIf="(appService.homeDown || appService.searchDown || appService.cartDown || appService.academicsDown || appService.hoursDown || appService.diningDown || appService.gpaDown || appService.compareDown || appService.resourcesDown) && !(appService.forcedDown || appService.clicDown || appService.eservicesDown || appService.sisDown)"
>
  <br />
  <br />
  <div style="text-align: center">
    <img src="./assets/svgLib/TC_MaintenanceLogo.svg" style="width: 65%" alt="Tiger Center" />
    <hr />
  </div>
  <br />

  <!-- Tablet and higher maintenance content -->
  <div class="row hidden-xs">
    <div class="col-xs-3 col-xs-offset-2" style="text-align: center; margin-left: 10%">
      <img
        *ngIf="(appService.homeDown || appService.searchDown || appService.cartDown || appService.academicsDown || appService.hoursDown || appService.diningDown || appService.gpaDown || appService.compareDown || appService.resourcesDown)"
        src="./assets/images/sadTigerNew.png"
        style="width: 70%"
        alt="Sad Tiger"
      />
      <img
        *ngIf="(!appService.homeDown && !appService.searchDown && !appService.cartDown && !appService.academicsDown && !appService.hoursDown && !appService.diningDown && !appService.gpaDown && !appService.compareDown && !appService.resourcesDown)"
        src="./assets/images/happyTigerNew.png"
        style="width: 70%"
        alt="Happy Tiger"
      />
    </div>

    <div
      *ngIf="(appService.homeDown || appService.searchDown || appService.cartDown || appService.academicsDown || appService.hoursDown || appService.diningDown || appService.gpaDown || appService.compareDown || appService.resourcesDown)"
      class="col-xs-6"
    >
      <div class="alert alert-danger" *ngIf="pageName != null" style="text-align: center">
        {{pageName}} is currently unavailable.
      </div>
      <div class="alert alert-danger" *ngIf="pageName == null" style="text-align: center">
        Some pages are currently unavailable.
      </div>
      <div style="text-align: center" *ngIf="appService.maintenanceMessage != ''">
        {{appService.maintenanceMessage}}
      </div>
    </div>

    <div
      *ngIf="(!appService.homeDown && !appService.searchDown && !appService.cartDown && !appService.academicsDown && !appService.hoursDown && !appService.diningDown && !appService.gpaDown && !appService.compareDown && !appService.resourcesDown)"
      class="col-xs-6"
    >
      <div class="alert alert-success" style="text-align: center">Tiger Center should be operating normally.</div>
      <div>
        All services and features should be operational. If you have encountered an issue, please submit a report
        through Feedback &amp; Support..
      </div>
    </div>
  </div>

  <!-- Mobile maintenance content -->
  <div class="row hidden-sm hidden-md hidden-lg">
    <div
      *ngIf="(appService.homeDown || appService.searchDown || appService.cartDown || appService.academicsDown || appService.hoursDown || appService.diningDown || appService.gpaDown || appService.compareDown || appService.resourcesDown)"
    >
      <div class="alert alert-danger col-xs-10 col-xs-offset-1" *ngIf="pageName != null" style="text-align: center">
        {{pageName}} is currently unavailable.
      </div>
      <div class="alert alert-danger col-xs-10 col-xs-offset-1" *ngIf="pageName == null" style="text-align: center">
        Some pages are currently unavailable.
      </div>
      <div *ngIf="appService.maintenanceMessage != ''" class="col-xs-10 col-xs-offset-1">
        <div style="text-align: center">
          {{appService.maintenanceMessage}}
          <hr />
        </div>
      </div>
      <div class="col-xs-10 col-xs-offset-1" style="text-align: center">
        <img src="./assets/images/sadTigerNew.png" style="width: 50%" alt="Sad Tiger" />
      </div>
    </div>

    <div
      *ngIf="(!appService.homeDown && !appService.searchDown && !appService.cartDown && !appService.academicsDown && !appService.hoursDown && !appService.diningDown && !appService.gpaDown && !appService.compareDown && !appService.resourcesDown)"
    >
      <div class="alert alert-success col-xs-10 col-xs-offset-1" style="text-align: center">
        Tiger Center is operating normally.
      </div>
      <div class="col-xs-10 col-xs-offset-1">
        <div>
          All services and features should be operational. Please submit a ticket using Feedback &amp; Support if found
          to be otherwise.
          <hr />
        </div>
      </div>
      <div class="col-xs-10 col-xs-offset-1" style="text-align: center">
        <img src="./assets/assets/happyTigerNew.png" style="width: 50%" alt="Happy Tiger" />
      </div>
    </div>
    <br />
  </div>
</div>
<br />
<br />
<br />
<br />
<br />
