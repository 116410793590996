/**
 * Created by kjsisd1 on 9/13/17.
 */
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs/Rx';
import { AdvancedSearchDialogComponent } from './Advanced Search/advanced-search-dialog.component';
import { CourseCatalogDialogComponent } from './Course Catalog/course-catalog-dialog.component';

@Injectable()
export class ClassSearchDialogsService {
  constructor(private dialog: MatDialog) {}

  /**
   * This function kicks off the advanced search dialog pop-up and passes in the necessary data called **savedParams** which
   * consists of whatever the user previously selected after clicking SAVE.
   *
   * > Note: This dialog popup cannot be closed by clicking away from it...only by clicking CANCEL or SAVE. This was due to
   * > it being tricky to pass data when that event happens.
   *
   * @param title
   * @param savedParams
   * @returns {Observable<any>}
   */
  public advancedSearch(title: string, savedParams): Observable<boolean> {
    let dialogRef: MatDialogRef<AdvancedSearchDialogComponent>;

    dialogRef = this.dialog.open(AdvancedSearchDialogComponent, {
      data: savedParams,
      disableClose: true,
      panelClass: 'advanced-search-modal',
    });

    dialogRef.componentInstance.title = title;

    return dialogRef.afterClosed();
  }

  /**
   * This function kicks off the course catalog dialog pop-up and passes in the necessary data which
   * consists of whatever data was necessary to grab from other endpoints to allow what's displayed to be dynamic in nature.
   * The course catalog multiple async network calls will only happen once, after that all of the data will be saved as
   * **cache** and passed back and forth when opening/reopening the dialog.
   *
   * > Note: This dialog popup cannot be closed by clicking away from it...only by clicking CANCEL or SAVE. This was due to
   * > it being tricky to pass data when that event happens.
   *
   * > Note2: *collegeStep*, *departmentStep*, *xCoord*, and *yCoord* all have to do with the dialog reopening back up to where
   * > the user previously was in Course Catalog. However, there is currently a bug on Angular's end involving the (opened)
   * > and (closed) events on a mat-expansion-panel not emitting using an AOT build of Angular...so this functionality will
   * > **NOT** work when using a production build in the frontend until they fix it. See the following link to the github
   * > discussion: https://github.com/angular/material2/issues/7172#issuecomment-332582894
   *
   * @param title
   * @param terms
   * @param colleges
   * @param dataCache
   * @param collegeStep
   * @param departmentStep
   * @param xCoord
   * @param yCoord
   * @returns {Observable<any>}
   */
  public courseCatalog(
    title: string,
    terms: string[],
    colleges,
    dataCache,
    collegeStep: string,
    departmentStep: string,
    xCoord: number,
    yCoord: number
  ): Observable<boolean> {
    let dialogRef: MatDialogRef<CourseCatalogDialogComponent>;
    let courseCatalogData = {
      terms: terms,
      colleges: colleges,
      cache: dataCache,
      collegeStep: collegeStep,
      departmentStep: departmentStep,
      xCoord: xCoord,
      yCoord: yCoord,
    };
    dialogRef = this.dialog.open(CourseCatalogDialogComponent, {
      data: courseCatalogData,
      disableClose: true,
    });

    dialogRef.componentInstance.title = title;

    return dialogRef.afterClosed();
  }
}
