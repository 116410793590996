/**
 * Created by jtkisd on 10/30/17.
 */

export class GPACalculatorObject {
  academicFinalGrades = null;
  gpaCalcTermStats = null;

  constructor(data) {
    this.academicFinalGrades = data.finalGrades;
    this.gpaCalcTermStats = data.credits;
  }

  static update(newValue, object) {
    object.academicFinalGrades = newValue.gradeClasses;
    object.gpaCalcTermStats = newValue.gradeStatisticsAll;

    return object;
  }
}
