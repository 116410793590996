/*
 * Created by kjsisd1 on 7/25/17.
 */
import { StudentAffairsObject } from './StudentAffairsObject';

/**
 * @class This class contains all student affairs objects.
 */
export class AllStudentAffairsObj {
  allStudentAffairs: StudentAffairsObject[] = [];

  /**
   * Populates allStudentAffairs array with StudentAffairsObjects.
   * @param data
   * @param {string} date
   */
  constructor(data, date?: string) {
    if (date) {
      for (let loc in data) {
        let newStudentAffairs = new StudentAffairsObject(data[loc], date);
        this.allStudentAffairs.push(newStudentAffairs);
      }
    }
  }
}
