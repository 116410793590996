/**
 * Created by kjsisd1 on 6/10/17
 */

import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AppService } from './app.service';
import { GoogleAnalyticsEventsService } from './GoogleAnalytics/google-analytics-events-service';

declare let ga: Function;

// use currFutureTerms in whichever other component you'd like by adding: @Input() currFutureTerms;
@Component({
  selector: 'app-root',
  templateUrl: './app.template.html',
  styleUrls: ['./app.style.css'],
  providers: [GoogleAnalyticsEventsService],
})
export class AppComponent implements OnInit {
  title = 'Tiger Center';

  envName = 'Prod';
  showBanner = false;
  hideNav = false;
  iOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
  newInnerWidth: any = window.innerWidth;

  /**
   * Contains the background colors of all the features listed in the sidenav.
   * @type {{search: string; enroll: string; acad: string; hoursLocations: string; diningBalances: string; calculator: string; resources: string}}
   */
  sideNavBackground = {
    homepage: '#424343',
    search: '#424343',
    enroll: '#424343',
    acad: '#424343',
    hoursLocations: '#424343',
    diningBalances: '#424343',
    calculator: '#424343',
    classCompare: '424343',
    resources: '#424343',
  };
  menuFileToUse = './assets/svgLib/cheeseburger.svg';
  // these four below need two different images because designer had to add strokes to them to make them thinner.
  searchFileToUse = './assets/svgLib/classSearch_white.svg';
  enrollFileToUse = './assets/svgLib/enrollment_white.svg';
  academicsFileToUse = './assets/svgLib/academics_white.svg';
  hoursLocationsFileToUse = './assets/svgLib/hoursLocations_white.svg';

  /**
   * This constructor serves a very importance purpose.
   *
   * Firstly, it hides the nav bar when on the landing page, otherwise it shows it.
   *
   * Secondly, the second (and eventually third, fourth, fifth, etc...) else if condition is in charge of defaulting the
   * side nav to be on that icon when that page is loaded using routing. If the event url is equal to the routing of a
   * particular feature declared in *app-routing.module.ts*, then the sidenav will default to that feature.
   * > **Note: Make sure to read *this.sideNavBackgroundColor* for insight as for what index to pass into the function**
   *
   * Thirdly, any **future features added to this Tiger Center redesign/re-architecture** will **need to have their own
   * else if condition (appended to the last else if condition currently) matching the event.url to the url path assigned
   * by *app-routing.module.ts***
   *
   * Fourthly, the network call to grab the current and future terms is made and saved in a publicly accessible variable in
   * the App Service
   * > **Note: It appears that if a user chooses to URL bookmark their way into the app, this service call happens AFTER
   * > the component they're in loads. Which causes errors. So, for insight on how to solve this, please check out the
   * > class search component constructor.**
   *
   * Lastly, the network call to check maintenance flags is made every five minutes and stored in publicly accessible variables
   * to ensure that a user can't get to unavailable services and to provide graceful failing for the application.
   *
   * @param {Router} router
   * @param appService
   */
  constructor(private router: Router, public appService: AppService, public gaService: GoogleAnalyticsEventsService) {
    this.updateNavigationColor();

    // grab current and future term for whole app
    try {
      this.appService.getCurrAndFutureTerms().subscribe(
        (value) => {},
        (error) => console.log(error),
        () => {}
      );
    } catch (error) {
      console.error(error);
    }

    // call the maintenance endpoint once in the very beginning
    try {
      this.appService.getMaintenanceFlags().subscribe(
        (value) => {},
        (error) => console.log(error),
        () => {}
      );
    } catch (error) {
      console.error(error);
    }

    // keep calling the maintenance endpoint every minute (60000 ms) to make sure no one is on stuff they're not supposed to be :)
    let tempThis = this;
    setInterval(function () {
      try {
        tempThis.appService.getMaintenanceFlags().subscribe(
          (value) => {},
          (error) => console.log(error),
          () => {}
        );
      } catch (error) {
        console.error(error);
      }
    }, 60000);

    try {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  ngOnInit() {}

  ngAfterViewInit() {
    switch (window.location.hostname) {
      case 'tigercenterdev.rit.edu':
        this.showBanner = true;
        document.querySelector('.tc-side-nav').className = 'tc-side-nav-env-bar';
        this.envName = 'Dev';
        break;
      case 'tigercentertest.rit.edu':
        this.showBanner = true;
        document.querySelector('.tc-side-nav').className = 'tc-side-nav-env-bar';
        this.envName = 'Test';
        break;
      case 'tigercenterstage.rit.edu':
        this.showBanner = true;
        document.querySelector('.tc-side-nav').className = 'tc-side-nav-env-bar';
        this.envName = 'Stage';
        break;
      case 'localhost':
        this.showBanner = true;
        document.querySelector('.tc-side-nav').className = 'tc-side-nav-env-bar';
        this.envName = 'Local';
        break;
      default:
        this.envName = 'Prod';
        break;
    }
  }
  /**
   * Refactored the code to update the navigation color into a separate method so it can be referenced by all aspects of the app.
   * This prevents having to copy and paste the same code in different components
   * */
  updateNavigationColor() {
    this.router.events.subscribe((event) => {
      const LANDING_SLASH: string = '/landing';
      const ALT_LANDING: string = '/';
      const TOO_MANY_REQUESTS: string = '/slowdown';
      if (event instanceof NavigationStart) {
        if (event.url === LANDING_SLASH) {
          // hide sideNav menu icon
          this.hideNav = true;
        } else if (event.url === ALT_LANDING) {
          // hide sideNav menu icon
          this.hideNav = true;
        } else if (event.url === TOO_MANY_REQUESTS) {
          this.hideNav = true;
        } else if (event.url === '/login_shib/tc/homepage') {
          this.sideNavBackgroundColor(0);
          this.hideNav = false;
        } else if (event.url === '/api/class-search') {
          this.sideNavBackgroundColor(1);
          this.hideNav = false;
        } else if (event.url === '/enrollment' || event.url === '/login_shib/tc/enrollment') {
          this.sideNavBackgroundColor(2);
          this.hideNav = false;
        } else if (event.url === '/academics' || event.url === '/login_shib/tc/academics') {
          this.sideNavBackgroundColor(3);
          this.hideNav = false;
        } else if (event.url === '/api/hours-and-locations') {
          this.sideNavBackgroundColor(4);
          this.hideNav = false;
        } else if (event.url === '/dining-balances' || event.url === '/login_shib/tc/dining-balances') {
          this.sideNavBackgroundColor(5);
          this.hideNav = false;
        } else if (event.url === '/api/gpa-calc') {
          this.sideNavBackgroundColor(6);
          this.hideNav = false;
        } else if (event.url === '/api/class-compare') {
          this.sideNavBackgroundColor(7);
          this.hideNav = false;
        } else if (event.url === '/api/resources') {
          this.sideNavBackgroundColor(8);
          this.hideNav = false;
        }
        // add more else if conditions for new features implemented in the side nav and add a new case to this.sideNavBackgroundColor for it
        // for example eventually open evals will need its own page when we completely incorporate it.
        // also be sure to check the component for maintenance and add it there as well.
      }
    });
  }

  /**
   * This HostListener will be called **any** time the window is resized. It's used for a massive performance boost.
   * The difference between [hidden]="" and ngIf="" is massive in terms of performance and this method allows us to use
   * ngIf=""
   * > Note: The short reason why this is the case is because ngIf is Angular and Angular completely **adds/removes** the element
   * > to the DOM. Hidden is simply an HTML attribute and only **hides** it from the DOM.
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;
  }

  /**
   * This ViewChild allows us to see whether or not the sidenav is opened.
   */
  @ViewChild('sidenav', { static: true }) sidenav: any;

  readonly menuIconClose = './assets/svgLib/x_white.svg';
  readonly menuIconOpen = './assets/svgLib/cheeseburger.svg';

  /**
   * This function sets the background color of the feature in use as orange and all the other features as black by calling
   * this.setBackgroundColorExcept('Feature Being Used Here').
   *
   * For the four images that required strokes to be done to them in illustrator, these will be set to their orange strokes
   * when being used, and white otherwise.
   *
   * @param {number} index The index should be as follows:
   * > * 0 => homepage
   * > * 1 => search
   * > * 2 => enroll
   * > * 3 => acad
   * > * 4 => hoursLocations
   * > * 5 => diningBalances
   * > * 6 => calculator
   * > * 7 => classCompare
   * > * 8 => resources
   */
  sideNavBackgroundColor(index: number) {
    switch (index) {
      case 0:
        // TODO: Change to real image when created
        this.sideNavBackground.homepage = '#f86a18';
        this.searchFileToUse = './assets/svgLib/classSearch_white.svg';
        this.enrollFileToUse = './assets/svgLib/enrollment_white.svg';
        this.academicsFileToUse = './assets/svgLib/academics_white.svg';
        this.hoursLocationsFileToUse = './assets/svgLib/hoursLocations_white.svg';
        this.setBlackBackgroundColorExcept('homepage');
        break;
      case 1:
        this.sideNavBackground.search = '#f86a18';
        this.searchFileToUse = './assets/svgLib/classSearch_orangeStroke.svg';
        this.enrollFileToUse = './assets/svgLib/enrollment_white.svg';
        this.academicsFileToUse = './assets/svgLib/academics_white.svg';
        this.hoursLocationsFileToUse = './assets/svgLib/hoursLocations_white.svg';
        this.setBlackBackgroundColorExcept('search');
        break;
      case 2:
        this.sideNavBackground.enroll = '#f86a18';
        this.enrollFileToUse = './assets/svgLib/enrollment_orangeStroke.svg';
        this.searchFileToUse = './assets/svgLib/classSearch_white.svg';
        this.academicsFileToUse = './assets/svgLib/academics_white.svg';
        this.hoursLocationsFileToUse = './assets/svgLib/hoursLocations_white.svg';
        this.setBlackBackgroundColorExcept('enroll');
        break;
      case 3:
        this.sideNavBackground.acad = '#f86a18';
        this.academicsFileToUse = './assets/svgLib/academics_orangeStroke.svg';
        this.searchFileToUse = './assets/svgLib/classSearch_white.svg';
        this.enrollFileToUse = './assets/svgLib/enrollment_white.svg';
        this.hoursLocationsFileToUse = './assets/svgLib/hoursLocations_white.svg';
        this.setBlackBackgroundColorExcept('acad');
        break;
      case 4:
        this.sideNavBackground.hoursLocations = '#f86a18';
        this.hoursLocationsFileToUse = './assets/svgLib/hoursLocations_orangeFill.svg';
        this.searchFileToUse = './assets/svgLib/classSearch_white.svg';
        this.enrollFileToUse = './assets/svgLib/enrollment_white.svg';
        this.academicsFileToUse = './assets/svgLib/academics_white.svg';
        this.setBlackBackgroundColorExcept('hoursLocations');
        break;
      case 5:
        this.sideNavBackground.diningBalances = '#f86a18';
        this.searchFileToUse = './assets/svgLib/classSearch_white.svg';
        this.enrollFileToUse = './assets/svgLib/enrollment_white.svg';
        this.academicsFileToUse = './assets/svgLib/academics_white.svg';
        this.hoursLocationsFileToUse = './assets/svgLib/hoursLocations_white.svg';
        this.setBlackBackgroundColorExcept('diningBalances');
        break;
      case 6:
        this.sideNavBackground.calculator = '#f86a18';
        this.searchFileToUse = './assets/svgLib/classSearch_white.svg';
        this.enrollFileToUse = './assets/svgLib/enrollment_white.svg';
        this.academicsFileToUse = './assets/svgLib/academics_white.svg';
        this.hoursLocationsFileToUse = './assets/svgLib/hoursLocations_white.svg';
        this.setBlackBackgroundColorExcept('calculator');
        break;
      case 7:
        this.sideNavBackground.classCompare = '#f86a18';
        this.searchFileToUse = './assets/svgLib/classSearch_white.svg';
        this.enrollFileToUse = './assets/svgLib/enrollment_white.svg';
        this.academicsFileToUse = './assets/svgLib/academics_white.svg';
        this.hoursLocationsFileToUse = './assets/svgLib/hoursLocations_white.svg';
        this.setBlackBackgroundColorExcept('classCompare');
        break;
      case 8:
        this.sideNavBackground.resources = '#f86a18';
        this.searchFileToUse = './assets/svgLib/classSearch_white.svg';
        this.enrollFileToUse = './assets/svgLib/enrollment_white.svg';
        this.academicsFileToUse = './assets/svgLib/academics_white.svg';
        this.hoursLocationsFileToUse = './assets/svgLib/hoursLocations_white.svg';
        this.setBlackBackgroundColorExcept('resources');
        break;
    }
  }

  /**
   * This function changes all the background colors of the features listed in the sidenav to be black, expect for the one
   * feature passed in as an argument.
   * @param {string} text The feature to not be set to a black background.
   */
  setBlackBackgroundColorExcept(text: string) {
    for (let key in this.sideNavBackground) {
      if (key !== text) {
        this.sideNavBackground[key] = '#434242';
      }
    }
  }

  hideBanner() {
    this.showBanner = false;
    document.querySelector('.tc-side-nav-env-bar').className = 'tc-side-nav';
  }
}
