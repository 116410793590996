/**
 * Created by sebisd on 7/11/17.
 */

import * as sortBy from 'sort-by';
const allDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export class LabEvent {
  haveData: boolean = false;
  eventId: string;
  eventName: string;
  description: string;
  activeFlag: string;
  daysMask: number;
  startTimeStamp: string;
  endTimeStamp: string;
  startDate: string;
  endDate: string;
  dayOfWeekSet: any;
  days: any[];
  exception: any[];

  timeLine = {
    Sunday: [],
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
  };
  /*
  [
    {
      percent: '30%',
      status: 0 | 1 | 2 | 4
    }
  ]
   */

  /**
   * This constructor instantiates the variables
   * @param data
   */
  constructor(data) {
    if (data) {
      this.haveData = true;
      this.eventId = data['event_id'];
      this.eventName = data['event_name'];
      this.description = data['description'];
      this.activeFlag = data['active_flag'];
      this.daysMask = data['days_mask'];
      this.startTimeStamp = data['startDateTime'];
      this.endTimeStamp = data['endDateTime'];
      this.startDate = data['startDay'];
      this.endDate = data['endDay'];
      this.dayOfWeekSet = data['daysOfTheWeekSet'];
      this.days = data['days'];
      this.exception = data['labEventExceptions'];
    } else {
      this.haveData = false;
    }
  }

  /**
   * This function is in charge of returning if an individual event within the lab object is open or otherwise. The
   * return value will be assigned to labObj.openNow
   *
   * @returns {number} Returns one of the following:
   * > * 0 = Gracefully Fail (No Data)
   * > * 1 = Closed
   * > * 2 = Reserved (by a class)
   * > * 3 = Closing Soon (closing within 30 mins)
   * > * 4 = Open
   */
  determineEventOpen(): number {
    let today = new Date();
    let day = allDays[today.getDay()];
    let currHour = today.getHours();
    let currMin = today.getMinutes();
    let singleTimeline = this.timeLine[day];

    if (singleTimeline != null) {
      for (let evnt of singleTimeline) {
        let startTimeHour = parseInt(evnt.start.split(':')[0]);
        let startTimeMin = parseInt(evnt.start.split(':')[1]);
        let endTimeHour = parseInt(evnt.end.split(':')[0]);
        let endTimeMin = parseInt(evnt.end.split(':')[1]);

        // determine if inside the correct object (time slot)
        if (currHour >= startTimeHour && currHour <= endTimeHour) {
          if (currHour === startTimeHour) {
            if (currMin < startTimeMin) {
              // closed, but opening soon
              return 1;
            } else {
              // open or reserved
              return evnt.status;
            }
          } else if (currHour > startTimeHour) {
            if (currHour < endTimeHour) {
              if (endTimeHour - currHour === 1) {
                if (currMin > endTimeMin) {
                  if (evnt.status === 4) {
                    let temp = currMin - endTimeMin;
                    if (temp >= 30) {
                      // closing soon
                      return 3;
                    }
                  } else {
                    // open or reserved
                    return evnt.status;
                  }
                } else {
                  // closed
                  return evnt.status;
                }
              } else {
                // open or reserved
                return evnt.status;
              }
            } else if (currHour === endTimeHour) {
              if (currMin < endTimeMin) {
                // check if closing soon is appropriate (in an open block only).
                if (evnt.status === 4) {
                  let temp = endTimeMin - currMin;
                  if (temp <= 30) {
                    // closing soon
                    return 3;
                  }
                } else {
                  // open or reserved
                  return evnt.status;
                }
              }
            }
          }
        }
      }
    }
    // null singleTimeline
    return 0;
  }

  generateTimeLineNoData() {
    for (let dy in this.timeLine) {
      this.timeLine[dy].push({
        percent: '100%',
        status: 0,
        start: '00:00',
        end: '23:59',
        statusText: 'No Data',
      });
    }
  }

  generateTimeLine(csList) {
    let weekData = [[], [], [], [], [], [], []];
    let weekBool = [false, false, false, false, false, false, false];

    if (this.haveData) {
      for (let dy of this.days) {
        let temp;
        if (dy.startTime !== '12:00 AM' && dy.endTime !== '12:00 AM') {
          temp = [
            { start: '00:00', end: this.timeConverter(dy.startTime), status: 1, text: 'Closed' },
            { start: this.timeConverter(dy.startTime), end: this.timeConverter(dy.endTime), status: 4, text: 'Open' },
            { start: this.timeConverter(dy.endTime), end: '23:59', status: 1, text: 'Closed' },
          ];
        } else {
          temp = [{ start: '00:00', end: '23:59', status: 4, text: 'Open' }];
        }
        switch (dy.weekday.toLowerCase()) {
          case 'sunday':
            weekBool[0] = true;
            weekData[0] = temp;
            break;
          case 'monday':
            weekBool[1] = true;
            weekData[1] = temp;
            break;
          case 'tuesday':
            weekBool[2] = true;
            weekData[2] = temp;
            break;
          case 'wednesday':
            weekBool[3] = true;
            weekData[3] = temp;
            break;
          case 'thursday':
            weekBool[4] = true;
            weekData[4] = temp;
            break;
          case 'friday':
            weekBool[5] = true;
            weekData[5] = temp;
            break;
          case 'saturday':
            weekBool[6] = true;
            weekData[6] = temp;
            break;
        }
      }

      if (this.exception.length > 0) {
        for (let exc of this.exception) {
          let temp;
          let notExcep24Flag = true;
          if (exc.startTime !== '12:00 AM' && exc.endTime !== '12:00 AM') {
            temp = {
              start: this.timeConverter(exc.startTime),
              end: this.timeConverter(exc.endTime),
              status: 1,
              text: 'Closed',
            };
          } else {
            notExcep24Flag = false;
          }
          for (let dy of weekData) {
            if (notExcep24Flag) {
              dy.push(temp);
            } else {
              dy[0].text = 'Closed';
              dy[0].status = 1;
            }
          }
        }
      }
    }

    for (let clss of csList) {
      for (let sess of clss.weekdays) {
        let temp = {
          start: this.timeConverter(sess.startTime),
          end: this.timeConverter(sess.endTime),
          status: 2,
          text: 'Reserved',
        };
        switch (sess.weekday.toLowerCase()) {
          case 'sunday':
            weekData[0].push(temp);
            break;
          case 'monday':
            weekData[1].push(temp);
            break;
          case 'tuesday':
            weekData[2].push(temp);
            break;
          case 'wednesday':
            weekData[3].push(temp);
            break;
          case 'thursday':
            weekData[4].push(temp);
            break;
          case 'friday':
            weekData[5].push(temp);
            break;
          case 'saturday':
            weekData[6].push(temp);
            break;
        }
      }
    }

    for (let dayArr of weekData) {
      dayArr = dayArr.sort(sortBy('start'));
    }

    const HARD_START = '00:00';
    const HARD_END = '23:59';
    const dayTracker = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    for (let i in dayTracker) {
      if (weekBool[i]) {
        let blockStart = weekData[i][0].start;
        let blockEnd;
        for (let z = 0; z < weekData[i].length; z++) {
          blockEnd = weekData[i][z].end;
          if (weekData[i][z + 1] && weekData[i][z + 1].start < blockEnd) {
            blockEnd = weekData[i][z + 1].start;
          } else if (blockStart < weekData[i][z].start) {
            this.timeLine[dayTracker[i]].push({
              percent: LabEvent.convertToPercent(blockStart, weekData[i][z].start),
              status: weekData[i][z].status,
              start: blockStart,
              end: blockEnd,
              statusText: weekData[i][z].text,
            });
            blockStart = weekData[i][z].start;
          }
          this.timeLine[dayTracker[i]].push({
            percent: LabEvent.convertToPercent(blockStart, blockEnd),
            status: weekData[i][z].status,
            start: blockStart,
            end: blockEnd,
            statusText: weekData[i][z].text,
          });
          blockStart = blockEnd;
        }
      } else if (weekData[i].length > 0) {
        let blockStart = weekData[i][0].start;
        let blockEnd;
        this.timeLine[dayTracker[i]].push({
          percent: LabEvent.convertToPercent(HARD_START, blockStart),
          status: 0,
          start: HARD_START,
          end: blockStart,
          statusText: 'No Data',
        });
        for (let y = 0; y < weekData[i].length; y++) {
          blockEnd = weekData[i][y].end;
          if (blockStart < weekData[i][y].start) {
            this.timeLine[dayTracker[i]].push({
              percent: LabEvent.convertToPercent(blockStart, weekData[i][y].start),
              status: 0,
              start: blockStart,
              end: weekData[i][y].start,
              statusText: 'No Data',
            });
            blockStart = weekData[i][y].start;
          }
          this.timeLine[dayTracker[i]].push({
            percent: LabEvent.convertToPercent(blockStart, blockEnd),
            status: weekData[i][y].status,
            start: blockStart,
            end: blockEnd,
            statusText: weekData[i][y].text,
          });
          blockStart = blockEnd;
        }
        this.timeLine[dayTracker[i]].push({
          percent: LabEvent.convertToPercent(blockEnd, HARD_END),
          status: 0,
          start: blockEnd,
          end: HARD_END,
          statusText: 'No Data',
        });
      }
    }
  }

  debugCount = 0;
  private timeConverter(time: string): string {
    this.debugCount++;
    let noSpacetime = time.replace(/\s/g, '');
    if (noSpacetime.toLowerCase().includes('pm') && !noSpacetime.toLowerCase().includes('12')) {
      noSpacetime = noSpacetime.slice(0, 5);
      let hr = parseInt(noSpacetime.split(':')[0]);
      let min = noSpacetime.split(':')[1];
      hr += 12;
      return hr.toString() + ':' + min.toString();
    } else {
      return noSpacetime.slice(0, 5);
    }
  }

  private static convertToPercent(startTime: string, endTime: string) {
    let startT = startTime.split(':');
    let endT = endTime.split(':');
    let startMins: number = parseInt(startT[1]);
    let endMins: number = parseInt(endT[1]);
    if (startTime.toLowerCase().includes('pm')) {
      startMins += (parseInt(startT[0]) + 12) * 60;
    } else {
      startMins += parseInt(startT[0]) * 60;
    }

    if (endTime.toLowerCase().includes('pm')) {
      endMins += (parseInt(endT[0]) + 12) * 60;
    } else {
      endMins += parseInt(endT[0]) * 60;
    }

    return (((endMins - startMins) / 1440) * 100).toFixed(5).toString() + '%';
  }
}
