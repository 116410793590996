/**
 * Created by kjsisd1 on 11/27/17.
 */
import { Component } from '@angular/core';
import { UserInfoService } from '../UserInfo/user.info.service';

@Component({
  selector: 'page-not-found',
  templateUrl: './page-not-found.template.html',
  styleUrls: ['./page-not-found.style.css'],
})
export class PageNotFoundComponent {
  userInfo = null;
  isPublicUser: boolean = true;

  /**
   * This constructor uses the userInfoService to incorporate shib information into the view.
   * @param {UserInfoService} userInfoService
   */
  constructor(userInfoService: UserInfoService) {
    try {
      userInfoService.getUserInfo().subscribe(
        (value) => {
          this.userInfo = value;
        },
        (error) => console.log(error),
        () => {
          this.isPublicUser = this.userInfo.isPublicUser;
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
}
