import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

/* declare var ga;
if (environment.GA) {
  ga('create', environment.GA, 'auto');
} */

platformBrowserDynamic().bootstrapModule(AppModule);
