<div class="container">
  <div class="col-xs-12 pageNotFoundFormatImage">
    <img src="./assets/images/sadTigerNew.png" alt="" class="pageNotFoundImage" />
  </div>
  <div class="col-xs-12 pageNotFoundBigText">
    <span class="col-xs-12">PAGE NOT FOUND</span>
  </div>
  <div class="col-xs-12 pageNotFoundSmallText">
    <span *ngIf="isPublicUser" class="col-xs-12 pageNotFoundSmallText">
      Please try visiting our <a href="landing">landing page</a>
    </span>
    <span *ngIf="!isPublicUser" class="col-xs-12 pageNotFoundSmallText">
      Please try visiting our <a href="login_shib/tc/homepage">homepage</a>
    </span>
  </div>
  <br />
  <div class="pageNotFoundSmallText">
    NOTE: If you got this page following a Legacy Tiger Center bookmark, please update your bookmark.
  </div>
  <div><br /></div>
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
</div>
