import { Injectable } from '@angular/core';
import axios from 'axios';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HTTPInterceptorService } from '../HTTPInterceptor/HTTPInterceptor.service';
import { AcademicsInfoObject } from './Models/AcademicsInfoObject';
import { FinalExamsObject } from './Models/FinalExamsObject';
import { FinalGradesObject } from './Models/FinalGradesObject';
import { GPACalculatorObject } from './Models/GPACalculatorObject';
import { TermStatisticsObject } from './Models/TermStatisticsObject';

const academicsServiceAPI = axios.create({
  baseURL: environment.TCAuth,
  withCredentials: true,
});

@Injectable()
/**
 * @class This service makes a call to the academics endpoint, gets the data requested
 * and creates a new AcademicsInfoObject
 */
export class AcademicsService {
  constructor(httpInterceptor: HTTPInterceptorService) {
    academicsServiceAPI.interceptors.response.use(
      (response) => httpInterceptor.responseHandler(response),
      (error) => {
        httpInterceptor.errorHandler(error);
      }
    );
  }

  /**
   * Makes a call to the academics endpoint to grab all of the users information for the academics page.
   *
   * @param strm - The current term to grab the data for
   * @returns {Observable}
   */
  getAcademicsData(strm: string): Observable<any> {
    return new Observable((observer) => {
      academicsServiceAPI
        .get('tiger-academics?strm=' + strm)
        .then((response) => {
          let responseObj = new AcademicsInfoObject(response.data);
          observer.next(responseObj);
          observer.complete();
        })
        .catch((err) => {
          console.log(err);
          observer.error(err);
        });
    });
  }

  /**
   * Used to update the gpa calculator object when a new term is selected by calling the grades endpoint.
   *
   * @param strm - The current term to grab the data for
   * @param object - The object to be updated
   * @returns {Observable}
   */
  updateGPACalculatorObj(strm: string, object: GPACalculatorObject): Observable<any> {
    return new Observable((observer) => {
      academicsServiceAPI
        .get('grades?strm=' + strm)
        .then((response) => {
          let modifiedObj = GPACalculatorObject.update(response.data, object);
          observer.next(modifiedObj);
          observer.complete();
        })
        .catch((err) => {
          console.log(err);
          observer.error(err);
        });
    });
  }

  /**
   * Used to update the final grades object when a new term is selected by calling the grades endpoint.
   *
   * @param strm - The current term to grab the data for
   * @param object - The object to be updated
   * @returns {Observable}
   */
  updateFinalGradesObj(strm: string, object: FinalGradesObject): Observable<any> {
    return new Observable((observer) => {
      academicsServiceAPI
        .get('grades?strm=' + strm)
        .then((response) => {
          let modifiedObj = FinalGradesObject.update(response.data, object);
          observer.next(modifiedObj);
          observer.complete();
        })
        .catch((err) => {
          console.log(err);
          observer.error(err);
        });
    });
  }

  /**
   * Given a term and an object to update, attempt to find final exams for the given term and fill
   *   out the object if successful.
   *
   * @param strm - The semester/term  that we're looking for final exams in.
   * @param object - A reference to the list of Final Exams that we'll update.
   */
  updateFinalExamsObj(strm: string, object: FinalExamsObject): Observable<any> {
    return new Observable((observer) => {
      academicsServiceAPI
        .get(`finalexams?strm=${strm}`)
        .then((response) => {
          object.academicsFinalExams = response.data.docs;
          observer.next(object);
          observer.complete();
        })
        .catch((err) => {
          console.log(err);
          observer.error(err);
        });
    });
  }

  /**
   * Used to update the term statistics object when a new term is selected by calling the grades endpoint.
   *
   * @param strm - The current term to grab the data for
   * @param object - The object to be updated
   * @returns {Observable}
   */
  updateTermStatisticsObj(strm: string, object: TermStatisticsObject): Observable<any> {
    return new Observable((observer) => {
      academicsServiceAPI
        .get('grades?strm=' + strm)
        .then((response) => {
          let modifiedObj = TermStatisticsObject.update(response.data, object);
          observer.next(modifiedObj);
          observer.complete();
        })
        .catch((err) => {
          console.log(err);
          observer.error(err);
        });
    });
  }

  /**
   * Used to get the academic standing depending on the term selected
   *
   * @returns {Observable}
   */
  getAcademicStandingObj(): Observable<any> {
    return new Observable((observer) => {
      academicsServiceAPI
        .get('acadStanding')
        .then((response) => {
          observer.next(response.data);
          observer.complete();
        })
        .catch((err) => {
          console.log(err);
          observer.error(err);
        });
    });
  }
}
