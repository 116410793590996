/**
 * Created by sebisd on 7/10/17.
 */
export class LabPrinter {
  printerId: string;
  details: string;
  name: string;
  hasQuota: string;
  apiKey: string;
  labPrinterHosts = null;

  /**
   * This constructor initializes the variables.
   * @param data
   */
  constructor(data) {
    this.printerId = data['printing_id'];
    this.name = data['name'];
    this.details = data['details'];
    this.hasQuota = data['has_quota'];
    this.apiKey = data['api_key'];
    this.labPrinterHosts = data['labPrintHostList'];
  }
}
