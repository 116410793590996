/**
 * Created by kjsisd1 on 7/25/17.
 */
import { Occurence } from '../../../ModelInterfaces/Occurence';
import { exceptionObject } from './exceptionObject';

export class eventObject extends Occurence {
  id = null;
  locationId = null;
  daysOfWeek: string[] = null;
  exception: exceptionObject = null;

  //openNow: number = 0;
  divHidden = true;
  infinite = false;
  categories = {};

  /**
   * This constructor instantiates the variables.
   * @param data
   */
  constructor(data) {
    super(data);
    this.id = data.id;
    this.locationId = data.locationId;
    this.exception = data.exceptions ? new exceptionObject(data.exceptions[0]) : null;
    this.daysOfWeek = data.daysOfWeek;

    //this.openNow = this.determineEventOpen();
    this.infinite = data.infinite;
  }

  /**
   * This function determines the open status of the student affairs object at the event level (for a singular event).
   * It takes into consideration the exceptions for an event.
   * @returns {number}
   */
  /**
  private determineEventOpen(): number {
      let eventOpen, closesSoon;
      let today = new Date();

      let currHour = today.getHours();
      let currMin = today.getMinutes();
      let startTimeHour = Number(this.startTime.split(':')[0]);
      let startTimeMin = Number(this.startTime.split(':')[1]);
      let endTimeHour = Number(this.endTime.split(':')[0]);
      let endTimeMin = Number(this.endTime.split(':')[1]);

      eventOpen = this.isNow(today, currHour, currMin) && this.hasDayOfWeek(today);
      closesSoon = this.isClosingSoon(currHour, currMin);

      if (this.exception && this.exception.isNow(today, currHour, currMin)){
          if (this.exception.status === "Open"){
            return (closesSoon ? Occurence.CLOSING_SOON_STATUS : Occurence.OPEN_STATUS);
          }
          return this.exception.status == exceptionObject.CLOSED_EXCEPTION ? Occurence.CLOSED_STATUS : Occurence.RESERVED_STATUS;
      }
      else{
        return eventOpen ? (closesSoon ? Occurence.CLOSING_SOON_STATUS : Occurence.OPEN_STATUS) : Occurence.CLOSED_STATUS;
      }
  }
   */

  /**
   * Checks if the date passed in is included in the days that an event is occurring (e.g. if today is Tue and the event
   * is open Mon/Wed/Fri, then this function will return *false*)
   * @param day A date object
   * @returns {boolean}
   */
  hasDayOfWeek(day): boolean {
    const allDays = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

    let dayOfWeek = allDays[day.getDay()];
    return this.daysOfWeek.indexOf(dayOfWeek) > -1;
  }
}
