import { Occurence } from '../../../ModelInterfaces/Occurence';
import { TCModel } from '../../../ModelInterfaces/TCModel';
import { diningEventObject } from './diningEventObject';

const WILL_BE_OPEN = 5;
const WILL_BE_CLOSED = 4;

/**
 * @class represents a dining location by implementing TCModel, this is so we can use our pipe functions generically
 * in the controllers and HTML Templates throughout the Application.
 * > **Note**: Some of the variables that are contained in this model are used by the templates for 2-way binds
 * > So we are not going to be documenting all of the variables for reasons of priorities
 *
 * @author Created by sebisd on 6/19/17.
 */
export class DiningObject implements TCModel {
  locationId = null;
  name = null;
  description = null;
  summary = null;
  mapsurl = null;

  /**
   * <p>This Array is used to hold the Events that occur at that location with there dates and times.
   * Within this Event Object also holds the menu information for that event.</p>
   * @type {Array}
   */
  events: diningEventObject[] = [];

  /**
   * A integer value of 0|1|2|3|4|5 used for real-time status of the location, also used by the 'sort-by' package when
   * the user would click *Sort Open Now*
   *
   *    * 0 => 'No Data'
   *    * 1 => 'Closed'
   *    * 2 => 'Closing Soon'
   *    * 3 => 'Open'
   *    * 4 => 'Will Be Closed' - *Used when selecting a date in the future*
   *    * 5 => 'Will Be Open' - *Used when selecting a date in the future*
   *
   * > This is determined from the eventObject after we build it/if we have it
   */
  openNow = 1;
  hideDiv = true;
  contacts = null;
  mdoId = null;
  mapId = null;
  mrkId = null;
  catId = null;
  hasData = false;
  noMenu = false;

  /**
   *  A String array that is used by the filter pipe.  This Array conatins a list of key words that the filter is
   *  looking for to match with what the user has selected
   */
  filterItems: string[];

  /**
   * This is used to cut down on the amount of HTTP calls was make.  This is in the structure as such:
   *
   * > {\*DATE\*:\*diningEventObject\*}
   *
   * > DATE will be in the format such: '2017-08-14'
   *
   * You are then able to set the 'active' event object using a date value.
   * @type {object}
   */
  eventObjCache = {};
  eventSelectedDate: string = null;
  selectedDateObj: Date = new Date();

  /**
   * @constructor for a Single Dining Object, this is what holds all the information that a location has
   * this information is independent from the date param that was used by the service to get the data, as well as the
   * event object which is contained within this object
   * @param { object } data is the JSON response, this constructor can handle 1 of 2 situations. Being passed only the
   *    data for the location or When we make the call to refresh a single dining location, the constructor will call
   *    it's own mapData function to create the event and the menus.
   */
  constructor(data, isUpdate, diningSelectedDate) {
    // These 3 elements are the same across both v2 and v3 so we set those by default
    this.name = data.name;
    this.description = data.description;
    this.summary = data.summary;
    // How they refer to the Location ID is how we decide with we got back v2 or v3 data. 'locId' is used for v2 and 'id' is used for v3
    if (data.hasOwnProperty('locId')) {
      this.locationId = data.locId;
      this.mapsurl = data.mapsurl;
    } else {
      this.locationId = data.id;
      this.mapsurl = data.mapsUrl;
      this.contacts = data.contacts;
      this.mdoId = data.mdoId;
      this.mapId = data.mapId;
      this.mrkId = data.mrkId;
      this.catId = data.catId;
      if (data.hasOwnProperty('events') && data.hasOwnProperty('menus') && !isUpdate) {
        this.mapData(data.events, data.menus);
      }

      if (data.hasOwnProperty('events') && data.hasOwnProperty('menus') && isUpdate) {
        DiningObject.updateMappedData(this, data.events, data.menus, diningSelectedDate);
      } else {
        this.openNow = this.getStatus();
      }
    }
  }

  /**
   * @static
   * This Static method is used to change the event (also menu) when we need to update on a single location.  This
   * occurs when you change the date from the drop down select in the expanded location on the Dining Tab in Hours and
   * Locations.
   *
   * @param {DiningObject} selectedDiningObject The DiningObject that needs to be updated
   * @param {any[]} eventMap JSON response from the service
   * @param {any[]} menuMap JSON response from the service
   * @param {string} date The date that was used to get the data, this is used to cache the object to avoid making more calls
   * @returns {DiningObject} The same DiningObject that was passed in except with the updated Event and Menu Map
   */
  static updateMappedData(
    selectedDiningObject: DiningObject,
    eventMap: any[],
    menuMap: any[],
    date: string
  ): DiningObject {
    selectedDiningObject.selectedDateObj = DiningObject.parseDate(date);
    if (selectedDiningObject.eventObjCache.hasOwnProperty(date)) {
      selectedDiningObject.events = selectedDiningObject.eventObjCache[date]['event'];
      selectedDiningObject.filterItems = selectedDiningObject.eventObjCache[date]['filterList'];
    } else {
      selectedDiningObject.events = [];
      selectedDiningObject.mapData(eventMap, menuMap, date);
    }

    if (!selectedDiningObject.hasData) {
      selectedDiningObject.openNow = Occurence.NO_DATA_STATUS;
    } else {
      if (selectedDiningObject.selectedDateObj.toDateString() === new Date().toDateString()) {
        selectedDiningObject.selectedDateObj = new Date();
        selectedDiningObject.openNow = selectedDiningObject.getStatus();
      } else {
        selectedDiningObject.openNow = selectedDiningObject.events.length > 0 ? WILL_BE_OPEN : WILL_BE_CLOSED;
      }
    }
    return selectedDiningObject;
  }

  /**
   * Used to create the event for the dining object, because events don't always have events a check needs to be
   * done to know if we actually have events
   * @param {any[]} eventMap - This is a JSON Object that we get back from the service
   * @param {any[]} menuMap - This is also a JSON Object
   * @param {string} date - This is an optional param that is used to set the cache of the Dining, as well as setting
   *    the selectedDate so that there is a default value in the Angular 2-way bind in the the template page.  If no
   *    date is passed into the method then it is **not** cached.
   */
  mapData(eventMap: any[], menuMap: any[], date?: string): void {
    this.events = [];
    this.filterItems = [];
    if (eventMap.length == 0) {
      // no data
      this.openNow = 0;
      this.hasData = false;
    } else {
      this.hasData = true;

      // adjust events hours of operations and remove events which have no hours of operation
      eventMap = this.adjustHoursBasedOnException(eventMap);

      for (let event in eventMap) {
        let newEvent = new diningEventObject(eventMap[event]);
        newEvent.openNow = newEvent.determineEventOpen(this.selectedDateObj);
        newEvent.mapMenuData(menuMap);

        if (newEvent.isOnThisDay(this.selectedDateObj) && newEvent.hasDayOfWeek(this.selectedDateObj)) {
          this.events.push(newEvent);
        }
      }

      if (this.events.length > 0) {
        let goalCount: number = this.events.length;
        let count: number = 0;
        let menuTypeList = [];
        for (let event of this.events) {
          //if menu does not have type of breakfast, lunch or dinner it is null
          if (event.menuTypes == null) {
            //filter out gracie's quick service which has menu type of null
            if (event.eventId != 5724) {
              //adds cafe filter to the menu type list for items that do not have a menu type
              menuTypeList.push('CAFE');

              for (let menu of menuTypeList) {
                //checks if menu type is already included in list
                if (!this.filterItems.includes(menu)) {
                  this.filterItems.push(menu);
                }
              }

              count++;
            }
          } else {
            let menuType = event.menuTypes.toString();
            //let menuTypeList = [];

            //Special case of menu type being LUNCH,DINNER
            // The restaurant will not filter properly in this format
            if (menuType == 'LUNCH,DINNER') {
              menuTypeList.push('LUNCH');
              menuTypeList.push('DINNER');
            } else if (menuType == 'BREAKFAST,LUNCH,DINNER') {
              menuTypeList.push('BREAKFAST');
              menuTypeList.push('LUNCH');
              menuTypeList.push('DINNER');
            } else {
              menuTypeList.push(menuType);
            }

            for (let menu of menuTypeList) {
              //checks if menu type is already included in list
              if (!this.filterItems.includes(menu)) {
                this.filterItems.push(menu);
              }
            }
          }
        }
        if (count === goalCount) {
          this.noMenu = true;
        }
      }

      if (date) {
        this.eventObjCache[date] = {};
        this.eventObjCache[date]['event'] = this.events;
        this.eventObjCache[date]['filterList'] = this.filterItems;
        this.eventSelectedDate = date;
      }
    }
  }

  /**
   * Updates the status for the Real-time label and for the sort-by function. The loop checks each event's status to see
   * of its not Closed.  If it's not closed we return the status we got.
   * > NO_DATA_STATUS is the same as 0
   *
   * > CLOSED_STATUS is the same as 1
   *
   * @returns {number} The status number as we get back from the events.
   */
  getStatus(): number {
    let status = Occurence.NO_DATA_STATUS;

    for (let event of this.events) {
      status = event.determineEventOpen(this.selectedDateObj);
      if (status != Occurence.CLOSED_STATUS) {
        break;
      }
    }
    // locations may not have events today but still have relevant data
    if (status == Occurence.NO_DATA_STATUS && this.hasData) {
      status = Occurence.CLOSED_STATUS;
    }

    return status;
  }

  /**
   * @private
   * @static
   * This private function is used to create a Built-in Date Object from the date that's passed into the method.
   *    Because of the format of the string ('YYYY-MM-DD') The Date object constructor assumes a different timezone.
   *    To account for this, we call the _getTimezoneOffset()_ followed by the *(-60000).  You can use this logic for all
   *    and where applicable.
   * @param {string} date format is 'YYYY-MM-DD' ex) '2017-08-14'
   * @returns {Date} a date object that is able to be handled better when doing the real-time methods.
   */
  private static parseDate(date: string): Date {
    let dateObj;
    dateObj = new Date(date);
    dateObj = new Date(dateObj.getTime() - dateObj.getTimezoneOffset() * -60000);
    return dateObj;
  }

  /**
   * This method is used to adjust the hours and locations of dining locations events which have different timings from the usual
   * daily hours of operations and remove events which have no hours of operations.
   * @param events
   *
   * @return events
   */
  adjustHoursBasedOnException(events: any[]): any[] {
    let i = 0;
    while (i < events.length) {
      if (events[i].exceptions != null && events[i].exceptions[0].length != 0) {
        if (events[i].exceptions[0].open) {
          events[i].startTime = events[i].exceptions[0].startTime;
          events[i].endTime = events[i].exceptions[0].endTime;
          i++;
        } else {
          events.splice(i, 1);
        }
      } else {
        i++;
      }
    }
    return events;
  }
}
