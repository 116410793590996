/**
 * Created by sebisd on 6/13/17.
 */

import { Injectable } from '@angular/core';
import axios from 'axios';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';
import { AppService } from '../../app.service';
import { HTTPInterceptorService } from '../../HTTPInterceptor/HTTPInterceptor.service';
import { AllDiningObject } from './Models/AllDiningObj';
import { DiningObject } from './Models/DiningObject';

const diningServiceAPI = axios.create({
  baseURL: environment.TC,
  withCredentials: true,
  params: {
    date: null,
  },
});

/**
 * @class
 * This Angular injectable service, that creates the HTTP Request and handles the responses for both all dining
 * information or a single dining object.  We use the 3rd party package: axios to create the HTTP request and execute.
 * This is due to this packages' ability to handle CORS for the development process (With RIT's security).
 */
@Injectable()
export class DiningHoursService {
  constructor(private appService: AppService, httpInterceptor: HTTPInterceptorService) {
    diningServiceAPI.interceptors.response.use(
      (response) => httpInterceptor.responseHandler(response),
      (error) => {
        httpInterceptor.errorHandler(error);
      }
    );
  }

  /**
   * This is the method used by the component to get the data to display to the user
   * @param {string} date
   * @return {Observable<any>}
   */
  getDiningHours(date: string): Observable<any> {
    return new Observable((observer) => {
      diningServiceAPI
        .get('dining-all?date=' + date)
        .then((response) => {
          let resObj = new AllDiningObject(response.data, date);
          observer.next(resObj);
          observer.complete();
        })
        .catch((err) => {
          switch (err.response.status) {
            // Service Unavailable
            case 503:
              this.appService.clicDown = true;
              break;
          }
          console.error(err);
          observer.error(err);
        });
    });
  }

  /**
   * The is the function used by the component to get data pertaining to a single dining location to display to the user.
   * @param {string} date
   * @param {DiningObject} diningObj
   * @return {Observable<any>}
   */
  getSingleDining(date: string, diningObj: DiningObject): Observable<any> {
    return new Observable((observer) => {
      diningServiceAPI
        .get(`dining-single?date=${date}&locId=${diningObj.locationId}`)
        .then((response) => {
          let modObj = DiningObject.updateMappedData(diningObj, response.data.events, response.data.menus, date);
          observer.next(modObj);
          observer.complete();
        })
        .catch((err) => {
          switch (err.response.status) {
            // Service Unavailable
            case 503:
              this.appService.clicDown = true;
              break;
          }
          console.error(err);
          observer.error(err);
        });
    });
  }
}
