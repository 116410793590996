<div id="header" class="footerheader">
  <div class="col-md-1"></div>
  <div class="logoDiv col-md-6">
    <img src="{{logoImageToUse}}" />
    <img class="hidden-xs hidden-sm" src="{{textImageToUse}}" />
  </div>
  <div class="headerLinks headerLinkCheck col-md-4">
    <a class="basic-link" href="{{links.feedbackHelp}}" target="_self" style="margin-right: 0px">Feedback</a>
    <span>|</span>
    <a class="basic-link" href="{{links.supportHelp}}" target="_blank" style="margin-right: 0px">Support</a>
    <span>|</span>
    <a class="basic-link" href="{{links.directory}}" target="_blank">Directories</a>
    <span>|</span>
    <!--<a class="basic-link" href="">RIT Search</a>-->
    <!--<span>|</span>-->
    <a class="basic-link" href="{{links.ritHome}}" target="_blank">RIT Home</a>
  </div>
  <div class="col-md-1"></div>
</div>
