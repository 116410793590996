declare let ga: Function;

export class GoogleAnalyticsEventsService {
  /**
   * Send a event to google analytics for tracking
   * @param eventCategory - Page in TC related to the event
   * @param eventAction - Type of element clicked
   * @param eventLabel - Name identifier
   * @param eventValue (optional) - Numerical value for tracking (e.g. load time)
   */
}
