import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError, tap } from 'rxjs/operators';
import { UserModel } from '../UserInfo/Models/UserModel';
import { UserInfoService } from '../UserInfo/user.info.service';

@Injectable()
export class HomepageResolver implements Resolve<any> {
  constructor(private userService: UserInfoService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserModel> {
    return this.userService.getUserInfo().pipe(
      tap((user: UserModel) => {
        console.log('HomepageResolver: Not a public user! Redirecting to login_shib/tc/login');
        this.router.navigate(['login_shib/tc/login']);
      }),
      catchError((err, caught) => {
        console.log('HomepageResolver: Do not redirect to login');
        return of(null);
      })
    );
  }
}
