/**
 * Created by jtkisd on 11/22/17.
 */
import { Injectable } from '@angular/core';
import { ClassSearchObj } from '../ClassSearch/Models/ClassSearchObj';

@Injectable()

/**
 * Service for holding information sent from class search
 */
export class ClassCompareService {
  // Variables for searching in class search
  public searchResult: ClassSearchObj;
  public searchResultAssociated: ClassSearchObj;
  public courseList: any[] = [];
  public term: string;
}
