/**
 * Created by jtkisd on 10/18/17.
 */
import { AcademicStandingObject } from './AcademicStandingObject';
import { FinalExamsObject } from './FinalExamsObject';
import { FinalGradesObject } from './FinalGradesObject';
import { GPACalculatorObject } from './GPACalculatorObject';
import { TermStatisticsObject } from './TermStatisticsObject';

export class AcademicsInfoObject {
  academicPrograms = null;
  advisorsList = null;
  holdsList = null;
  courseHistoryList = null;

  termStatistics: TermStatisticsObject;
  finalExams: FinalExamsObject;
  finalTermGrades: FinalGradesObject;
  gpaCalculator: GPACalculatorObject;
  academicStand: AcademicStandingObject;

  constructor(data) {
    this.termStatistics = new TermStatisticsObject(data.credits);
    if (data.finalExams != null) {
      this.finalExams = new FinalExamsObject(data.finalExams.docs);
    } else {
      this.finalExams = new FinalExamsObject([]);
    }
    this.finalTermGrades = new FinalGradesObject(data.finalGrades);
    this.gpaCalculator = new GPACalculatorObject(data);
    this.academicStand = new AcademicStandingObject(data.academicStanding);

    this.academicPrograms = data.program;
    this.advisorsList = data.advisors;
    this.holdsList = data.holds;
    this.courseHistoryList = data.courseHistory;
  }
}
