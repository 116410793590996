import { Injectable } from '@angular/core';

@Injectable()
/**
 * @class This service handles all logic required to link courses to
 *   maps.rit.edu locations.
 */
export class CourseMapService {
  baseURL = 'https://maps.rit.edu/~phpmaps';

  /**
   * Return true if the class meeting has a location on campus that can
   *   be displayed on the maps website. Return false otherwise.
   *
   * @param meeting : A single instance of a course meeting.
   */
  public hasMapLink(fullLocation: string) {
    // If null, no map link
    if (fullLocation === null) {
      return false;
    }

    // Catch any edge cases
    let edgeCases = {
      'Online Class': true, // ONLINE - CSCI 720 13316
      'To Be Determined': true, // TBD - CSCI 243 600 16740, Non-main - 17065 (To be determined)
      'Instructor Provided': true, // INSTRUCTOR - 13238
      'Off-Campus Location': true, // Off campus - WINT-42 14162
      'TBA': true, // Edge case specifically for the final exams page
    };

    return !edgeCases[fullLocation]; // Returns true if the location is not an edge case!
  }

  /**
   * Assumes that the meeting has an appropriate maps link, and creates said link.
   *
   * @param meeting : A single instance of a course meeting.
   */
  public mapsLink(fullLocation: string, shortLocation: string) {
    let mapEdgeCases = {
      'Annex VII': '?details=Annex+VII', // MATH-231 07R2 16883
      'Red Barn': '?details=Red+Barn', // WINT-39  01 14154
      'Outside Tennis': '?details=Outdoor+Tennis+Courts', // WREC-82  01 14239
      'Ritter Ice Arena': '?details=Frank+Ritter+Ice+Arena', // WREC-62  02 16176
      '(SAU)-GAME': '?details=RITchie%27s+Game+Room', // WREC-16  01 14212
      '(HAC)-MINI': '?details=MINI:+Mini+Gym', // WMAR-11  01 14194
      '(HAC)-UDAN': '?details=UDAN:+Upper+Dance+Studio', // WDAN-01  01 13849
      '(HAC)-LDAN': '?details=LDAN:+Lower+Dance+Studio', // WDAN-07  01 13848

      // Unnecessary, but known, edge cases:
      // HAC - WDAN-15 01 13851
      // GOR - WREC-79 01 14253 and WFIT-70 01 14104 and WREC-22 01 14218
      // CLK - WMAR-56 01 14191
    };

    for (const key of Object.keys(mapEdgeCases)) {
      if (fullLocation.includes(key)) {
        return this.baseURL + mapEdgeCases[key];
      }
    }

    return this.baseURL + `?abbr=${shortLocation.substring(0, 3)}`;
  }
}
