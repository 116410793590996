import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { AppService } from '../app.service';
import { CurrTermResponse } from '../ModelInterfaces/CurrTermResponse';
import { AddToCartResponse } from './Models/AddToCartResponse';
import { EnrollDatesResponse } from './Models/EnrollDatesResponse';
import { EnrolledClassesResponse } from './Models/EnrolledClassesResponse';
import { PendingSwapsResponse } from './Models/PendingSwapsResponse';
import { ShoppingCartResponse } from './Models/ShoppingCartResponse';
import { SwapClassResponse } from './Models/SwapClassResponse';
import { EnrollmentApp, ShoppingDate } from './Models/TigerEnroll';

@Injectable()
/**
 * @class EnrollmentService
 * @author Fernando Flores & Paula Register
 * Provides services to retrieve and manipulate a user's shopping cart and schedule
 * Also provides other important enrollment information for a user such as their appointment times for enrollment
 * UID's should NEVER be handled on the client side since it can expose another persons info!!!
 * These services use SIS Gateway in the background for the request
 */
export class EnrollmentService {
  static termsRes: CurrTermResponse;
  static enrollRes: EnrollDatesResponse;
  static enrolledClassesRes: EnrolledClassesResponse;
  static enrollmentDateRes: EnrollmentApp[];
  static addDrop;
  static shoppingCartRes: ShoppingCartResponse = null;
  static shoppingDateRes: ShoppingDate[];
  static Loaded = false;
  static Loading = false;
  static numLoaded = 0;
  static numDataSources = 3;
  static shoppingCartIndexOfTermPicker = 0;
  static classScheduleIndexOfTermPicker = 0;
  static hideUnavaliableModal = false;
  static loading: {
    cart: boolean;
    schedule: boolean;
  };

  constructor(private http: HttpClient, private appService: AppService) {}

  /**
   * Gets the data for the current terms (used for the dropdown menus in the enrollment components)
   * @returns {Observable}
   */
  getTermData(): Observable<CurrTermResponse> {
    return this.appService.getCurrAndFutureTerms();
  }
  getAddDrop(term: string, career: string, sessionCode: string) {
    let params = new HttpParams().set('term', term).set('career', career).set('sessionCode', sessionCode);
    return this.http.get(environment.TCAuth + 'add-drop-date', { params: params });
  }
  /**
   * Gets the classes enrolled for
   * @param {string} term - the term you want to get enrolled classes for (ex: 2175)
   * @returns {Observable<EnrolledClassesResponse>}
   */
  getEnrolledClasses(term: string, career: string) {
    let params = new HttpParams().set('term', term).set('career', career);
    return this.http.get<EnrolledClassesResponse>(environment.TCAuth + 'enrolled-classes', { params: params });
  }

  /**
   * Gets the shopping cart for the desired term
   * @param {string} term - the term you want to get a shopping cart for (ex: 2175)
   * @returns {Observable<ShoppingCartResponse>}
   */
  getShoppingCart(term: string, career: string) {
    let params = new HttpParams().set('term', term).set('career', career);
    return this.http.get<ShoppingCartResponse>(environment.TCAuth + 'shopping-cart', { params: params });
  }

  /**
   * Gets the Important Enrollment Appointments, and shopping date appointments for the logged in user
   * @returns {Observable<EnrollDatesResponse>}
   */
  getEnrollDates() {
    return this.http.get<EnrollDatesResponse>(environment.TCAuth + 'enroll-dates-info');
  }
  getSISLinks() {
    return this.http.get(environment.TCAuth + 'SIS-links', { responseType: 'text' });
  }
  /**
   * Adds a class to a student's shopping cart.
   * @param {string} term - the shopping cart term the class will be added to
   * @param {string} ppid - the ppId of the class that will be added to the cart
   * @param {string} related1 - an associated lab or lecture that is require to be taken along with the class selected. (ex: BIO Lecture requires a LAB)
   * @param {string} related2 - there hasn't been a case where this is used, but is still here just in case the registrar decides to change that
   * @param {string} waitlistOK - if the student is ok with waitlisting this class if it is full
   * @param {string} gradingBasis - the grading scheme the student wants the class to use, if the option is available to me.
   * @param {string} permissionNumber - permission number for enrolling into a class
   * @author @ffits
   * @returns {Observable<Object>} - resolves to the updated shopping cart.
   */
  addClassToCart(
    term: string,
    ppid: string,
    related1: string,
    related2: string,
    waitlistOK: string,
    gradingBasis: string,
    permissionNumber: string,
    career: string
  ) {
    let params = new HttpParams()
      .set('term', term)
      .append('ppId', ppid)
      .append('related1', related1)
      .append('related2', related2)
      .append('waitlistOK', waitlistOK)
      .append('gradingBasis', gradingBasis)
      .append('permissionNumber', permissionNumber)
      .append('career', career);
    return this.http.get<AddToCartResponse>(environment.TCAuth + 'add', { params: params });
  }

  /**
   * Validates classes in the shoppingCart.
   * @param {string[]} ppIds - the ppIds of the classes that will be validated by SIS
   * @param {string} career - the career of the student who is validating (UGRD or GRD)
   * @param {string} term - the term for which we are validating for (i.e 2181, 2185, 2171 etc..)
   * @author @ffits
   * @return an observable
   */
  validateShoppingCart(ppIds: string[], career: string, term: string) {
    let params = new HttpParams().set('career', career).append('term', term);
    for (let ppId of ppIds) {
      params = params.append('ppId', ppId);
    }
    return this.http.get(environment.TCAuth + 'validate', { params: params });
  }

  /**
   * Enrolls multiple classes
   * @param {string} career - the career of the student who is validating (UGRD or GRD)
   * @param {string} term - the term for which we are validating for (i.e 2181, 2185, 2171 etc..)
   * @param {string[]} related1 - the ppId of a related class, if one exists. If it does not, then it is '0'
   * @param {string[]} related2 - the ppId of a related class, if one exists. If it does not, then it is '0'
   * @param {string[]} grading - the grading scheme the student wants the class to use, if the option is available to me.
   * @param {boolean[]} waitlist - booleans for waitlisting the class
   * @param {string[]} ppIds - the ppIds of the classes to be enrolled in
   * @returns {Observable<Object>}
   */
  enrollClasses(
    career: string,
    term: string,
    related1: string[],
    related2: string[],
    grading: string[],
    waitlist: boolean[],
    ppIds: string[]
  ) {
    let params = new HttpParams().set('career', career).append('term', term);
    for (let related of related1) {
      params = params.append('related1', related);
    }
    for (let related of related2) {
      params = params.append('related2', related);
    }
    for (let gradeOption of grading) {
      params = params.append('grading', gradeOption);
    }
    for (let waitlistOption of waitlist) {
      params = params.append('waitlist', waitlistOption.toString());
    }
    for (let ppId of ppIds) {
      params = params.append('ppId', ppId);
    }
    return this.http.get(environment.TCAuth + 'checkout', { params: params });
  }

  /**
   * Removes a class from a user's shopping cart
   * @param {string} ppId - the SISGW ppId for the class that will be dropped
   * @param {string} career - the career of the cart from which this class will be dropped
   * @param {string} term - the term for the shopping cart where the class will be taken down from.
   * @return {Observable<Object>}
   */
  removeClassFromCart(ppId: string, career: string, term: string) {
    let params = new HttpParams().set('career', career).append('term', term).append('ppId', ppId);
    return this.http.get(environment.TCAuth + 'remove', { params: params });
  }

  /**
   * Removes a class from a user's schedule
   * @param {string} ppId - the SISGW ppId for the class that will be dropped
   * @param {string} term - the term for the schedule where the class will be taken down from.
   * @param {string} career - the career of the schedule from which this class will be dropped
   * @returns {Observable<Object>}
   */
  removeClassFromSchedule(ppId: string, term: string, career: string) {
    let params = new HttpParams().set('ppId', ppId).append('term', term).append('career', career);
    return this.http.get(environment.TCAuth + 'drop', { params: params });
  }

  /**
   * Swaps a class for another class, uses SISGW in the backend in order to carry out the request
   * @param {string} oldTerm - the term for the class to you are swapping
   * @param {string} newTerm - the term for the new class you are swapping for
   * @param {string} oldPpid - the SISGW ppid for the class being swapped
   * @param {string} newPpid - the SISGW ppid for the class that is being swapped for
   * @param {string} term - the term you are currently in, I THINK
   * @param {string} career - the career of the selected student
   * @param {string} waitlist - the boolean as a string of whether the student chose to waitlist this class or not
   * @param {string} grading - the type of grading this class uses, should be set to GRD as default, or other if applicable, for example wellness classes that have an audit option
   * @param {string} related1 - the first related class for the parent class, (if a lecture requires a lab, then the lab class num willbe the related class num), value of zero if no related class
   * @param {string} related2 - the second related class, is not used, but exists in case the registar one day does add this functionality, besically it would make the function fail-safe
   * @return {Observable<Object>}
   */
  swapClass(
    oldTerm: string,
    newTerm: string,
    oldPpid: string,
    newPpid: string,
    term: string,
    career: string,
    waitlist: string,
    grading: string,
    related1: string,
    related2: string
  ): Observable<SwapClassResponse> {
    let params = new HttpParams()
      .set('oldterm', oldTerm)
      .append('newterm', newTerm)
      .append('oldppid', oldPpid)
      .append('newppid', newPpid)
      .append('term', term)
      .append('waitlist', waitlist)
      .append('grading', grading)
      .append('career', career)
      .append('related1', related1)
      .append('related2', related2);

    return this.http.get<SwapClassResponse>(environment.TCAuth + 'swap', { params: params });
  }

  getPendingSwaps(term: string): Observable<PendingSwapsResponse> {
    const params = new HttpParams().set('term', term);
    // return of(mockPendingSwaps1); //MOCK
    return this.http.get<PendingSwapsResponse>(environment.TCAuth + 'pending-swaps', { params: params });
  }
}
