<div class="row">
  <div class="col-xs-12">
    <div (click)="clickedX(); bundleSearchObject(''); dialogRef.close(bundleQueryTerm)">
      <img src="./assets/svgLib/x.svg" alt="" class="courseCatalogCancelIcon" />
    </div>
  </div>
  <p class="col-xs-12 courseCatalogTitleText">{{ title }}</p>
</div>
<mat-dialog-content *ngIf="haveCourseCatalogData" style="overflow-x: hidden" id="mat-content">
  <div class="row">
    <!--List of Colleges-->
    <mat-accordion displayMode="flat">
      <!--TODO: as of now, 11/21/17, the expansion panels actually won't fire off the open and close events in a -aot build. This is a bug, and this code should work perfectly fine when it's fixed.-->
      <!--https://github.com/angular/material2/issues/7172#issuecomment-332582894-->
      <mat-expansion-panel
        *ngFor="let catalog of this.masterCourseCatalog; trackBy: trackByFn; index as i;"
        [attr.id]="'colleges' + i"
        [expanded]="collegeStep === 'college' + i"
        (opened)="toggleCollegeColor(i); setCollegeStep(i)"
        (closed)="removeCollegeColor(i); removeCollegeStep(); removeDepartmentStep()"
      >
        <mat-expansion-panel-header class="collegesList">
          <mat-panel-title [attr.id]="'collegeName' + i">
            <span>{{catalog.collegeName}}</span>
          </mat-panel-title>
          <mat-panel-description [attr.id]="'collegeDescription' + i">
            <span> - {{catalog.collegeDescr}}</span>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <!--List of Departments-->
        <mat-accordion displayMode="flat">
          <mat-expansion-panel
            *ngFor="let department of catalog.departments; trackBy: trackByFn; index as j;"
            [attr.id]="'departments' + i + j"
            [expanded]="departmentStep === 'department' + i + j"
            (opened)="toggleDepartmentColor(i, j); setDepartmentStep(i, j)"
            (closed)="removeDepartmentColor(i, j); removeDepartmentStep()"
          >
            <mat-expansion-panel-header class="departmentsList" id="departmentsList">
              <mat-panel-title [attr.id]="'departmentName' + i + j">
                <span>{{department.departmentName}}</span>
              </mat-panel-title>
              <mat-panel-description [attr.id]="'departmentDescription' + i + j">
                <span> - {{department.departmentDescr}}</span>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <!--List of Courses-->
            <!--Static Headers-->
            <div class="row courseCatalogCourseHeaders">
              <!--Course Header-->
              <div class="col-xs-3 col-sm-2 courseHeadersCourseCode">
                <span class="courseCatalogCourseHeadersText">Course</span>
              </div>
              <!--Name Header-->
              <div class="col-xs-5 col-sm-6 courseHeadersCourseName">
                <span class="courseCatalogCourseHeadersText">Name</span>
              </div>
              <!--Typically Offered Header-->
              <div class="hidden-xs col-sm-4">
                <span class="courseCatalogCourseHeadersText">Typically Offered</span>
              </div>
              <div class="visible-xs col-xs-3 courseHeadersCourseTerm">
                <span class="courseCatalogCourseHeadersText">Offered</span>
              </div>
            </div>
            <!--Dynamic Results-->
            <ng-container *ngIf="departmentStep === 'department' + i + j">
              <div
                class="courseCatalogCourseResultsDecorator"
                *ngFor="let course of department.classes; trackBy: trackByFn; index as k;"
              >
                <!--BASIC DETAILS-->
                <div class="row">
                  <div
                    class="col-xs-12 courseCatalogDivHover"
                    [ngStyle]="{'background-color':course.backgroundColor}"
                    (click)="toggleDiv(course)"
                    style="cursor: pointer; padding: 0"
                  >
                    <!--Course Result-->
                    <div class="col-xs-3 col-sm-2 courseCatalogBasicResultsText courseCatalogCoursePaddingLeftRight">
                      <span>{{course.catalogNumber}}</span>
                    </div>
                    <!--Name Result-->
                    <div
                      class="visible-xs col-xs-5 courseCatalogBasicResultsText courseCatalogNamePaddingLeftRight courseCatalogCourseTitle"
                    >
                      <span>{{course.courseTitleLong}}</span>
                    </div>
                    <div class="hidden-xs col-sm-6 courseCatalogBasicResultsText courseCatalogNamePaddingLeftRight">
                      <span>{{course.courseTitleLong}}</span>
                    </div>
                    <!--Typically Offered Result-->
                    <div class="col-xs-3 col-sm-4 courseCatalogBasicResultsText courseCatalogOfferedPaddingLeftRight">
                      <span>{{course.courseTypicallyOff}}</span>
                    </div>
                  </div>
                  <!--EXPANDED DETAILS-->
                  <div *ngIf="!course.hideDiv" class="col-xs-12 courseCatalogExpandedDetailsDiv w3-animate-top">
                    <!--Desktop View-->
                    <div *ngIf="newInnerWidth >= 768">
                      <!--Class Details-->
                      <div class="col-sm-4" style="margin-top: 10px">
                        <span class="courseCatalogExpandedDetailsHeaderText col-xs-12">Class Details</span>
                        <!--Credits-->
                        <span class="courseCatalogExpandedDetailsBodyText col-xs-12">
                          Credits: {{course.credits}}
                        </span>
                        <!--End of Credits-->
                        <!--Grading Basis-->
                        <span class="courseCatalogExpandedDetailsBodyText col-xs-12"
                          >Grading: {{course.gradingBasis}}</span
                        >
                        <!--End of Grading Basis-->
                        <!--Type-->
                        <span class="courseCatalogExpandedDetailsBodyText col-xs-12">Type: {{course.component}}</span>
                        <!--End of Type-->
                        <!--Career-->
                        <span class="courseCatalogExpandedDetailsBodyText col-xs-12"
                          >Career: {{course.academicCareer}}</span
                        >
                        <!--End of Career-->
                      </div>
                      <!--End of Class Details-->
                      <div class="col-sm-8" style="margin-top: 10px">
                        <!--Class Description-->
                        <div class="row">
                          <span class="col-xs-12 courseCatalogExpandedDetailsHeaderText">Class Description</span>
                          <p class="col-xs-12 courseCatalogExpandedDetailsBodyText">{{course.courseDescription}}</p>
                        </div>
                        <!--End of Class Description-->
                        <!--Requisites-->
                        <div class="row">
                          <span class="col-xs-12 courseCatalogExpandedDetailsHeaderText">Requisites</span>
                          <div class="col-xs-6 courseCatalogNoPaddingLeftRight">
                            <!--Pre-Reqs-->
                            <span class="col-xs-12 courseCatalogExpandedDetailsHeader2Text">Pre-Reqs:</span>

                            <p
                              *ngIf="course.preReqDescrsLong.length === 0"
                              class="col-xs-12 courseCatalogExpandedDetailsBodyText"
                            >
                              No Prerequisites.
                            </p>
                            <p
                              *ngIf="course.preReqDescrsLong.length !== 0"
                              class="col-xs-12 courseCatalogExpandedDetailsBodyText"
                            >
                              {{course.preReqDescrsLong}}
                            </p>
                            <!--End of Pre-Reqs-->
                          </div>
                          <div class="col-xs-6 courseCatalogNoPaddingLeftRight">
                            <!--Consent-->
                            <span class="col-xs-12 courseCatalogExpandedDetailsHeader2Text">Consent:</span>
                            <p class="col-xs-12 courseCatalogExpandedDetailsBodyText">{{course.consent}}</p>
                            <!--End of Consent-->
                          </div>
                        </div>
                        <!--End of Requisites-->
                      </div>
                      <!--View Sections & Term Selector-->
                      <div class="col-xs-12" style="margin-top: 10px">
                        <div
                          class="styledSelect col-xs-5 col-sm-3 col-md-2 col-lg-2"
                          style="float: right; margin-right: 2%"
                        >
                          <select
                            class="styledSelectWidthFix"
                            [(ngModel)]="course.termIndex"
                            (change)="reloadSections(course)"
                            name="courseCatalogTermSelector"
                          >
                            <option *ngFor="let term of termDescrArray; index as i;" [ngValue]="i">{{term}}</option>
                          </select>
                        </div>
                        <button
                          class="col-xs-5 col-sm-3 col-md-2"
                          [ngClass]="changeSectionButton(course)"
                          (click)="toggleSections(course)"
                        >
                          <span>View Sections</span>
                        </button>
                      </div>
                      <!--Start of Sections-->
                      <div
                        *ngIf="course.sectionSpinner"
                        class="col-xs-12"
                        style="overflow: hidden; margin-bottom: 15px"
                      >
                        <mat-spinner color="primary"></mat-spinner>
                      </div>
                      <div
                        *ngIf="course.viewSection && course.sectionData.length === 0 && !course.sectionSpinner"
                        class="courseCatalogNoSectionResults"
                      >
                        <span>No sections available for the selected term.</span>
                      </div>
                      <div
                        *ngIf="course.viewSection && course.sectionData.length !== 0 && !course.sectionSpinner"
                        class="col-xs-11"
                        style="margin-left: 4%"
                      >
                        <!--Static Class Section Headers-->
                        <div class="col-xs-12 courseCatalogCourseHeaders">
                          <!--Name Header-->
                          <div class="col-sm-4 col-md-3">
                            <span class="courseCatalogCourseHeadersText">Name</span>
                          </div>
                          <!--Status Header-->
                          <div class="hidden-sm col-md-1" style="padding-left: 0">
                            <span class="courseCatalogCourseHeadersText">Status</span>
                          </div>
                          <div class="visible-sm col-sm-3" style="padding-left: 0; text-align: center">
                            <span class="courseCatalogCourseHeadersText">Status</span>
                          </div>
                          <!--Days/Times Header-->
                          <div class="col-xs-3" style="text-align: center">
                            <span class="courseCatalogCourseHeadersText">Days/Times</span>
                          </div>
                          <!--Location Header-->
                          <div class="hidden-sm col-md-1" style="text-align: center; margin-right: 5px">
                            <span class="courseCatalogCourseHeadersText">Location</span>
                          </div>
                          <!--Instructor Header-->
                          <div class="hidden-sm col-md-2" style="text-align: center">
                            <span class="courseCatalogCourseHeadersText">Instructor</span>
                          </div>
                          <!--Leaving 2 column spaces open on the far right for 'Search' buttons -->
                        </div>
                        <!--End of Class Section Headers-->
                        <!--Dynamic Class Section Results-->
                        <div class="col-xs-12" *ngFor="let section of course.sectionData; trackBy: trackByFn;">
                          <!--Name-->
                          <div class="col-sm-4 col-md-3">
                            <p class="courseCatalogBasicResultsText">
                              <span class="courseCatalogResultsDisplayName">{{section.courseTitleLong}}</span>
                              <span class="courseCatalogResultsDisplayName">{{section.combinedName}}</span>
                            </p>
                          </div>
                          <!--Status-->
                          <div class="col-sm-3 col-md-1 courseCatalogResultsStatusPaddingAdjust">
                            <p class="courseCatalogBasicResultsText">
                              <img src="{{section.statusImage}}" alt="" class="courseCatalogResultsStatusIcon" />
                              <span class="courseCatalogResultsStatusText" [ngStyle]="{'color': section.statusText}"
                                >{{section.enrollmentStatus}}</span
                              >
                              <span style="display: block">{{section.enrollmentTotal}}/{{section.enrollmentCap}}</span>
                            </p>
                          </div>
                          <!--Days/Times-->
                          <div class="col-xs-3" style="text-align: center">
                            <div *ngFor="let meeting of section.meetingsSortDayTimes; trackBy: trackByFn; index as x;">
                              <div *ngIf="x === 0">
                                <span
                                  *ngIf="x === 0"
                                  style="display: block; margin-bottom: 0"
                                  class="courseCatalogBasicResultsText"
                                  >{{meeting.days}}</span
                                >
                                <span
                                  *ngIf="x !== 0"
                                  style="display: block; margin-top: 0; margin-bottom: 0"
                                  class="courseCatalogBasicResultsText"
                                  >{{meeting.days}}</span
                                >
                                <span
                                  *ngIf="x !== section.meetingsSortDayTimes.length - 1"
                                  style="display: block; margin-top: 0; margin-bottom: 0"
                                  class="courseCatalogBasicResultsText"
                                  >{{meeting.time}}</span
                                >
                                <span
                                  *ngIf="x === section.meetingsSortDayTimes.length - 1"
                                  style="display: block; margin-top: 0"
                                  class="courseCatalogBasicResultsText"
                                  >{{meeting.time}}</span
                                >
                              </div>
                              <div
                                *ngIf="x !== 0 && section.meetingsSortDayTimes[x].daytimes !== section.meetingsSortDayTimes[x-1].daytimes;"
                              >
                                <span
                                  *ngIf="x === 0"
                                  style="display: block; margin-bottom: 0"
                                  class="courseCatalogBasicResultsText"
                                  >{{meeting.days}}</span
                                >
                                <span
                                  *ngIf="x !== 0"
                                  style="display: block; margin-top: 0; margin-bottom: 0"
                                  class="courseCatalogBasicResultsText"
                                  >{{meeting.days}}</span
                                >
                                <span
                                  *ngIf="x !== section.meetingsSortDayTimes.length - 1"
                                  style="display: block; margin-top: 0; margin-bottom: 0"
                                  class="courseCatalogBasicResultsText"
                                  >{{meeting.time}}</span
                                >
                                <span
                                  *ngIf="x === section.meetingsSortDayTimes.length - 1"
                                  style="display: block; margin-top: 0"
                                  class="courseCatalogBasicResultsText"
                                  >{{meeting.time}}</span
                                >
                              </div>
                            </div>
                          </div>
                          <!--Location-->
                          <div class="hidden-sm col-xs-1" style="text-align: center">
                            <div *ngFor="let meeting of section.meetingsSortLocation; trackBy: trackByFn; index as k;">
                              <div *ngIf="section.campus === 'MAIN'; else off_campus_1">
                                <div
                                  *ngIf="courseMapService.hasMapLink(meeting.location) && k === 0; else first_location_TBD"
                                >
                                  <a
                                    href="{{courseMapService.mapsLink(meeting.location, meeting.locationShort)}}"
                                    target="_blank"
                                  >
                                    <p
                                      style="margin-bottom: 0; cursor: pointer !important"
                                      (click)="toggleDiv(section)"
                                      class="courseCatalogBasicResultsText blueText"
                                    >
                                      {{meeting.locationShort}}
                                    </p>
                                  </a>
                                </div>
                              </div>
                              <ng-template #first_location_TBD>
                                <p *ngIf="k === 0" style="margin-bottom: 0" class="courseCatalogBasicResultsText">
                                  {{meeting.locationShort}}
                                </p>
                              </ng-template>
                              <ng-template #off_campus_1>
                                <p *ngIf="k === 0" style="margin-bottom: 0" class="courseCatalogBasicResultsText">
                                  {{meeting.locationShort}}
                                </p>
                              </ng-template>
                              <div *ngIf="section.campus === 'MAIN'; else off_campus_2">
                                <div
                                  *ngIf="courseMapService.hasMapLink(meeting.location) && k >= 1 && section.meetingsSortLocation[k-1].locationShort !== section.meetingsSortLocation[k].locationShort; else second_location_TBD"
                                >
                                  <a
                                    href="{{courseMapService.mapsLink(meeting.location, meeting.locationShort)}}"
                                    target="_blank"
                                  >
                                    <p
                                      style="margin-top: 0; cursor: pointer !important"
                                      (click)="toggleDiv(section)"
                                      class="courseCatalogBasicResultsText blueText"
                                    >
                                      {{meeting.locationShort}}
                                    </p>
                                  </a>
                                </div>
                              </div>
                              <ng-template #second_location_TBD>
                                <p
                                  *ngIf="k >= 1 && section.meetingsSortLocation[k-1].locationShort !== section.meetingsSortLocation[k].locationShort"
                                  class="courseCatalogBasicResultsText"
                                  style="margin-top: 0"
                                >
                                  {{meeting.locationShort}}
                                </p>
                              </ng-template>
                              <ng-template #off_campus_2>
                                <p
                                  *ngIf="k >= 1 && section.meetingsSortLocation[k-1].locationShort !== section.meetingsSortLocation[k].locationShort"
                                  class="courseCatalogBasicResultsText"
                                  style="margin-top: 0"
                                >
                                  {{meeting.locationShort}}
                                </p>
                              </ng-template>
                            </div>
                          </div>
                          <!--Instructor-->
                          <div class="hidden-sm col-md-2" style="text-align: center">
                            <div *ngFor="let meeting of section.meetingsSortInstructor; trackBy: trackByFn; index as z">
                              <div *ngIf="z === 0">
                                <div
                                  *ngIf="meeting.instructorEmail != null && meeting.instructorEmail.trim() !== ''; else no_email_1"
                                >
                                  <a href="mailto:{{meeting.instructorEmail}}">
                                    <p
                                      (click)="toggleDiv(section)"
                                      class="courseCatalogBasicResultsText blueText"
                                      style="cursor: pointer !important; margin-bottom: 0"
                                    >
                                      {{meeting.instructor}}
                                    </p>
                                  </a>
                                </div>
                              </div>
                              <div
                                *ngIf="z !== 0 && section.meetingSortInstructor[z].instructor !== section.meetingSortInstructor[z-1].instructor;"
                              >
                                <div
                                  *ngIf="meeting.instructorEmail != null && meeting.instructorEmail.trim() !== ''; else no_email_2"
                                >
                                  <a href="mailto:{{meeting.instructorEmail}}">
                                    <p
                                      (click)="toggleDiv(section)"
                                      class="courseCatalogBasicResultsText blueText"
                                      style="cursor: pointer !important; margin-top: 0; margin-bottom: 0"
                                    >
                                      {{meeting.instructor}}
                                    </p>
                                  </a>
                                </div>
                              </div>
                              <ng-template #no_email_1>
                                <p class="courseCatalogBasicResultsText" style="margin-bottom: 0">
                                  {{meeting.instructor}}
                                </p>
                              </ng-template>
                              <ng-template #no_email_2>
                                <p class="courseCatalogBasicResultsText" style="margin-top: 0; margin-bottom: 0">
                                  {{meeting.instructor}}
                                </p>
                              </ng-template>
                            </div>
                          </div>
                          <!--Search Button-->
                          <button
                            class="col-xs-2 courseCatalogSearchButton"
                            (click)="bundleSearchObject(section.classNumber); dialogRef.close(bundleQueryTerm)"
                          >
                            Search
                          </button>
                          <!--End of Search Button-->
                        </div>
                        <!--End of Class Section Results-->
                      </div>
                      <!--End of Sections-->
                    </div>
                    <!--End of Desktop View-->
                    <!--Mobile View-->
                    <div *ngIf="newInnerWidth <= 767">
                      <mat-tab-group class="col-xs-12" dynamicHeight="true">
                        <mat-tab label="Details">
                          <!--Class Details-->
                          <div class="row">
                            <!--Credits-->
                            <span class="courseCatalogExpandedDetailsBodyText col-xs-12">
                              Credits: {{course.credits}}
                            </span>
                            <!--End of Credits-->
                            <!--Grading Basis-->
                            <span class="courseCatalogExpandedDetailsBodyText col-xs-12"
                              >Grading: {{course.gradingBasis}}</span
                            >
                            <!--End of Grading Basis-->
                            <!--Type-->
                            <span class="courseCatalogExpandedDetailsBodyText col-xs-12"
                              >Type: {{course.component}}</span
                            >
                            <!--End of Type-->
                            <!--Career-->
                            <span class="courseCatalogExpandedDetailsBodyText col-xs-12"
                              >Career: {{course.academicCareer}}</span
                            >
                            <!--End of Career-->
                          </div>
                          <!--End of Class Details-->
                        </mat-tab>
                        <mat-tab label="Description">
                          <!--Class Description-->
                          <div class="row">
                            <p class="col-xs-12 courseCatalogExpandedDetailsBodyText">{{course.courseDescription}}</p>
                          </div>
                          <!--End of Class Description-->
                        </mat-tab>
                        <mat-tab label="Requisites">
                          <!--Requisites-->
                          <div class="row">
                            <div class="col-xs-12 courseCatalogNoPaddingLeftRight">
                              <!--Pre-Reqs-->
                              <span class="col-xs-12 courseCatalogExpandedDetailsHeader2Text">Pre-Reqs:</span>
                              <p
                                *ngIf="course.preReqDescrsLong.length === 0"
                                class="col-xs-12 courseCatalogExpandedDetailsBodyText"
                              >
                                No Prerequisites.
                              </p>
                              <p
                                *ngIf="course.preReqDescrsLong.length !== 0"
                                class="col-xs-12 courseCatalogExpandedDetailsBodyText"
                              >
                                {{course.preReqDescrsLong}}
                              </p>
                              <!--End of Pre-Reqs-->
                            </div>
                            <div class="col-xs-12 courseCatalogNoPaddingLeftRight">
                              <!--Consent-->
                              <span class="col-xs-12 courseCatalogExpandedDetailsHeader2Text">Consent:</span>
                              <p class="col-xs-12 courseCatalogExpandedDetailsBodyText">No Special Consent Required.</p>
                              <!--End of Consent-->
                            </div>
                          </div>
                          <!--End of Requisites-->
                        </mat-tab>
                      </mat-tab-group>
                      <!--View Sections & Term Selector-->
                      <div class="col-xs-12" style="margin-top: 10px">
                        <div class="styledSelect col-xs-5" style="float: right; margin-right: 8%">
                          <select
                            class="styledSelectWidthFix"
                            [(ngModel)]="course.termIndex"
                            (change)="decideSelectedTerm(course.termIndex); reloadSections(course)"
                            name="courseCatalogTermSelector"
                          >
                            <option *ngFor="let term of termDescrArray; index as i;" [ngValue]="i">{{term}}</option>
                          </select>
                        </div>
                        <button
                          class="courseCatalogViewSectionsButton col-xs-5"
                          [ngClass]="changeSectionButton(course)"
                          (click)="toggleSections(course)"
                        >
                          <span>Sections</span>
                        </button>
                      </div>
                      <!--Start of Sections-->
                      <div *ngIf="course.sectionSpinner" class="col-xs-12" style="margin-bottom: 15px">
                        <mat-spinner color="primary"></mat-spinner>
                      </div>
                      <div
                        *ngIf="course.viewSection && course.sectionData.length === 0 && !course.sectionSpinner"
                        class="courseCatalogNoSectionResults"
                      >
                        <span>No sections available for the selected term.</span>
                      </div>
                      <div
                        *ngIf="course.viewSection && course.sectionData.length !== 0 && !course.sectionSpinner"
                        class="col-xs-11"
                        style="margin-left: 4%; padding: 0"
                      >
                        <!--Static Class Section Headers-->
                        <div class="col-xs-12 courseCatalogCourseHeaders courseCatalogNoPaddingLeftRight">
                          <!--Name Header-->
                          <div class="col-xs-5">
                            <span class="courseCatalogCourseHeadersText">Name</span>
                          </div>
                          <!--Status Header-->
                          <div class="col-xs-3" style="padding-left: 0; text-align: center">
                            <span class="courseCatalogCourseHeadersText">Status</span>
                          </div>
                          <!--Days/Times Header-->
                          <div class="col-xs-4" style="text-align: center">
                            <span class="courseCatalogCourseHeadersText">Days</span>
                          </div>
                          <!--Leaving 0 column spaces open on the far right for 'Search' buttons, on mobile user simply needs to click the div to search-->
                        </div>
                        <!--End of Class Section Headers-->
                        <!--Dynamic Class Section Results-->
                        <div
                          class="col-xs-12"
                          *ngFor="let section of course.sectionData; trackBy: trackByFn;"
                          (click)="bundleSearchObject(section.classNumber); dialogRef.close(bundleQueryTerm)"
                          style="cursor: pointer"
                        >
                          <!--Name-->
                          <div class="col-xs-5" style="padding-left: 0">
                            <p class="courseCatalogBasicResultsText">
                              <span class="courseCatalogResultsDisplayName courseCatalogCourseTitle"
                                >{{section.courseTitleLong}}</span
                              >
                              <span class="courseCatalogResultsDisplayName">{{section.combinedName}}</span>
                            </p>
                          </div>
                          <!--Status-->
                          <div class="col-xs-3 courseCatalogResultsStatusPaddingAdjust">
                            <p class="courseCatalogBasicResultsText" style="text-align: center">
                              <img src="{{section.statusImage}}" alt="" class="courseCatalogResultsStatusIcon" />
                              <span class="courseCatalogResultsStatusText" [ngStyle]="{'color': section.statusText}"
                                >{{section.enrollmentStatus}}</span
                              >
                              <span style="display: block; cursor: pointer"
                                >{{section.enrollmentTotal}}/{{section.enrollmentCap}}</span
                              >
                            </p>
                          </div>
                          <!--Days/Times-->
                          <div class="col-xs-4 courseCatalogNoPaddingLeftRight" style="text-align: center">
                            <div *ngFor="let meeting of section.meetingsSortDayTimes; trackBy: trackByFn; index as x;">
                              <div *ngIf="x === 0">
                                <span
                                  *ngIf="x === 0"
                                  style="display: block; margin-bottom: 0"
                                  class="courseCatalogBasicResultsText"
                                  >{{meeting.days}}</span
                                >
                                <span
                                  *ngIf="x !== 0"
                                  style="display: block; margin-top: 0; margin-bottom: 0"
                                  class="courseCatalogBasicResultsText"
                                  >{{meeting.days}}</span
                                >
                                <span
                                  *ngIf="x !== section.meetingsSortDayTimes.length - 1"
                                  style="display: block; margin-top: 0; margin-bottom: 0"
                                  class="courseCatalogBasicResultsText"
                                  >{{meeting.time}}</span
                                >
                                <span
                                  *ngIf="x === section.meetingsSortDayTimes.length - 1"
                                  style="display: block; margin-top: 0"
                                  class="courseCatalogBasicResultsText"
                                  >{{meeting.time}}</span
                                >
                              </div>
                              <div
                                *ngIf="x !== 0 && section.meetingsSortDayTimes[x].daytimes !== section.meetingsSortDayTimes[x-1].daytimes;"
                              >
                                <span
                                  *ngIf="x === 0"
                                  style="display: block; margin-bottom: 0"
                                  class="courseCatalogBasicResultsText"
                                  >{{meeting.days}}</span
                                >
                                <span
                                  *ngIf="x !== 0"
                                  style="display: block; margin-top: 0; margin-bottom: 0"
                                  class="courseCatalogBasicResultsText"
                                  >{{meeting.days}}</span
                                >
                                <span
                                  *ngIf="x !== section.meetingsSortDayTimes.length - 1"
                                  style="display: block; margin-top: 0; margin-bottom: 0"
                                  class="courseCatalogBasicResultsText"
                                  >{{meeting.time}}</span
                                >
                                <span
                                  *ngIf="x === section.meetingsSortDayTimes.length - 1"
                                  style="display: block; margin-top: 0"
                                  class="courseCatalogBasicResultsText"
                                  >{{meeting.time}}</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--End of Class Section Results-->
                      </div>
                      <!--End of Sections-->
                    </div>
                    <!--End of Mobile View-->
                  </div>
                </div>
              </div>
            </ng-container>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</mat-dialog-content>
<div class="mySpinnerHolder">
  <div class="mySpinner" *ngIf="!(haveCourseCatalogData)"></div>
</div>
