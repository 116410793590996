/**
 * Created by kjsisd1 on 9/13/17.
 */
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'advanced-search-dialog',
  templateUrl: './advanced-search-dialog.template.html',
  styleUrls: ['./advanced-search-dialog.style.css', '../../class-search.style.css'],
})

/**
 * @class Advanced Search is a heavy logic centric component that involves many different parameters and transactions with SIS
 * This dialog can be brought up anytime a user wishes while within Class Search and will take effect once the user searches something
 * after having clicked Save Options inside the dialog (a green check-mark will animate to show the save took effect).
 */
export class AdvancedSearchDialogComponent {
  public title: string;

  /** populated in constructor **/
  //Removed hard coded values
  attributesBase: any; //store base attributes object

  //structured holding of data - parent/child att relationships
  attributesValueDict = {}; //key = main, values = children, or code if no children
  attributesNameDict = {}; //similar to above but names to display
  attributesDefHiddenDict = {}; //whether or not they should be hidden by default

  //for holding the names and values without relationships
  attributesValueList = []; //list of all values
  attributesNameList = []; // list of all names
  attributesDefHiddenList = []; // list of all default hidden values
  mainAttribs = []; //list of main att names
  mainAttribsVals = []; //list of main att values

  // all the options zipped up, if you will. Used to pass into the Class Search Component (and passes back).
  // bundleMasterObject() will populate this once save is clicked by the user
  advancedSearchFilterOptionsBundle = {
    filterAnd: null,
    filterOptions: {},
    filterOptionsChecked: [],
    filterOptionsExpandedHidden: [],
    dropdownSubjectList: {},
  };

  filterAnd: boolean = true;
  advancedSearchFilterOptions = {
    campusTermOptions: {
      session: null,
      campus: null,
    },
    courseTypeOptions: {
      college: null,
      subject: null,
      instructor: null,
      credits: {
        creditsMax: null,
        creditsMin: null,
        precision: null,
      },
      career: null,
      component: null,
      instructionType: null,
    },
    courseAttributeOptions: [
      //push values in array when checkboxes are clicked - determines visibly checked boxes whereas the below array is what is actually passed to the back end
    ],
    courseAttributeOptionsPassed: [
      //what actually gets passed in the end
    ],
  };
  advancedSearchFilterOptionsChecked: boolean[] = []; // this changes during runtime via ngModel
  advancedSearchFilterOptionsExpandedHidden: boolean[] = []; //this changes during runtime via function call
  advancedSearchDynamicDropdowns: any;
  dynamicDropdownSubjectList: any;
  currentTerm: string = '';
  userTodo: string = '';

  // Used for ngModel bindings
  // '' or -1 are the placeholder values
  // except credit data
  sessionValue: string = null;
  campusValue: string = '';
  collegeValue: string = '';
  subjectValue: string = null;
  instructorQuery: string = '';
  creditsMaxValue: number = 8;
  creditsMinValue: number = 0;
  creditsPrecisionValue: number = 1;
  careerValue: string = '';
  componentValue: string = '';
  instructionValue: string = '';

  /**
   * Fetches the dyanmic dropdown values to be used, grabbed from endpoint calls within Class Search.
   * If the user has previously clicked Save Options, it will instantiate all the necessary variables accordingly.
   *
   * @param dialogRef
   * @param filterOptionsCopy
   */
  constructor(
    public dialogRef: MatDialogRef<AdvancedSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public filterOptionsCopy: any
  ) {
    // dynamic dropdown values (fetched from an endpoint call in class search)
    this.advancedSearchDynamicDropdowns = filterOptionsCopy.advancedSearchDynamicDropdowns;
    this.currentTerm = filterOptionsCopy.currentTerm;

    //reduce hardcoded attributes
    this.attributesBase = this.advancedSearchDynamicDropdowns.attributes;

    //populate key/values and lists with main/child attribs values and names, set default hidden
    for (let mainAttr in this.attributesBase) {
      this.attributesValueList.push(mainAttr);
      this.attributesNameList.push(this.attributesBase[mainAttr].description);
      this.attributesDefHiddenDict[mainAttr] = false;
      this.attributesDefHiddenList.push(false);
      this.advancedSearchFilterOptionsChecked.push(false);

      let lesserValuesArray = [];
      let lesserNamesArray = [];
      for (let lowerAttr in this.attributesBase[mainAttr]) {
        let i = 0;
        let lastValue = '';
        if (lowerAttr.includes('Values')) {
          for (let value in this.attributesBase[mainAttr][lowerAttr]) {
            i++;
            lesserValuesArray.push(value);
            this.attributesValueList.push(value);
            this.attributesNameList.push(this.attributesBase[mainAttr][lowerAttr][value]);
            lesserNamesArray.push(this.attributesBase[mainAttr][lowerAttr][value]);
            this.attributesDefHiddenDict[value] = true;
            this.attributesDefHiddenList.push(true);
            this.advancedSearchFilterOptionsChecked.push(false);
            lastValue = value;
          }
        }
        if (i == 1) {
          delete this.attributesDefHiddenDict[mainAttr];
          this.attributesDefHiddenDict[lastValue] = false;
          this.attributesValueList.splice(this.attributesValueList.length - 2, 1);
          this.attributesNameList.splice(this.attributesNameList.length - 2, 1);
          this.attributesDefHiddenList.pop();
          this.advancedSearchFilterOptionsChecked.pop();
        }
      }

      if (lesserValuesArray.length == 0 && lesserNamesArray.length == 0) {
        lesserValuesArray.push(mainAttr);
        lesserNamesArray.push(this.attributesBase[mainAttr].description);
      }

      this.advancedSearchFilterOptionsExpandedHidden.push(true);
      this.attributesValueDict[mainAttr] = lesserValuesArray;
      this.attributesNameDict[this.attributesBase[mainAttr].description] = lesserNamesArray;
    }

    for (let main in this.attributesNameDict) {
      if (this.attributesNameDict[main].length == 1) {
        this.mainAttribs.push(this.attributesNameDict[main][0]);
      } else {
        this.mainAttribs.push(main);
      }
    }

    for (let main in this.attributesValueDict) {
      this.mainAttribsVals.push(main);
    }

    //end fixing hard-coded stuff

    if (filterOptionsCopy.filterOptions) {
      // saved drop-downs
      this.sessionValue =
        filterOptionsCopy.filterOptions.campusTermOptions.session != null
          ? filterOptionsCopy.filterOptions.campusTermOptions.session
          : null;
      this.campusValue =
        filterOptionsCopy.filterOptions.campusTermOptions.campus != null
          ? filterOptionsCopy.filterOptions.campusTermOptions.campus
          : '';
      this.collegeValue =
        filterOptionsCopy.filterOptions.courseTypeOptions.college != null
          ? filterOptionsCopy.filterOptions.courseTypeOptions.college
          : '';
      this.subjectValue = filterOptionsCopy.filterOptions.courseTypeOptions.subject;
      this.dynamicDropdownSubjectList = filterOptionsCopy.dropdownSubjectList;
      this.instructorQuery =
        filterOptionsCopy.filterOptions.courseTypeOptions.instructor != null
          ? filterOptionsCopy.filterOptions.courseTypeOptions.instructor
          : '';
      this.creditsMaxValue =
        filterOptionsCopy.filterOptions.courseTypeOptions.credits.creditsMax != null
          ? filterOptionsCopy.filterOptions.courseTypeOptions.credits.creditsMax
          : 8;
      this.creditsMinValue =
        filterOptionsCopy.filterOptions.courseTypeOptions.credits.creditsMin != null
          ? filterOptionsCopy.filterOptions.courseTypeOptions.credits.creditsMin
          : 0;
      this.creditsPrecisionValue =
        filterOptionsCopy.filterOptions.courseTypeOptions.credits.precision != null
          ? filterOptionsCopy.filterOptions.courseTypeOptions.credits.precision
          : 1;
      this.careerValue =
        filterOptionsCopy.filterOptions.courseTypeOptions.career != null
          ? filterOptionsCopy.filterOptions.courseTypeOptions.career
          : '';
      this.componentValue =
        filterOptionsCopy.filterOptions.courseTypeOptions.component != null
          ? filterOptionsCopy.filterOptions.courseTypeOptions.component
          : '';
      this.instructionValue =
        filterOptionsCopy.filterOptions.courseTypeOptions.instructionType != null
          ? filterOptionsCopy.filterOptions.courseTypeOptions.instructionType
          : '';

      // saved slide toggle and checkboxes
      this.filterAnd = filterOptionsCopy.filterAnd;
      this.advancedSearchFilterOptions = filterOptionsCopy.filterOptions;
      this.advancedSearchFilterOptionsChecked = filterOptionsCopy.filterOptionsChecked;
      this.advancedSearchFilterOptionsExpandedHidden = filterOptionsCopy.filterOptionsExpandedHidden;
    }
  }

  // HUGE performance optimization. I mean it.
  /**
   * Massively increases Angular's performance with regards to *ngFor
   *
   * @param index
   * @param item
   * @returns {any}
   */
  trackByFn(index, item) {
    return index; // or item.id
  }

  /**
   * Packs everything up in one large object to be sent away back to Class Search.
   */
  bundleMasterObject() {
    this.advancedSearchFilterOptionsBundle.filterAnd = this.filterAnd;
    this.advancedSearchFilterOptionsBundle.filterOptions = this.advancedSearchFilterOptions;
    this.advancedSearchFilterOptionsBundle.filterOptionsChecked = this.advancedSearchFilterOptionsChecked;
    this.advancedSearchFilterOptionsBundle.filterOptionsExpandedHidden = this.advancedSearchFilterOptionsExpandedHidden;
    this.advancedSearchFilterOptionsBundle.dropdownSubjectList = this.dynamicDropdownSubjectList;
  }

  /**
   * Used for the reset and cancel buttons within the dialog. Does what it sounds like...wipes everything back to a fresh slate.
   */
  wipeAdvancedSearchOptions() {
    this.advancedSearchFilterOptions = {
      campusTermOptions: {
        session: null,
        campus: null,
      },
      courseTypeOptions: {
        college: null,
        subject: null,
        instructor: null,
        credits: {
          creditsMax: null,
          creditsMin: null,
          precision: null,
        },
        career: null,
        component: null,
        instructionType: null,
      },
      courseAttributeOptions: [],
      courseAttributeOptionsPassed: [],
    };

    this.sessionValue = null;
    this.campusValue = '';
    this.collegeValue = '';
    this.subjectValue = null;
    this.instructorQuery = '';
    this.creditsMaxValue = 8;
    this.creditsMinValue = 0;
    this.creditsPrecisionValue = 1;
    this.careerValue = '';
    this.componentValue = '';
    this.instructionValue = '';

    this.filterAnd = true;
    for (let i = 0; i < this.advancedSearchFilterOptionsChecked.length; i++) {
      this.advancedSearchFilterOptionsChecked[i] = false;
    }
    for (let i = 0; i < this.advancedSearchFilterOptionsExpandedHidden.length; i++) {
      this.advancedSearchFilterOptionsExpandedHidden[i] = true;
    }
    this.dynamicDropdownSubjectList = null;
  }

  /**
   * Inverts the **filterAnd** variable. And = true, Or = false.
   */
  setAndOrLogic() {
    this.filterAnd = !this.filterAnd;
  }

  /**
   * This function is responsible for checking to make sure that for every Parent checkbox (any checkbox that reveals more checkboxes
   * when clicked), there is at least one child checkbox clicked.
   * If it is discovered that there isn't at least one, the template will alert the user and disable the Save Options button.
   * @returns {boolean}
   */
  checkMandatoryCheckboxes() {
    //message to display - only displayed if not "".
    this.userTodo = '';
    //loop through filter options checked
    for (let i = 0; i < this.advancedSearchFilterOptionsChecked.length; i++) {
      //set not checked amount to 0
      let notChecked = 0;
      //loop through main and
      for (let main in this.mainAttribs) {
        //if the current filter option/att being checked is a main
        if (this.mainAttribs[main] == this.attributesNameList[i]) {
          //and if it is checked
          if (this.advancedSearchFilterOptionsChecked[i]) {
            //then loop through it's children in the Name Dict
            for (let child in this.attributesNameDict[this.mainAttribs[main]]) {
              //and if a child is checked, break because the user input is good and ready to be passed out - no message necessary for this main attrib.
              if (
                this.advancedSearchFilterOptionsChecked[
                  this.attributesNameList.indexOf(this.attributesNameDict[this.mainAttribs[main]][child])
                ]
              ) {
                break;
              }
              //but if it's not checked
              else {
                //add to notChecked
                notChecked++;
                //and if notChecked is equal to the number of children
                if (notChecked == this.attributesNameDict[this.mainAttribs[main]].length) {
                  //add to the message, which will cause it to display
                  this.userTodo += "' " + this.mainAttribs[main] + " '" + ' , ';
                }
              }
            }
          }
        }
      }
    }

    if (this.userTodo === '') {
      // no further action needed from user
      return false;
    } else {
      // alert the user and cut off the extra comma space at the end of the string.
      this.userTodo = this.userTodo.substring(0, this.userTodo.length - 2);
      return true;
    }
  }

  /**
   * Returns whether or not the children checkboxes should be unchecked (e.g. this needs to happen when a user chooses
   * to deselect a Parent checkbox without unchecking all the children checkboxes).
   * @param index
   * @returns {boolean}
   */
  calculateHidden(index: number) {
    //for each main attribute
    for (let main in this.mainAttribs) {
      //if the index being looked at is not the main itself
      if (this.mainAttribs[main] != this.attributesNameList[index]) {
        //and the main's children are set to be displayed
        if (this.advancedSearchFilterOptionsExpandedHidden[main] === false) {
          //then check the name of what's being looked at against the names of the children of the main attribute being looked at
          let name = this.attributesNameList[index];
          for (let child in this.attributesNameDict[this.mainAttribs[main]]) {
            if (name == this.attributesNameDict[this.mainAttribs[main]][child]) {
              //and if it is a child, return false - it doesn't need to be unchecked
              return false;
            }
          }
        }
        //if it is meant to be hidden
        else if (this.advancedSearchFilterOptionsExpandedHidden[main] === true) {
          //then get the name of what's being looked at
          let name = this.attributesNameList[index];
          //and if it matches the name of a child of the main attrib being looked at
          for (let child in this.attributesNameDict[this.mainAttribs[main]]) {
            if (name == this.attributesNameDict[this.mainAttribs[main]][child]) {
              //return true that it should be unchecked
              return true;
            }
          }
        }
      }
    }

    return false;
  }

  /**
   * This function keeps the **advancedSearchFilterOptionsChecked** array up to date when checkboxes are selected and
   * deselected.
   * If a parent checkbox is selected, it will reveal the previously hidden child checkboxes while if a parent checkbox
   * is deselected it will hide all the previously revealed child checkboxes.
   *
   * @param filterItem
   * @param isChecked
   * @param i
   */
  changeAttributeArray(filterItem: string, isChecked: boolean, i: number) {
    //if it is checked
    if (isChecked) {
      //push to the arrays, one to control display and one for what's actually passed back
      this.advancedSearchFilterOptions.courseAttributeOptions.push(filterItem);
      this.advancedSearchFilterOptions.courseAttributeOptionsPassed.push(filterItem);

      //push item if true and not a main with children
      for (let j = 0; j < this.mainAttribsVals.length; j++) {
        if (filterItem == this.mainAttribsVals[j]) {
          this.advancedSearchFilterOptionsExpandedHidden[j] = false;
          //if it has actual children, then pop it from what is passed back - was causing issues when parent was passed back with children
          if (this.attributesValueDict[filterItem].length > 1) {
            this.advancedSearchFilterOptions.courseAttributeOptionsPassed.pop();
          }
        }
      }

      document.getElementById(i + '').hidden = this.calculateHidden(i);
    } else {
      //remove item if false
      //get indices
      let index = this.advancedSearchFilterOptions.courseAttributeOptions.indexOf(filterItem);
      let indexPassed = this.advancedSearchFilterOptions.courseAttributeOptionsPassed.indexOf(filterItem);

      //if it exists in the array, remove it - handles mains not being in OptionsPassed array
      if (index !== -1) {
        this.advancedSearchFilterOptions.courseAttributeOptions.splice(index, 1);
      }
      if (indexPassed !== -1) {
        this.advancedSearchFilterOptions.courseAttributeOptionsPassed.splice(indexPassed, 1);
      }

      //then loop through the main values and if it is equal to what is being looked at...
      for (let j = 0; j < this.mainAttribsVals.length; j++) {
        if (filterItem == this.mainAttribsVals[j]) {
          // ...hide all children elements in the view
          this.advancedSearchFilterOptionsExpandedHidden[j] = true;
          // set advancedSearchOptionsChecked to be false for all children
          // remove all children from the arrays
          for (let z = 0; z < this.attributesValueDict[filterItem].length; z++) {
            this.advancedSearchFilterOptionsChecked[i + z + 1] = false;

            let removeIndex = this.advancedSearchFilterOptions.courseAttributeOptions.indexOf(
              this.attributesValueDict[filterItem][z]
            );
            let removeIndexPassed = this.advancedSearchFilterOptions.courseAttributeOptionsPassed.indexOf(
              this.attributesValueDict[filterItem][z]
            );

            if (removeIndex !== -1) {
              this.advancedSearchFilterOptions.courseAttributeOptions.splice(removeIndex, 1);
            }
            if (removeIndexPassed !== -1) {
              this.advancedSearchFilterOptions.courseAttributeOptionsPassed.splice(removeIndexPassed, 1);
            }
          }
        }
      }

      document.getElementById(i + '').hidden = this.calculateHidden(i);
    }
  }

  setSession(): void {
    this.advancedSearchFilterOptions.campusTermOptions.session = this.sessionValue;
  }

  setCampus(): void {
    this.advancedSearchFilterOptions.campusTermOptions.campus = this.campusValue;
  }

  setCollege(): void {
    this.advancedSearchFilterOptions.courseTypeOptions.college = this.collegeValue;
    this.subjectValue = null;
    this.advancedSearchFilterOptions.courseTypeOptions.subject = this.subjectValue;
  }

  grabSubjects() {
    if (this.collegeValue != '') {
      this.dynamicDropdownSubjectList =
        this.advancedSearchDynamicDropdowns.colleges[this.collegeValue].subjects[this.currentTerm];
    } else {
      this.dynamicDropdownSubjectList = null;
      this.subjectValue = '';
    }
  }

  setSubject(): void {
    this.advancedSearchFilterOptions.courseTypeOptions.subject = this.subjectValue;
  }

  setInstructor(): void {
    //turn into better format for search - capitalize first letter of each word
    //need to consider XxXxxxx kind of names and hyphens with capitals after Xxx-Xxxx (but some are Xx-xxx, and X'Xxxxx
    let inString = this.instructorQuery.split(' ');
    for (let i = 0; i < inString.length; i++) {
      //check for capitals if there are two, then don't change it - likely cover cases when the user types it correctly
      let numUpper = inString[i].length - inString[i].replace(/[A-Z]/g, '').length;
      //if there's two, assume it's right rather than lock the user out of correct spellings
      if (numUpper != 2) {
        //make lower case and first letter upper
        inString[i] = inString[i].toLowerCase();
        inString[i] = inString[i].charAt(0).toUpperCase() + inString[i].substring(1);

        //for aposts - make sure it's correct
        let checkForApost = inString[i].indexOf("'");
        if (checkForApost != -1 && inString[i].indexOf(inString[i].charAt(checkForApost + 1)) != -1) {
          inString[i] =
            inString[i].substring(0, checkForApost + 1) +
            inString[i].charAt(checkForApost + 1).toUpperCase() +
            inString[i].substring(checkForApost + 2);
        }
      }
    }
    this.instructorQuery = inString.join(' ');
    this.advancedSearchFilterOptions.courseTypeOptions.instructor = this.instructorQuery;
  }

  /**
   * If 'between' is selected as the desired precision, then creditsMax is set to creditMax as expected, however if any other
   * precision is selected the creditsMax is set to creditsMin because that's how the backend wants it.
   */
  setCredits(): void {
    this.advancedSearchFilterOptions.courseTypeOptions.credits.creditsMin = this.creditsMinValue;
    this.advancedSearchFilterOptions.courseTypeOptions.credits.creditsMax =
      this.creditsPrecisionValue === 1 ? this.creditsMaxValue : this.creditsMinValue;
    this.advancedSearchFilterOptions.courseTypeOptions.credits.precision = this.creditsPrecisionValue;
  }

  resetCreditsMaxVal() {
    this.creditsMaxValue = 8;
  }

  setCareer(): void {
    this.advancedSearchFilterOptions.courseTypeOptions.career = this.careerValue;
  }

  setComponent(): void {
    this.advancedSearchFilterOptions.courseTypeOptions.component = this.componentValue;
  }

  setInstruction(): void {
    this.advancedSearchFilterOptions.courseTypeOptions.instructionType = this.instructionValue;
  }
}
