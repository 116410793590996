import { Injectable } from '@angular/core';
import axios from 'axios';
import { Observable } from 'rxjs/Observable';
import { shareReplay } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HTTPInterceptorService } from '../HTTPInterceptor/HTTPInterceptor.service';
import { UserModel } from './Models/UserModel';

const userInfoServiceAPI = axios.create({
  baseURL: environment.TCAuth,
  withCredentials: true,
});

export const SIS_LOCKED = 'SIS_LOCKED';

@Injectable()
/**
 * @class This class makes a call to the login_shib/tc/user endpoint and grabs the
 * relevant shib data needed to display in the view (first name, last name, and UID).
 */
export class UserInfoService {
  private user = null;
  public universityId = '';
  public sisLockedUser = null;
  private user$: Observable<UserModel> = new Observable<UserModel>((observer) => {
    userInfoServiceAPI
      .get('user')
      .then((response) => {
        let resObj;
        resObj = {};

        // if we can't find users info use default
        if (response.data.firstName || response.data.lastName || response.data.universityId) {
          resObj.isPublicUser = false;
          resObj.firstName = response.data.firstName;
          resObj.lastName = response.data.lastName;
          resObj.accountType = response.data.accountType;
          resObj.universityId = response.data.universityId;
          resObj.sisLocked = response.data.sisLocked;
          resObj.termsActivated = response.data.termsActivated;
          resObj.username = response.data.username;
          this.user = resObj;
        } else {
          resObj.isPublicUser = true;
          observer.error('public user');
          return;
        }

        const sisLocked = response.data.sisLocked;
        if (sisLocked) {
          this.sisLockedUser = this.user;
          this.user = null;
          observer.error(SIS_LOCKED);
          return;
        }

        observer.next(resObj);
        observer.complete();
      })
      .catch((err) => {
        observer.error(err);
      });
  }).pipe(shareReplay());

  constructor(httpInterceptor: HTTPInterceptorService) {
    userInfoServiceAPI.interceptors.response.use(
      (response) => httpInterceptor.responseHandler(response),
      (error) => {
        httpInterceptor.errorHandler(error);
      }
    );
  }

  /**
   * Important thing to note with this function (besides the service call explained in the class doc above) is that
   * if we can't find one field of a user's info then they are assumed to be a public user
   * @returns {Observable}
   */
  getUserInfo() {
    return this.user$;
  }

  getUser(): UserModel {
    if (this.user === null || !this.user.universityId) {
      this.getUserInfo();
    } else {
      return this.user;
    }
  }

  isSisLocked(): boolean {
    return this.user == null && this.sisLockedUser != null;
  }
}
