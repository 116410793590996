<div *ngIf="appService.forcedDown">
  <!--Maintenance Checks-->
  <check-maintenance></check-maintenance>
  <!--End of Maintenance Checks-->
</div>

<div *ngIf="!appService.forcedDown" (konami)="enableStarWars()">
  <div *ngIf="starWarsEasterEgg" class="starWarsBackground">
    <star-wars></star-wars>
  </div>
  <div *ngIf="!starWarsEasterEgg" class="container">
    <div class="row">
      <div class="col-xs-12">
        <div class="row">
          <img
            class="tc-bannerImg col-xs-12"
            src="./assets/images/tcLandingPageLogo.png"
            title="Tiger Center. For Students, By Students"
            alt="Tiger Center. For Students, By Students"
          />
        </div>
        <div class="row">
          <div class="col-md-4 col-xs-3"></div>
          <a class="btn btn-lg tc-student-login-btn col-md-4 col-xs-6" routerLink="/login_shib/tc/login"
            >Student Login</a
          >
          <div class="col-md-4 col-xs-3"></div>
        </div>
        <br />
        <div class="row tc-landing-mar">
          <table width="100%">
            <tr>
              <td><hr class="col-xs-6 col-sm-10 col-md-11 col-lg-11 tc-hr-color-fix" /></td>
              <td class="tc-public-text">PUBLIC FEATURES</td>
              <td><hr class="col-xs-7 col-sm-10 col-md-11 col-lg-11 tc-hr-color-fix" /></td>
            </tr>
          </table>
        </div>

        <div class="row">
          <a routerLink="./../api/class-search" class="col-xs-6 col-sm-6 col-md-6 col-lg-6 tc-link-padding">
            <img class="tc-landing-img" src="./assets/svgLib/search_landing.svg" />
            <div class="img-text distanceFix">Class Search</div>
          </a>
          <a routerLink="./../api/gpa-calc" class="col-xs-6 col-sm-6 col-md-6 col-lg-6 tc-link-padding">
            <div class="img-text largeShow">GPA Calculator</div>
            <img class="tc-landing-img" src="./assets/svgLib/calculator_landing.svg" />
            <div class="img-text largeHidden distanceFix">GPA Calculator</div>
          </a>
        </div>

        <div class="row">
          <a routerLink="./../api/hours-and-locations" class="col-xs-6 col-sm-6 col-md-6 col-lg-6 tc-link-padding">
            <img class="tc-landing-img alignFix" src="./assets/svgLib/h&l_landing.svg" /><br />
            <div class="img-text distanceFix alignFixText">Hours & Locations</div>
          </a>
          <a href="{{links.maps}}" target="_blank" class="col-xs-6 col-sm-6 col-md-6 col-lg-6 tc-link-padding">
            <div class="img-text mapSpace largeShow">Maps at RIT</div>
            <img class="tc-landing-map-img" src="./assets/svgLib/maps_landing.svg" /><br />
            <div class="img-text mapSpace largeHidden">Maps at RIT</div>
          </a>
        </div>

        <div class="tc-landing-white-space"></div>
      </div>
    </div>

    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </div>
</div>
