<!--Start of Mini Header-->
<div class="row no-gutters" *ngIf="loadingComplete">
  <div class="col-xs-8 col-sm-10">
    <img src="./assets/svgLib/TClogo.svg" alt="" class="userInfoTCLogo" />
    <div class="userInfoWelcomeDiv" *ngIf="isPublicUser === false">
      <span class="userInfoGreetingsText userInfoNoLastNameMobile"
        >Hello, {{userInfo.firstName}} {{userInfo.lastName}}</span
      >
      <span class="userInfoGreetingsText userInfoLastNameMobile">Hello, {{userInfo.firstName}}</span>
      <span (click)="showingUID = !showingUID" class="userInfoUIDText clickHide">
        UID: <span *ngIf="!showingUID">Show <img id="arrowRight" src="./assets/svgLib/rightArrow.svg" alt="" /></span>
        <ng-container *ngIf="showingUID">{{ uid }}</ng-container>
        <span *ngIf="showingUID"><img id="arrowLeft" src="./assets/svgLib/leftArrow.svg" alt="" /> Hide</span>
      </span>
    </div>
    <div class="userInfoWelcomeDiv" *ngIf="isPublicUser === true">
      <span class="userInfoUIDText">Welcome to</span>
      <span class="userInfoPublicGreetingsText">Tiger Center</span>
    </div>
  </div>
  <div class="col-xs-4 col-sm-2" style="margin-top: 3%" *ngIf="isPublicUser === false">
    <a class="userInfoLogoutButton" mat-button href="{{links.logout}}" style="float: right">
      <img src="./assets/svgLib/logoff_orange.svg" alt="" class="userInfoLogoutIcon" />
      <span class="userInfoLogoutButtonText">Log off</span>
    </a>
  </div>
  <div class="col-xs-4 col-sm-2" style="margin-top: 3%" *ngIf="isPublicUser === true">
    <a class="userInfoLogoutButton" mat-button href="./../tigerCenterApp/login_shib/tc/homepage" style="float: right">
      <img src="./assets/svgLib/logoff_orange.svg" alt="" class="userInfoLogoutIcon" />
      <span class="userInfoLogoutButtonText">Log in</span>
    </a>
  </div>
</div>

<!--End of Mini Header-->
