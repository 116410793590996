/**
 * Created by kjsisd1 on 9/14/17.
 */
import { HttpClient, HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, HostListener, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClassSearchService } from 'app/ClassSearch/class-search.service';
import { AllClassSearchObj } from 'app/ClassSearch/Models/AllClassSearchObj';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/operator/map';
import * as sortBy from 'sort-by'; // sorts anything by a specific field.
import { environment } from '../../../../environments/environment';
import { CourseMapService } from '../../CourseMapping/course-map.service';
import { CourseCatalogService } from './course-catalog-dialog.service';

@Component({
  selector: 'course-catalog-dialog',
  templateUrl: './course-catalog-dialog.template.html',
  styleUrls: ['./course-catalog-dialog.style.css', '../../../../assets/styles/w3-style-fixes.css'],
})

/**
 * @class This dialog displays a list of all the colleges, then all the majors, then all the courses, then all the sections
 * if the user so chooses. It's a lot in volume, but asycn network calls and caching have helped out with it's performance.
 */
export class CourseCatalogDialogComponent implements AfterViewInit {
  public title: string;

  allClassSearch: AllClassSearchObj = new AllClassSearchObj('', '');

  // this is what we send back to the class search component.
  bundleQueryTerm = {
    query: null,
    term: null,
    cache: null,
    clickedX: null,
    collegeStep: null,
    departmentStep: null,
    xCoord: null,
    yCoord: null,
  };
  userClose = false;
  collegeStep: string;
  departmentStep: string;
  xCoord: number;
  yCoord: number;
  cleanDialog: boolean = true;
  dialogContent: any;
  scrollCounter: number = 0;

  // We need to mimic the exact class search request otherwise the POST will fail
  searchParams = {
    // whatever the user types
    query: '',
    // determined by network call
    term: '',
    filterAnd: null,
    isAdvanced: false,
    campus: null,
    session: null,
    courseAttributeOptions: [],
    career: null,
    college: null,
    component: null,
    creditsMax: null,
    creditsMin: null,
    precision: null,
    instructionType: null,
    instructor: null,
    subject: null,
  };
  sectionSpinner: boolean = false;

  termsArray: string[] = [];
  termDescrArray: string[] = [];
  newInnerWidth: any = window.innerWidth;

  params = new HttpParams();
  colleges = [];
  masterCourseCatalog: any = [
    // all async responses will be placed in here.
  ];
  haveCourseCatalogData: boolean = false;

  /**
   * This constructor makes async calls to grab all the data needed for all the different colleges (one per college)
   * if there is no cached data already. These calls will only happen once, then everything is cached.
   *
   * @param dialogRef
   * @param courseCatalogDataInject
   * @param http
   * @param classSearchService
   * @param courseCatalogService
   */
  constructor(
    public dialogRef: MatDialogRef<CourseCatalogDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public courseCatalogDataInject: any,
    private http: HttpClient,
    private classSearchService: ClassSearchService,
    private courseCatalogService: CourseCatalogService,
    private courseMapService: CourseMapService
  ) {
    this.setupTermDesc(this.courseCatalogDataInject.terms);
    this.masterCourseCatalog = courseCatalogDataInject.cache;

    this.xCoord = courseCatalogDataInject.xCoord;
    this.yCoord = courseCatalogDataInject.yCoord;

    // only perform async calls if data is not already cached.
    if (this.masterCourseCatalog && this.masterCourseCatalog.length === 0) {
      this.http.get(environment.TC + 'courseCatalog').subscribe((response) => {
        let index = 0,
          college;
        Object.keys(response).forEach((collegeName) => {
          college = response[collegeName];
          if (college.departments.length !== 0) {
            this.masterCourseCatalog[index] = college;
            index++;
          }
        });
        this.masterCourseCatalog.sort(sortBy('collegeName'));
        this.haveCourseCatalogData = true;
      });
    } else {
      // data is cached, set boolean to true after a bit of a wait to allow the spinner to occur
      setTimeout(() => {
        this.haveCourseCatalogData = true;
      }, 100);
    }
  }

  /**
   * As we are not passing the actual descriptions, make a call to build the descriptions manually.
   * @param {string[]} terms
   */
  setupTermDesc(terms: string[]) {
    for (let i = 0; i < terms.length; i++) {
      this.termsArray[i] = terms[i];
      this.termDescrArray[i] = this.formatTermString(terms[i]);
    }
  }

  /**
   * This Angular lifecycle function is necessary to appropriately reopen the user back to wherever they were in the dialog.
   *
   * > Note: Again, this does not work as of now using an Angular Prod build due to a known issue with Angular AOT builds
   * > not firing off the (opened) and (closed) events on mat-expansion-panels. See the *courseCatalog* function in
   * > **class-search.dialogs.service.ts** for more information.
   */
  ngAfterViewInit() {
    let tempThis = this;
    setTimeout(function () {
      tempThis.collegeStep = tempThis.courseCatalogDataInject.collegeStep;
      tempThis.departmentStep = tempThis.courseCatalogDataInject.departmentStep;
      tempThis.cleanDialog = tempThis.collegeStep === '' && tempThis.departmentStep === '';
    }, 100);
  }

  /**
   * This function fires off many times due to how angular handles this event and on the sixth time it actually moves to
   * the correct position, so after 6 times, no more going in the if statement. This is necessary or the re-opening element
   * of Course Catalog.
   */
  ngAfterViewChecked() {
    if (this.haveCourseCatalogData && this.scrollCounter <= 5) {
      this.dialogContent = document.getElementById('mat-content');
      if (this.dialogContent && !this.cleanDialog) {
        let tempThis = this;
        setTimeout(function () {
          tempThis.dialogContent.scrollTo(tempThis.xCoord, tempThis.yCoord);
          tempThis.scrollCounter++;
        }, 100);
      }
    }
  }

  /**
   * This HostListener will be called **any** time the window is resized. It's used for a massive performance boost.
   * The difference between [hidden]="" and ngIf="" is massive in terms of performance and this method allows us to use
   * ngIf=""
   * > Note: The short reason why this is the case is because ngIf is Angular and Angular completely **adds/removes** the element
   * > to the DOM. Hidden is simply an HTML attribute and only **hides** it from the DOM.
   *
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;
  }

  // HUGE performance optimization. I mean it.
  /**
   * Massively increases Angular's performance with regards to *ngFor
   *
   * @param index
   * @param item
   * @returns {any}
   */
  trackByFn(index, item) {
    return index; // or item.id
  }

  /**
   * This function packs a single level object to pass into the class search component with all the juicy re-opening
   * and caching variables needed, as well as the query and term they intend to search if they clicked search from the section
   * level of Course Catalog.
   *
   * @param classNumber
   */
  bundleSearchObject(classNumber: string) {
    this.xCoord = this.dialogContent.scrollLeft;
    this.yCoord = this.dialogContent.scrollTop;

    this.bundleQueryTerm.collegeStep = this.collegeStep;
    this.bundleQueryTerm.departmentStep = this.departmentStep;
    this.bundleQueryTerm.xCoord = this.xCoord;
    this.bundleQueryTerm.yCoord = this.yCoord;
    this.bundleQueryTerm.cache = this.masterCourseCatalog;
    if (this.userClose) {
      this.bundleQueryTerm.clickedX = true;
    } else {
      this.bundleQueryTerm.clickedX = false;
      this.bundleQueryTerm.query = classNumber;
      this.bundleQueryTerm.term = this.searchParams.term;
    }
  }

  /**
   * This function inverts the **hideDiv** boolean, sets the background color appropriately, and  expands a given div
   * and makes the call to preReqs endpoint
   *
   * @param course
   */
  toggleDiv(course): void {
    course.hideDiv = !course.hideDiv;
    if (course.hideDiv) {
      course.backgroundColor = 'white';
    } else {
      course.backgroundColor = '#ffebb7';
    }
    try {
      this.courseCatalogService.getPreReqsData(course.ppSearchId).subscribe(
        (value) => {},
        (error) => console.log(error),
        () => {
          if (this.courseCatalogService.preReqsResults !== '') {
            course.preReqDescrsLong = [];
            course.preReqDescrsLong.push(this.courseCatalogService.preReqsResults);
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * This function simply changes the view sections button in the view (the background and text color).
   *
   * @param course
   * @returns {any}
   */
  changeSectionButton(course) {
    if (course.viewSection) {
      return 'courseCatalogViewSectionsButtonClicked';
    }
    return 'courseCatalogViewSectionsButtonUnclicked';
  }

  /**
   * This changes the **viewSection** boolean and then loads the sections if it is true
   *
   * @param course
   */
  toggleSections(course) {
    course.viewSection = !course.viewSection;
    if (course.viewSection) {
      this.loadSections(course);
    }
  }

  /**
   * This adds the coloring of the college mat-expansion-panel when it is selected by the user.
   *
   * > Note: since this is fired off by (opened), as of now this will not work using an Angular production build. Please
   * > see the *ngAfterViewInit* function in this class for more detail.
   *
   * @param index
   */
  toggleCollegeColor(index: number) {
    if (document.getElementById('collegeName' + index) && document.getElementById('collegeDescription' + index)) {
      document.getElementById('collegeName' + index).style.color = '#f86a18';
      document.getElementById('collegeDescription' + index).style.color = '#f86a18';
    }
  }

  /**
   * This removes the coloring of the college mat-expansion-panel when it is deselected by the user.
   *
   * > Note: since this is fired off by (closed), as of now this will not work using an Angular production build. Please
   * > see the *ngAfterViewInit* function in this class for more detail.
   *
   * @param index
   */
  removeCollegeColor(index: number) {
    if (document.getElementById('collegeName' + index) && document.getElementById('collegeDescription' + index)) {
      document.getElementById('collegeName' + index).style.color = 'rgba(0, 0, 0, 0.87)';
      document.getElementById('collegeDescription' + index).style.color = 'rgba(0, 0, 0, 0.54)';
    }
  }

  /**
   * This adds the coloring of the department mat-expansion-panel when it is selected by the user.
   *
   * > Note: since this is fired off by (opened), as of now this will not work using an Angular production build. Please
   * > see the *ngAfterViewInit* function in this class for more detail.
   *
   * @param collegeIndex
   * @param departmentIndex
   */
  toggleDepartmentColor(collegeIndex: number, departmentIndex: number) {
    if (
      document.getElementById('departmentName' + collegeIndex + departmentIndex) &&
      document.getElementById('departmentDescription' + collegeIndex + departmentIndex)
    ) {
      document.getElementById('departmentName' + collegeIndex + departmentIndex).style.color = '#f86a18';
      document.getElementById('departmentDescription' + collegeIndex + departmentIndex).style.color = '#f86a18';
    }
  }

  /**
   * This removes the coloring of the department mat-expansion-panel when it is deselected by the user.
   *
   * > Note: since this is fired off by (closed), as of now this will not work using an Angular production build. Please
   * > see the *ngAfterViewInit* function in this class for more detail.
   *
   * @param collegeIndex
   * @param departmentIndex
   */
  removeDepartmentColor(collegeIndex: number, departmentIndex: number) {
    if (
      document.getElementById('departmentName' + collegeIndex + departmentIndex) &&
      document.getElementById('departmentDescription' + collegeIndex + departmentIndex)
    ) {
      document.getElementById('departmentName' + collegeIndex + departmentIndex).style.color = 'rgba(0, 0, 0, 0.87)';
      document.getElementById('departmentDescription' + collegeIndex + departmentIndex).style.color =
        'rgba(0, 0, 0, 0.54)';
    }
  }

  /**
   * Sets the **collegeStep** to whatever unique mat-expansion-panel is selected.
   *
   * @param index
   */
  setCollegeStep(index: number) {
    this.collegeStep = 'college' + index;
  }

  /**
   * Sets the **departmentStep** to whatever unique mat-expansion-panel is selected.
   *
   * @param collegeIndex
   * @param departmentIndex
   */
  setDepartmentStep(collegeIndex: number, departmentIndex: number) {
    // Sometimes the department step is not cleared before it is attempted to be set. This recursive call fixes that
    // bug in order to ensure that the department is set and not then immediately cleared.
    if (this.departmentStep) {
      setTimeout((_) => {
        this.setDepartmentStep(collegeIndex, departmentIndex);
      }, 100);
    } else {
      this.departmentStep = 'department' + collegeIndex + departmentIndex;
    }
  }

  /**
   * Wipes the **collegeStep** variable back to an empty string.
   */
  removeCollegeStep() {
    this.collegeStep = '';
  }

  /**
   * Wipes the **departmentStep** variable back to an empty string.
   */
  removeDepartmentStep() {
    this.departmentStep = '';
  }

  /**
   * Inverts the **userClose** variable when the user clicks the X at the top right-hand corner of the dialog.
   */
  clickedX(): void {
    this.userClose = !this.userClose;
  }

  /**
   * Make network call to populate section data and set boolean flag for section data to enable/disable the spinner and
   * display or not display the sections.
   * @param course
   */
  private loadSections(course) {
    this.searchParams.query = course.courseId;
    this.decideSelectedTerm(course.termIndex);
    let currTerm = this.termsArray[0];
    course.sectionSpinner = true;
    try {
      this.classSearchService.getClassSearchData(this.searchParams, currTerm).subscribe(
        (value) => {
          this.allClassSearch = value;
        },
        (error) => console.log(error),
        () => {
          course.sectionSpinner = false;
          course.sectionData = this.allClassSearch.allClassSearch;
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * When term is changed, reload div containing the sections. Note, for convenience, even if a user forgets to click view sections,
   * as long as they change the dropdown, it will automatically load the sections.
   * @param course
   */
  reloadSections(course) {
    course.viewSection = true;
    this.loadSections(course);
  }

  /**
   * This function is called whenever the term dropdown is changed in class search. It will kick off the correct functions
   * to update the selected term to pass back into Class Search.
   *
   * @param index
   */
  decideSelectedTerm(index: number) {
    this.searchParams.term = this.termsArray[index];
  }

  /**
   * This function takes in an unformatted term (e.g. 2171) and transforms it into a nicely formatted string (e.g. "2017-18 Fall (2171)").
   * > 2171 becomes 2017-18 Fall
   * > 2175 becomes 2017-18 Spring
   * > 2178 becomes 2017-18 Summer
   * @param term
   * @returns {string}
   */
  formatTermString(term: string): string {
    let startYear = term.substring(1, 3);
    let endYear = parseInt(startYear) + 1 + '';
    let semesterId = term.substring(3);
    let semesterName = '';
    switch (semesterId) {
      case '1':
        semesterName = 'Fall';
        break;
      case '5':
        semesterName = 'Spring';
        break;
      case '8':
        semesterName = 'Summer';
        break;
      default:
        semesterName = 'Term ' + semesterId;
    }
    return '20' + startYear + ' - ' + endYear + ' ' + semesterName + ' (' + term + ')';
  }
}
