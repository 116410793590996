/**
 * Created by sebisd on 7/10/17.
 */

import { TCModel } from '../../../ModelInterfaces/TCModel';
import { LabContact } from './LabContactObj';
import { LabEvent } from './LabEventObj';
import { LabHardware } from './LabHardwareObj';
import { LabPrinter } from './LabPrintObj';
import { LabSoftware } from './LabSoftwareObj';

export class LabObject implements TCModel {
  locationId: string = null;
  departmentId: string = null;
  name: string = null;
  description: string = null;
  summary: string = null;
  mapsurl: string = 'https://maps.rit.edu/~phpmaps/m?multi=';
  mapId: string = null;
  buildingCode: string = null;
  roomNumber: string = null;
  activeFlag: string = null;
  mrkId: string = null;
  catId: string = null;
  mdoId: string = null;
  restrictedMessage: string = null;
  restrictedFlag: string = null;
  openNow: number = 0;
  hideDiv: boolean = true;
  filterItems: string[] = [];
  macFileToUse: string;
  windowsFileToUse: string;
  printerFileToUse: string;
  shownDate: string;

  labEvents: LabEvent[] = [];
  labContacts: LabContact[] = [];
  labSoftware: LabSoftware[] = [];
  labHardware: LabHardware[] = [];
  labPrinters: LabPrinter[] = [];

  /**
   * @constructor For a single Lab Object, this is what holds all the information that a location has
   * this information is independent from the date param that was used by the service to get the data, as well as the
   * event object which is contained within this object.
   *
   * > **Note:** The mapsurl check is because at the creation of this model, the maps url returned null so
   * > we take the maps baseurl and append the building code to it. If it's not null however, we do a straight assign.
   *
   * @param { object } data is the JSON response, this constructor instantiates all the necessary variables.
   */
  constructor(data) {
    this.locationId = data['loc_id'];
    this.departmentId = data['dept_id'];
    this.name = data['loc_name'];
    this.description = data['description'];
    this.summary = data['full_description'];
    // For whatever reason at the creation of this model, the maps url returned null so
    // we construct it if it's null based on the same method classes uses.
    if (data['mapsurl'] == null) {
      this.mapsurl += data['building'];
    } else {
      this.mapsurl = data['mapsurl'];
    }
    this.buildingCode = data['building'];
    this.roomNumber = data['room_number'];
    this.activeFlag = data['active_flag'];
    this.mapId = data['map_id'];
    this.mrkId = data['mrk_id'];
    this.mdoId = data['mdo_id'];
    this.catId = data['cat_id'];
    this.restrictedFlag = data['restricted_flag'];
    this.restrictedMessage = data['restricted_message'];

    if (this.summary.toLowerCase().includes('mac')) {
      this.filterItems.push('MAC');
    }
    if (this.summary.toLowerCase().includes('windows')) {
      this.filterItems.push('WINDOWS');
    }
  }

  /**
   * Used to create the event for the lab object. Since the eventsList, the contactsList, the hardware array,
   * the printer array, the reserved classes array, and the software array aren't always necessarily populated,
   * a check needs to be done to know if we actually have these data entries.
   *
   * The this.openNow variable is based off of current time (e.g. if you have three events for one lab and the current
   * time falls within the advancedSearch time and the end time of one of these events, then this.openNow could potentially be
   * any of the status options. However, if the current time is outside the borders of the events' advancedSearch times and end
   * times, then this.openNow would be considered closed or no data, depending.
   *
   *
   * @param { object } data is the JSON response.
   */
  mapData(data): void {
    let contactsList = data['labContacts'][this.locationId];
    let eventList = data['labEvents'][this.locationId];
    let hardware = data['labHardware'][this.locationId];
    let printers = data['labPrints'][this.locationId];
    let csClasses = data['labCSClasses'][this.locationId];
    let software = data['labSoftware'][this.locationId];

    for (let contact of contactsList) {
      this.labContacts.push(new LabContact(contact));
    }

    for (let printer of printers) {
      if (!this.filterItems.includes('PRINTER')) {
        this.filterItems.push('PRINTER');
      }
      this.labPrinters.push(new LabPrinter(printer));
    }

    for (let comp of hardware) {
      let tempHardware = new LabHardware(comp);
      if (tempHardware.macFlag && !this.filterItems.includes('MAC')) {
        this.filterItems.push('MAC');
      }
      if (tempHardware.windowFlag && !this.filterItems.includes('WINDOWS')) {
        this.filterItems.push('WINDOWS');
      }
      this.labHardware.push(tempHardware);
    }

    for (let program of software) {
      let tempSoftware = new LabSoftware(program);
      if (tempSoftware.name.toLowerCase().includes('mac') && !this.filterItems.includes('MAC')) {
        this.filterItems.push('MAC');
      }
      if (tempSoftware.name.toLowerCase().includes('window') && !this.filterItems.includes('WINDOWS')) {
        this.filterItems.push('WINDOWS');
      }
      this.labSoftware.push(tempSoftware);
    }

    if (eventList.length > 0) {
      for (let event of eventList) {
        let tempEvent = new LabEvent(event);
        tempEvent.generateTimeLine(csClasses);
        tempEvent.determineEventOpen();
        this.labEvents.push(tempEvent);
      }
    } else if (csClasses && csClasses.length > 0) {
      let tempEvent = new LabEvent('');
      tempEvent.generateTimeLine(csClasses);
      tempEvent.determineEventOpen();
      this.labEvents.push(tempEvent);
    } else {
      let noDataEvent = new LabEvent('');
      noDataEvent.generateTimeLineNoData();
      this.labEvents.push(noDataEvent);
    }

    let arrayLocs = {
      Sunday: null,
      Monday: null,
      Tuesday: null,
      Wednesday: null,
      Thursday: null,
      Friday: null,
      Saturday: null,
    };

    if (this.labEvents.length > 1) {
      for (let evt in this.labEvents) {
        for (let x in this.labEvents[evt].days) {
          arrayLocs[this.labEvents[evt].days[x].weekday] = evt;
        }
      }
      for (let lne in this.labEvents[0].timeLine) {
        this.labEvents[0].timeLine[lne] = this.labEvents[arrayLocs[lne]].timeLine[lne];
      }
      let eventCount = this.labEvents.length;
      for (let q = 1; q < eventCount; q++) {
        delete this.labEvents[q];
      }
      this.labEvents.length = 1;
    }

    this.openNow = this.labEvents[0].determineEventOpen();

    this.macFileToUse = './assets/svgLib/apple_gray.svg';
    this.windowsFileToUse = './assets/svgLib/windows_gray.svg';
    this.printerFileToUse = './assets/svgLib/printer_gray.svg';
    for (let filter of this.filterItems) {
      if (filter === 'PRINTER') {
        this.printerFileToUse = './assets/svgLib/printer_orange.svg';
      } else if (filter === 'MAC') {
        this.macFileToUse = './assets/svgLib/apple_orange.svg';
      } else if (filter === 'WINDOWS') {
        this.windowsFileToUse = './assets/svgLib/windows_orange.svg';
      }
    }
  }
}
