<!--If app is shutdown, don't show the sidenav, disable all routing, but still show header and footer-->
<div *ngIf="envName != 'Prod' && this.showBanner" (click)="hideBanner()">
  <div *ngIf="envName == 'Dev'" class="env-header">
    You are on TC Dev, please go to Prod if SIS locked. Click to hide.
  </div>
  <div style="background-color: red" *ngIf="envName == 'Stage'" class="env-header">
    You are on TC Stage, please go to Prod if SIS locked. Click to hide.
  </div>
  <div style="background-color: #8000ff" *ngIf="envName == 'Test'" class="env-header">
    You are on TC Test, please go to Prod if SIS locked. Click to hide.
  </div>
  <!-- Below is for testing purposes -->
  <div style="background-color: #00c6ff" *ngIf="envName == 'Local'" class="env-header">
    You are on TC Local, please go to Prod if SIS locked. Click to hide.
  </div>
</div>
<div *ngIf="appService.forcedDown">
  <!--Header-->
  <headers></headers>
  <!--End of Header-->
  <!--Maintenance Checks-->
  <check-maintenance></check-maintenance>
  <!--End of Maintenance Checks-->
  <!--Footer-->
  <footers></footers>
  <!--End of Footer-->
</div>
<div id="top"></div>
<!--Otherwise, show the sidenav and run the app-->
<div *ngIf="!appService.forcedDown">
  <!--Header inside-->
  <!--Dictates how the hamburger menu appears for xs,sm,md devices-->
  <mat-sidenav-container class="tc-app-header-fixed" style="background-color: white">
    <div class="tc-side-nav-header" [hidden]="hideNav">
      <button class="row hidden-lg" mat-icon-button style="color: white" (click)="sidenav.toggle()">
        <img [src]="sidenav.opened ? menuIconClose : menuIconOpen" alt="" class="appSidenavCheeseburger" />
      </button>
    </div>
    <headers></headers>
  </mat-sidenav-container>

  <!--App content inside-->
  <!--Dictates how the hamburger menu appears for lg devices-->
  <!--Contains sidenav popup for all device sizes-->
  <mat-sidenav-container
    class="tc-header-content-padding"
    id="content-sidenav-container"
    style="background-color: white; margin-top: -4px; height: auto; position: static"
  >
    <mat-sidenav #sidenav mode="over" fixedInViewport>
      <mat-nav-list class="appNavList">
        <div mat-list-item>
          <button mat-icon-button (click)="sidenav.toggle()" style="margin: 0 8px 0 auto; display: block">
            <img [src]="menuIconClose" alt="" class="appSidenavCheeseburger" />
          </button>
        </div>
        <!--TODO: add if public to a tag below-->
        <a mat-list-item routerLink="." (click)="sidenav.toggle()" class="tc-nav-padding">
          <img src="./assets/svgLib/TC_menu.svg" alt="" class="appSidenavTCImage" />
        </a>
        <!--TODO: add if private a tag with a routerLink to homepage-->
        <div class="row tc-spacer"></div>
        <div (click)="sideNavBackgroundColor(0)" [ngStyle]="{'background-color':sideNavBackground.homepage}">
          <a mat-list-item routerLink="./login_shib/tc/homepage" (click)="sidenav.toggle()">
            <img src="./assets/svgLib/homepageNavIcon.svg" alt="" class="appSidenavExpandedImages" />
            <span class="appSidenavExpandedText">Homepage</span>
          </a>
          <div class="row tc-spacer"></div>
        </div>
        <div (click)="sideNavBackgroundColor(1)" [ngStyle]="{'background-color':sideNavBackground.search}">
          <a mat-list-item routerLink="./api/class-search" (click)="sidenav.toggle()">
            <img src="{{searchFileToUse}}" alt="" class="appSidenavExpandedImages" />
            <span class="appSidenavExpandedText">Class Search</span>
          </a>
          <div class="row tc-spacer"></div>
        </div>
        <div (click)="sideNavBackgroundColor(2)" [ngStyle]="{'background-color':sideNavBackground.enroll}">
          <a mat-list-item routerLink="./login_shib/tc/enrollment" (click)="sidenav.toggle()">
            <img src="{{enrollFileToUse}}" alt="" class="appSidenavExpandedImages" />
            <span class="appSidenavExpandedText">Enrollment</span>
          </a>
          <div class="row tc-spacer"></div>
        </div>
        <div (click)="sideNavBackgroundColor(3)" [ngStyle]="{'background-color':sideNavBackground.acad}">
          <a mat-list-item routerLink="./login_shib/tc/academics" (click)="sidenav.toggle()">
            <img src="{{academicsFileToUse}}" alt="" class="appSidenavExpandedImages" />
            <span class="appSidenavExpandedText">Academics</span>
          </a>
          <div class="row tc-spacer"></div>
        </div>
        <div (click)="sideNavBackgroundColor(4)" [ngStyle]="{'background-color':sideNavBackground.hoursLocations}">
          <a mat-list-item routerLink="./api/hours-and-locations" (click)="sidenav.toggle()">
            <img src="{{hoursLocationsFileToUse}}" alt="" class="appSidenavExpandedImages" />
            <span class="appSidenavExpandedText">Hours & Locations</span>
          </a>
          <div class="row tc-spacer"></div>
        </div>
        <div (click)="sideNavBackgroundColor(5)" [ngStyle]="{'background-color':sideNavBackground.diningBalances}">
          <a mat-list-item routerLink="./login_shib/tc/dining-balances" (click)="sidenav.toggle()">
            <img src="./assets/svgLib/balance_white.svg" alt="" class="appSidenavExpandedImages" />
            <span class="appSidenavExpandedText">Dining Balances</span>
          </a>
          <div class="row tc-spacer"></div>
        </div>
        <div (click)="sideNavBackgroundColor(6)" [ngStyle]="{'background-color':sideNavBackground.calculator}">
          <a mat-list-item routerLink="./api/gpa-calc" (click)="sidenav.toggle()">
            <img src="./assets/svgLib/calculator_white.svg" alt="" class="appSidenavExpandedImages" />
            <span class="appSidenavExpandedText">GPA Calculator</span>
          </a>
          <div class="row tc-spacer"></div>
        </div>
        <div (click)="sideNavBackgroundColor(7)" [ngStyle]="{'background-color':sideNavBackground.classCompare}">
          <a mat-list-item routerLink="./api/class-compare" (click)="sidenav.toggle()">
            <img src="./assets/svgLib/compare_white.svg" alt="" class="appSidenavExpandedImages" />
            <span class="appSidenavExpandedText">Class Compare</span>
          </a>
          <div class="row tc-spacer"></div>
        </div>
        <div (click)="sideNavBackgroundColor(8)" [ngStyle]="{'background-color':sideNavBackground.resources}">
          <a mat-list-item routerLink="./api/resources" (click)="sidenav.toggle()">
            <img src="./assets/svgLib/resources_white.svg" alt="" class="appSidenavExpandedImages" />
            <span class="appSidenavExpandedText">Resources</span>
          </a>
          <div class="row tc-spacer"></div>
        </div>
      </mat-nav-list>
    </mat-sidenav>
    <div class="tc-side-nav" [hidden]="hideNav">
      <div>
        <div class="row visible-lg tc-spacer"></div>
        <button class="row visible-lg" mat-icon-button (click)="sidenav.toggle()" style="margin: auto">
          <img [src]="menuIconOpen" alt="" class="appSidenavCheeseburger" />
        </button>
        <div class="row visible-lg tc-spacer"></div>
        <div [hidden]="sidenav.opened">
          <div (click)="sideNavBackgroundColor(0)" [ngStyle]="{'background-color':sideNavBackground.homepage}">
            <button
              class="row appSidenavButtons visible-lg"
              routerLink="./login_shib/tc/homepage"
              matTooltip="Homepage"
              [matTooltipPosition]="'right'"
              mat-icon-button
            >
              <img src="./assets/svgLib/homepageNavIcon.svg" alt="" class="appSidenavImages" />
            </button>
          </div>
          <div (click)="sideNavBackgroundColor(1)" [ngStyle]="{'background-color':sideNavBackground.search}">
            <button
              class="row appSidenavButtons visible-lg"
              routerLink="./api/class-search"
              matTooltip="Class Search"
              [matTooltipPosition]="'right'"
              mat-icon-button
            >
              <img src="{{searchFileToUse}}" alt="" class="appSidenavImages" />
            </button>
          </div>
          <div (click)="sideNavBackgroundColor(2)" [ngStyle]="{'background-color':sideNavBackground.enroll}">
            <button
              class="row appSidenavButtons visible-lg"
              routerLink="./login_shib/tc/enrollment"
              matTooltip="Enrollment"
              [matTooltipPosition]="'right'"
              mat-icon-button
              style="color: white"
            >
              <img src="{{enrollFileToUse}}" alt="" class="appSidenavImages" />
            </button>
          </div>
          <div (click)="sideNavBackgroundColor(3)" [ngStyle]="{'background-color':sideNavBackground.acad}">
            <button
              class="row appSidenavButtons visible-lg"
              routerLink="./login_shib/tc/academics"
              matTooltip="Academics"
              [matTooltipPosition]="'right'"
              mat-icon-button
              style="color: white"
            >
              <img src="{{academicsFileToUse}}" alt="" class="appSidenavImages" />
            </button>
          </div>
          <div (click)="sideNavBackgroundColor(4)" [ngStyle]="{'background-color':sideNavBackground.hoursLocations}">
            <button
              class="row appSidenavButtons visible-lg"
              routerLink="./api/hours-and-locations"
              matTooltip="Hours & Locations"
              [matTooltipPosition]="'right'"
              mat-icon-button
              style="color: white"
            >
              <img src="{{hoursLocationsFileToUse}}" alt="" class="appSidenavImages" />
            </button>
          </div>
          <div (click)="sideNavBackgroundColor(5)" [ngStyle]="{'background-color':sideNavBackground.diningBalances}">
            <button
              class="row appSidenavButtons visible-lg"
              routerLink="./login_shib/tc/dining-balances"
              matTooltip="Dining"
              [matTooltipPosition]="'right'"
              mat-icon-button
              style="color: white"
            >
              <img src="./assets/svgLib/balance_white.svg" alt="" class="appSidenavImages" />
            </button>
          </div>
          <div (click)="sideNavBackgroundColor(6)" [ngStyle]="{'background-color':sideNavBackground.calculator}">
            <button
              class="row appSidenavButtons visible-lg"
              routerLink="./api/gpa-calc"
              matTooltip="GPA Calculator"
              [matTooltipPosition]="'right'"
              mat-icon-button
              style="color: white"
            >
              <img src="./assets/svgLib/calculator_white.svg" alt="" class="appSidenavImages" />
            </button>
          </div>
          <div (click)="sideNavBackgroundColor(7)" [ngStyle]="{'background-color':sideNavBackground.classCompare}">
            <button
              class="row appSidenavButtons visible-lg"
              routerLink="./api/class-compare"
              matTooltip="Class Compare"
              [matTooltipPosition]="'right'"
              mat-icon-button
              style="color: white"
            >
              <img src="./assets/svgLib/compare_white.svg" alt="" class="appSidenavImages" />
            </button>
          </div>
          <div (click)="sideNavBackgroundColor(8)" [ngStyle]="{'background-color':sideNavBackground.resources}">
            <button
              class="row appSidenavButtons visible-lg"
              routerLink="./api/resources"
              matTooltip="Resources"
              [matTooltipPosition]="'right'"
              mat-icon-button
              style="color: white"
            >
              <img src="./assets/svgLib/resources_white.svg" alt="" class="appSidenavImages" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="widthFix">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-container>

  <!--Footer inside-->
  <footers></footers>
</div>
