<div id="footer" class="row footer" xmlns="http://www.w3.org/1999/html">
  <div class="row" style="margin-top: 15px">
    <div class="col-sm-1 col-md-2"></div>
    <div class="footerLinkAdjust col-xs-5 col-sm-6 col-md-5">
      <span class="col-sm-12 hidden-xs" style="font-size: 18px; cursor: default">About</span>
      <span class="col-sm-12 hidden-xs" style="font-size: 16px; cursor: default"
        >Founded in 2013, Tiger Center is a gateway to various university tools essential to your experience at
        Rochester Institute of Technology</span
      >
      <div class="footerIndent hidden-xs">
        <a (click)="navigateToReleaseNotes()" class="basic-link footerLinkMargin" style="cursor: pointer"
          >Release Notes</a
        >
        <a class="basic-link footerLinkMargin" href="{{links.feedbackHelp}}" target="_blank">Feedback</a>
        <a class="basic-link footerLinkMargin" href="{{links.supportHelp}}" target="_blank">Support</a>
        <a class="basic-link footerLinkMargin" href="{{links.directory}}" target="_blank">Directory</a>
        <a class="basic-link footerLinkMargin" href="{{links.ritHome}}" target="_blank">RIT Home</a>
      </div>
      <div class="footerIndent visible-xs">
        <a (click)="navigateToReleaseNotes()" class="basic-link col-xs-12" style="cursor: pointer">Release Notes</a>
        <a class="basic-link col-xs-12" href="{{links.feedbackHelp}}" target="_blank">Feedback</a>
        <a class="basic-link col-xs-12" href="{{links.supportHelp}}" target="_blank">Support</a>
        <a class="basic-link col-xs-12" href="{{links.directory}}" target="_blank">Directions</a>
        <a class="basic-link col-xs-12" href="{{links.ritHome}}" target="_blank">RIT Home</a>
      </div>
    </div>
    <div class="col-xs-7 col-sm-5 col-md-5 footerSITOImageCenter">
      <img src="./assets/svgLib/SITO.png" alt="" class="footerSITOImage" />
      <a href="{{links.facebook}}" target="_blank"
        ><img src="./assets/svgLib/facebook.svg" alt="" class="footerImages hidden-xs"
      /></a>
      <a href="{{links.twitter}}" target="_blank"
        ><img src="./assets/svgLib/twitter.svg" alt="" class="footerImages hidden-xs"
      /></a>
      <a href="{{links.instagram}}" target="_blank"
        ><img src="./assets/svgLib/instagram.png" alt="" class="footerImages hidden-xs"
      /></a>
    </div>
  </div>
  <div class="row visible-xs" style="margin-top: 15px">
    <a href="{{links.facebook}}" class="col-xs-12 footerLinksCenter" target="_blank"
      ><img src="./assets/svgLib/facebook.svg" alt="" class="footerImages" /><span
        style="vertical-align: sub; margin-left: 15px"
        >facebook.com/TigerCenterRIT</span
      ></a
    >
    <a href="{{links.twitter}}" class="col-xs-12 footerLinksCenter" target="_blank"
      ><img src="./assets/svgLib/twitter.svg" alt="" class="footerImages" /><span
        style="vertical-align: sub; margin-left: 15px"
        >twitter.com/tigercenterrit</span
      ></a
    >
    <a href="{{links.instagram}}" class="col-xs-12 footerLinksCenter" target="_blank"
      ><img src="./assets/svgLib/instagram.png" alt="" class="footerImages" /><span
        style="vertical-align: sub; margin-left: 15px"
        >instagram.com/tigercenter_rit/</span
      ></a
    >
  </div>
  <br />
  <div class="row" style="text-align: center">
    <div class="footerCopyright">
      <span style="cursor: default">&copy;Rochester Institute of Technology. All Rights Reserved.</span>
      <div class="footerLinks footerLinkCheck">
        <a class="basic-link" style="margin-left: 5px" href="{{links.copyright}}" target="_blank"
          >Copyright Infringement</a
        >
        <span>|</span>
        <a class="basic-link" href="{{links.privacy}}" target="_blank">Privacy Statement</a>
        <span>|</span>
        <a class="basic-link" href="{{links.disclaimer}}" target="_blank">Disclaimer</a>
        <span>|</span>
        <a class="basic-link" href="{{links.nondiscrimination}}" target="_blank">Nondiscrimination</a>
      </div>
    </div>
  </div>
</div>
