// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// DEV ENDPOINTS
export const environment = {
  production: false,
  ENV_MESSAGE: 'I am the Development Build',
  TC: '/tigerCenterApp/tc/',
  TCAuth: '/tigerCenterApp/login_shib/tc/',
  DeepLink: 'https://mycampus.pstest.rit.edu/psc/TRIT4J/EMPLOYEE/SA/c/NUI_FRAMEWORK.PT_LANDINGPAGE.GBL?',
  TCAuthLoginBase: '/tigerCenterApp/login_shib/tc/',
};
