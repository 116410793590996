import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LinksResponse } from 'app/ModelInterfaces/LinksResponse';
import { AppService } from '../../app.service';
import { GoogleAnalyticsEventsService } from '../../GoogleAnalytics/google-analytics-events-service';

@Component({
  selector: 'footers',
  templateUrl: './footer.tab.template.html',
  styleUrls: ['../../app.style.css'],
})
export class FooterComponent implements OnInit {
  constructor(private gaService: GoogleAnalyticsEventsService, private router: Router, public appService: AppService) {}

  links: LinksResponse;

  navigateToReleaseNotes() {
    this.router.navigateByUrl('/api/release-notes');
  }

  ngOnInit(): void {
    this.appService.getLinks().subscribe((links) => (this.links = links));
  }
}
