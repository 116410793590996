/**
 * Created by kjsisd1 on 11/15/17.
 */
import { Injectable } from '@angular/core';
import axios from 'axios';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../../environments/environment';
import { AppService } from '../../../app.service';
import { HTTPInterceptorService } from '../../../HTTPInterceptor/HTTPInterceptor.service';

const preReqsServiceAPI = axios.create({
  baseURL: environment.TC,
  params: {
    courseId: null,
  },
});

@Injectable()
/**
 * @class This service makes a call to the prerequisites endpoint, gets the data requested
 * and stores it in **preReqsResults**
 */
export class CourseCatalogService {
  public preReqsResults: any;

  constructor(private appService: AppService, httpInterceptor: HTTPInterceptorService) {
    preReqsServiceAPI.interceptors.response.use(
      (response) => httpInterceptor.responseHandler(response),
      (error) => {
        httpInterceptor.errorHandler(error);
      }
    );
  }

  /**
   * This function kicks off prerequisites by getting the response from the endpoint and modifying
   * **preReqsResults**
   * This is called each time a course is expanded in course catalog.
   * @param {string} courseId The id of the course requesting pre-reqs.
   * @returns {Observable<any>}
   */
  getPreReqsData(courseId: string): Observable<any> {
    return new Observable((observer) => {
      preReqsServiceAPI
        .get(`getPrereq?courseId=${courseId}`)
        .then((response) => {
          this.preReqsResults = response.data.description;
          observer.complete();
        })
        .catch((err) => {
          switch (err.response.status) {
            // Service Unavailable
            case 503:
              this.appService.sisDown = true;
              break;
          }
          console.error(err);
          observer.error(err);
        });
    });
  }
}
