/**
 * Created by kjsisd1 on 9/14/17.
 */

import * as clone from 'clone'; // deep clones down to any depth necessary of anything. In this case, it's used to deep clone the meetings array
import * as sortBy from 'sort-by'; // sorts anything by a specific field. In this case, it's used to sort the meetings array multiple times

export class ClassSearchObj {
  // used as a display name
  combinedName = null;

  // used for opening and coloring the class divs
  hideDiv = true;
  backgroundColor = 'white';

  // used for live filtering
  sectionOfDay = null;

  // used for barnes and noble link
  bookNum = null;
  bookTerm = null;
  currTerm = null;

  // used for making it easier on the logic in the view for displaying these items under the meeting array.
  meetingsCopy = [];
  meetingsSortDays = [];
  meetingsSortDayTimes = [];
  meetingsSortTime = [];
  meetingsSortInstructor = [];
  meetingsSortDate = [];
  meetingsSortLocation = [];
  associatedMeetingsCopy = [];

  // used for selecting associated classes
  associatedClassIndex = -1;

  // fetched from the response or derived from the response
  subject = null;
  enrollmentStatus = null;
  statusText = null;
  statusImage = null;
  classType = null;
  component = null;
  componentShort = null;
  componentLong = null;
  instructionMode = null;
  instructionName = null;
  campus = null;
  startingTerm = null;
  academicGroupShort = null;
  academicCareer = null;
  courseTitleLong = null;
  catalogNumber = null;
  waitCap = null;
  classNumber = null;
  sessionCode = null;
  attributes = null;
  attributeKeys = [];
  attributeValues = [];
  attributeDescriptions = [];
  waitTotal = null;
  enrollmentCap = null;
  ppSearchId = null;
  academicTitle = null;
  location = null;
  enrollmentTotal = null;
  classSection = null;
  courseDescription = null;
  academicGroup = null;
  instructors = null;
  maximumUnits = null;
  minimumUnits = null;
  gradingBasis = null;
  gradingBasisLong = null;
  associatedClassNumber = null;
  autoEnrollSect1 = null;
  autoEnrollSect2 = null;
  courseId = null;
  printTopic = null;
  courseTopicId = null;
  courseTopic = null;
  associatedComponents = null;
  relatedAttributes = null;
  meetings = [];
  preReqDescriptions = [];
  preReqDescrsShort = [];
  preReqDescrsLong = [];
  notes = null;
  combinedSection = null;
  associatedClasses = [];
  reservedCap = null;
  term = null;

  //TO ADD PROPERTIES DYNAMICALLY
  // [key: string]: any;

  constructor(data, currTerm) {
    this.subject = data.subject;
    this.enrollmentStatus = data.enrollmentStatus;
    this.statusText = this.getStatusTextColor();
    this.statusImage = data.statusImage;
    this.classType = data.classType;
    this.component = this.getComponentFullName(data.component);
    this.componentShort = this.getComponentShortName();
    this.componentLong = this.getComponentLongName();
    this.instructionMode = data.instructionMode;
    this.instructionName = this.getInstructionName();
    this.instructors = data.instructors;
    this.campus = data.campus;
    this.startingTerm = data.startingTerm;
    this.academicGroupShort = data.academicGroupShort;
    this.academicCareer = this.getAcademicCareerName(data.academicCareer);
    this.courseTitleLong = data.courseTitleLong;
    this.catalogNumber = data.catalogNumber;
    this.waitCap = data.waitCap;
    this.classNumber = data.classNumber;
    this.sessionCode = data.sessionCode;
    this.attributes = data.attributes;
    this.attributeKeys = data.attributeKeys;
    this.attributeValues = data.attributeValues;
    this.attributeDescriptions = data.attributeDescriptions;
    this.waitTotal = data.waitTotal;
    this.enrollmentCap = data.enrollmentCap;
    this.ppSearchId = data.ppSearchId;
    this.academicTitle = data.academicTitle;
    this.location = data.location;
    this.enrollmentTotal = data.enrollmentTotal;
    this.classSection = data.classSection;
    this.courseDescription = data.courseDescription;
    this.academicGroup = data.academicGroup;
    this.maximumUnits = data.maximumUnits;
    this.minimumUnits = data.minimumUnits;
    this.gradingBasis = data.gradingBasis;
    this.gradingBasisLong = this.getGradingBasisLongName();
    this.associatedClassNumber = data.associatedClassNumber;
    this.autoEnrollSect1 = data.autoEnrollSect1;
    this.autoEnrollSect2 = data.autoEnrollSect2;
    this.courseId = data.courseId;
    this.printTopic = data.printTopic;
    this.courseTopicId = data.courseTopicId;
    this.courseTopic = data.courseTopic;
    this.associatedComponents = data.associatedComponents;
    this.relatedAttributes = data.relatedAttributes;

    this.meetings = data.meetings;
    this.meetingsCopy = clone(data.meetings);
    this.meetingsSortDays = this.sortMeetingsByDays();
    this.meetingsSortDayTimes = this.sortMeetingsByDayTimes();
    this.meetingsSortTime = this.sortMeetingsByTime();
    this.meetingsSortInstructor = this.sortMeetingsByInstructor();
    this.meetingsSortDate = this.sortMeetingsByDate();
    this.meetingsSortLocation = this.sortMeetingsByLocation();

    this.preReqDescriptions = data.preReqDescriptions;
    this.preReqDescrsShort = data.preReqDescrsShort;
    this.preReqDescrsLong = data.preReqDescrsLong;
    this.notes = data.notes;
    this.combinedSection = data.combinedSection;
    this.associatedClasses = data.associatedClasses;
    for (let i = 0; i < this.associatedClasses.length; i++) {
      this.associatedClasses[i].meetingsSortDayTimes = this.sortAssociatedMeetingsByDayTimes(i);
      this.associatedClasses[i].meetingsSortLocation = this.sortAssociatedMeetingsByLocation(i);
      this.associatedClasses[i].meetingsSortInstructor = this.sortAssociatedMeetingsByInstructor(i);
    }
    this.reservedCap = data.reservedCap;

    this.currTerm = currTerm;
    this.combinedName = this.combinedDetailsName();
    this.sectionOfDay = this.getSectionOfDay();
    this.bookNum = this.calculateBookNum();
    this.bookTerm = this.calculateBookTerm();
    this.startingTerm = data.term;
  }

  /**
   * Returns the nicely formatted name to be used in the view
   * @returns {string}
   */
  combinedDetailsName(): string {
    return this.subject + ' ' + this.catalogNumber + ' ' + this.classSection + ' ' + this.classNumber;
  }

  /**
   * Returns the short name and the long name of the component together in one string.
   * @param compId
   * @returns {string}
   */
  getComponentFullName(compId: string): string {
    switch (compId) {
      case 'COL':
        return 'COL Colloquium';
      case 'CLN':
        return 'CLN Clinical';
      case 'CON':
        return 'CON Continuance';
      case 'IND':
        return 'IND Independent Study';
      case 'LAB':
        return 'LAB Laboratory';
      case 'LEC':
        return 'LEC Lecture';
      case 'RSC':
        return 'RSC Research';
      case 'SEM':
        return 'SEM Seminar';
      case 'ACT':
        return 'ACT Activity';
      case 'CNV':
        return 'CNV Conversion Course';
      case 'COP':
        return 'COP Cooperative Education';
      case 'CRI':
        return 'CRI Critique';
      case 'EQU':
        return 'EQU FT/PT Equivalent';
      case 'INT':
        return 'INT Internship';
      case 'LEL':
        return 'LEL Lecture/Lab';
      case 'REC':
        return 'REC Recitation';
      case 'STU':
        return 'STU Studio';
      case 'CMP':
        return 'CMP Comprehensive Exam';
      case 'THE':
        return 'THE Thesis';
      case 'PRO':
        return 'PRO Project';
      case 'SAB':
        return 'SAB Study Abroad';
      default:
        return 'N/A None';
    }
  }

  /**
   * Returns the short name of the component.
   * @returns {string}
   */
  getComponentShortName(): string {
    return this.component.substring(0, 3);
  }

  /**
   * Returns the long name of the component.
   * @returns {string}
   */
  getComponentLongName(): string {
    return this.component.substring(4);
  }

  /**
   * Returns the string name associated with the **careerId**.
   * @param careerId
   * @returns {string}
   */
  getAcademicCareerName(careerId: string): string {
    switch (careerId) {
      case 'UGRD':
        return 'Undergraduate';
      case 'GRAD':
        return 'Graduate';
      default:
        return null;
    }
  }

  /**
   * Calculates which section of the day a class begins in (i.e. Morning, Afternoon, or Evening).
   * @returns {string}
   */
  getSectionOfDay(): string {
    if (this.meetings != null) {
      for (let meeting of this.meetings) {
        let startHour = parseInt(meeting.time.substring(0, 2));
        let amPm = meeting.time.substring(6, 8);
        if (startHour <= 11 && amPm === 'PM') {
          startHour += 12;
        }
        // morning = 6AM - 11AM
        if (amPm === 'AM' && startHour >= 6 && startHour <= 11) {
          return 'Morning';
        }
        // afternoon = 12PM - 3PM
        else if (amPm === 'PM' && startHour >= 12 && startHour <= 15) {
          return 'Afternoon';
        }
        // evening = 4PM - 8PM
        else if (amPm === 'PM' && startHour >= 16 && startHour <= 20) {
          return 'Evening';
        }
      }
    }
    return null;
  }

  /**
   * Returns the text color to be used with the status of the class (green, yellow, or red).
   * @returns {string}
   */
  getStatusTextColor(): string {
    switch (this.enrollmentStatus) {
      case 'Open':
        return '#3ac34a';
      case 'Waitlist':
        return '#e8c221';
      case 'Closed':
        return '#dd4a46';
      default:
        return '#434242';
    }
  }

  /**
   * Returns the long name of the **gradingBasis**.
   * @returns {string}
   */
  getGradingBasisLongName(): string {
    switch (this.gradingBasis) {
      case 'ADJ':
        return 'Conversion Adjustment';
      case 'AUD':
        return 'Audit';
      case 'CGE':
        return 'Comprehensive Graduate Examination';
      case 'EQV':
        return 'Equivalent';
      case 'EXM':
        return 'Credit by Exam';
      case 'GRD':
        return 'Standard Letter Grade';
      case 'NON':
        return 'Non-Graded Component';
      case 'S/F':
        return 'Satisfactory/Failing';
      case 'SFA':
        return 'Satisfactory Audit Option';
      case 'SUS':
        return 'Satisfactory/Unsatisfactory';
      case 'THE':
        return 'Thesis';
      case 'TRN':
        return 'Transfer Credit';
      case 'WV':
        return 'Waived';
      default:
        return '';
    }
  }

  /**
   * Returns the instruction name based off the **instructionMode**
   * @returns {string}
   */
  getInstructionName(): string {
    switch (this.instructionMode) {
      case 'P':
        return 'In Person';
      case 'OL':
        return 'Online';
      case 'BL':
        return 'Blended Learning';
      default:
        return '';
    }
  }

  /**
   * This function uses an external npm package called clone and deep-copies the **meetings** object.
   * Then, it uses yet another external npm package called sortBy and sorts the *meetingsCopy** object by days.
   * @returns {Array}
   */
  sortMeetingsByDays() {
    this.meetingsCopy = clone(this.meetings);
    this.meetingsCopy = this.meetingsCopy.sort(sortBy('days'));
    return this.meetingsCopy;
  }

  /**
   * This function uses an external npm package called clone and deep-copies the **meetings** object.
   * Then, it uses yet another external npm package called sortBy and sorts the *meetingsCopy** object by time.
   * @returns {Array}
   */
  sortMeetingsByTime() {
    this.meetingsCopy = clone(this.meetings);
    this.meetingsCopy = this.meetingsCopy.sort(sortBy('time'));
    return this.meetingsCopy;
  }

  /**
   * This function uses an external npm package called clone and deep-copies the **meetings** object.
   * Then, it uses yet another external npm package called sortBy and sorts the *meetingsCopy** object by instructor.
   * @returns {Array}
   */
  sortMeetingsByInstructor() {
    this.meetingsCopy = clone(this.meetings);
    this.meetingsCopy = this.meetingsCopy.sort(sortBy('instructor'));
    return this.meetingsCopy;
  }

  /**
   * This function uses an external npm package called clone and deep-copies a specific class' meetings object in the **associatedClasses** object.
   * Then, it uses yet another external npm package called sortBy and sorts the *associatedMeetingsCopy** object by instructor.
   * @returns {Array}
   */
  sortAssociatedMeetingsByInstructor(index: number) {
    this.associatedMeetingsCopy = clone(this.associatedClasses[index].meetings);
    this.associatedMeetingsCopy = this.associatedMeetingsCopy.sort(sortBy('instructor'));
    return this.associatedMeetingsCopy;
  }

  /**
   * This function uses an external npm package called clone and deep-copies the **meetings** object.
   * Then, it uses yet another external npm package called sortBy and sorts the *meetingsCopy** object by date.
   * @returns {Array}
   */
  sortMeetingsByDate() {
    this.meetingsCopy = clone(this.meetings);
    this.meetingsCopy = this.meetingsCopy.sort(sortBy('date'));
    return this.meetingsCopy;
  }

  /**
   * This function uses an external npm package called clone and deep-copies the **meetings** object.
   * Then, it uses yet another external npm package called sortBy and sorts the *meetingsCopy** object by locationShort.
   * @returns {Array}
   */
  sortMeetingsByLocation() {
    this.meetingsCopy = clone(this.meetings);
    this.meetingsCopy = this.meetingsCopy.sort(sortBy('locationShort'));
    return this.meetingsCopy;
  }

  /**
   * This function uses an external npm package called clone and deep-copies a specific class' meetings object in the **associatedClasses** object.
   * Then, it uses yet another external npm package called sortBy and sorts the *associatedMeetingsCopy** object by locationShort.
   * @returns {Array}
   */
  sortAssociatedMeetingsByLocation(index: number) {
    this.associatedMeetingsCopy = clone(this.associatedClasses[index].meetings);
    this.associatedMeetingsCopy = this.associatedMeetingsCopy.sort(sortBy('locationShort'));
    return this.associatedMeetingsCopy;
  }

  /**
   * This function uses an external npm package called clone and deep-copies the **meetings** object.
   * Then, it uses yet another external npm package called sortBy and sorts the *meetingsCopy** object by daytimes.
   * @returns {Array}
   */
  sortMeetingsByDayTimes() {
    this.meetingsCopy = clone(this.meetings);
    this.meetingsCopy = this.meetingsCopy.sort(sortBy('daytimes'));
    return this.meetingsCopy;
  }

  /**
   * This function uses an external npm package called clone and deep-copies a specific class' meetings object in the **associatedClasses** object.
   * Then, it uses yet another external npm package called sortBy and sorts the *associatedMeetingsCopy** object by daytimes.
   * @returns {Array}
   */
  sortAssociatedMeetingsByDayTimes(index: number) {
    this.associatedMeetingsCopy = clone(this.associatedClasses[index].meetings);
    this.associatedMeetingsCopy = this.associatedMeetingsCopy.sort(sortBy('daytimes'));
    return this.associatedMeetingsCopy;
  }

  /**
   * The function returns the book number to be used in building the Barnes & Noble XML Form.
   * @returns {string|LoDashExplicitWrapper<string>|any|void}
   */
  calculateBookNum() {
    return (this.subject + this.catalogNumber).replace(/\s+/g, '');
  }

  /**
   * The function returns the book term to be used in building the Barnes & Noble XML Form.
   * @returns {string|LoDashExplicitWrapper<string>|any|void}
   */
  calculateBookTerm() {
    let year = parseInt(this.currTerm.charAt(1) + this.currTerm.charAt(2));

    let semester = this.currTerm.charAt(3);

    if (semester == '1') {
      this.bookTerm = 'F';
    } else if (semester == '2') {
      this.bookTerm = 'T';
      year = year + 1;
    } else if (semester == '3') {
      this.bookTerm = 'T';
      year = year + 1;
    } else if (semester == '5') {
      this.bookTerm = 'W';
      year = year + 1;
    } else if (semester == '8') {
      this.bookTerm = 'A';
      year = year + 1;
    }
    return this.bookTerm + year;
  }

  /**
   * This function updates the term and updates the generated **bookTerm** for the Barnes & Noble XML Form.
   * @param updatedTerm
   */
  updateTermSelected(updatedTerm: string): void {
    this.currTerm = updatedTerm;
    this.bookTerm = this.calculateBookTerm();
  }
}
