import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AxiosResponse } from 'axios';

@Injectable()
/**
 * @class Intercepts Axios requests and searches for specific HTTP errors.
 */
export class HTTPInterceptorService {
  constructor(private router: Router) {}

  public responseHandler(response: AxiosResponse<any>): AxiosResponse<any> {
    return response;
  }

  public errorHandler(error: any) {
    if (error && error.response && error.response.status == 429) {
      this.router.navigate(['slowdown']);
    } else {
      return error;
    }
  }
}
