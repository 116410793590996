import { Component, OnInit } from '@angular/core';
import { LinksResponse } from 'app/ModelInterfaces/LinksResponse';
import { AppService } from '../app.service';
import { GoogleAnalyticsEventsService } from '../GoogleAnalytics/google-analytics-events-service';

@Component({
  selector: 'landing-page',
  templateUrl: './landing-page.template.html',
  styleUrls: ['./landing-page.style.css'],
})
export class LandingPageComponent implements OnInit {
  constructor(public appService: AppService, private gaService: GoogleAnalyticsEventsService) {}

  starWarsEasterEgg: boolean = false;
  links: LinksResponse;

  enableStarWars() {
    this.starWarsEasterEgg = true;
  }

  ngOnInit(): void {
    this.appService.getLinks().subscribe((links) => (this.links = links));
  }
}
