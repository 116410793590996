/**
 * Created by jtkisd on 11/2/17.
 */
export class AcademicStandingObject {
  academicStand = null;

  constructor(data) {
    this.academicStand = data;
  }

  static update(newValue, object) {
    object.academicStand = newValue.academicStanding;

    return object;
  }
}
