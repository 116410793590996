<audio autoplay>
  <source src="./assets/audio/Star_Wars_original_opening_crawl_1977.ogg" type="audio/ogg" />
  <source src="./assets/audio/Star_Wars_original_opening_crawl_1977.mp3" type="audio/mpeg" />
</audio>

<body class="star-wars" id="background">
  <div *ngFor="let star of stars" class="star" [style.top.px]="star[0]" [style.left.px]="star[1]"></div>

  <section class="intro">
    A long time ago in an office far,<br />
    far away....
  </section>

  <section class="logo">
    <!-- SVG GOES HERE -->
    <img src="./assets/svgLib/SITO_white.svg" />
  </section>

  <!-- Change the text to your liking -->
  <div id="board">
    <div id="content">
      <p id="title">Episode II</p>
      <p id="subtitle">Revenge of the SIS</p>
      <br /><br /><br />
      <!-- Add as many paragraphs as you want -->
      <!-- And make it cheesy ! -->

      <p>Wait for it...<br /></p>

      <p>Here it comes....<br /></p>

      <p>Nah I'm just messin' with ya.....<br /></p>

      <p>Just kidding.....?<br /><br /><br /></p>

      <p>
        A long time ago in an office far,<br />
        far away....
      </p>

      <p id="starWarsHook">
        It is a period of great innovation. RIT students cry out for a new and improved Tiger Center. With little sign
        of their prayers being answered, most students had given up hope . . . . . . . until now!
      </p>

      <p id="starWarsIntroduction">
        Meet the SITO Teams that didn't let their dreams be memes...
        <br /><br /><br />
      </p>

      <p><b>Spring 2017:</b></p>
      <p>Developers: <span>Kyle Skonieczny, Scott Ketelaar, Abhishek Kannekanti</span></p>
      <p>Designer: <span>Nicole Thurgood</span></p>
      <p>Business Analyst: <span>Michael Alex Bowen</span></p>
      <p>Marketer: <span>Cassie</span><br /><br /><br /></p>

      <p><b>Summer 2017:</b></p>
      <p>Developers: <span>Kyle Scagnelli, Scott Baron, David Egan</span></p>
      <p>Designer: <span>Jillian Duma</span></p>
      <p>Business Analyst: <span>Alana Bichutsky</span><br /><br /><br /></p>

      <p><b>Fall 2017:</b></p>
      <p>
        Developers:
        <span> Kyle Scagnelli, Ronak Shettigar, Jeff Kotowicz, Peace Omiponle </span>
      </p>
      <p>Designer: <span>Felix Brink</span></p>
      <p>Business Analyst: <span>Tim Gale</span><br /><br /><br /></p>

      <p><b>Spring 2018:</b></p>
      <p>
        Developers:
        <span> Paula Register, Fernando Flores, Johnny Cuevas, Alex Wilczek </span>
      </p>
      <p>Designer: <span>Alejandro Garzon</span></p>
      <p>Business Analyst: <span>Lucas Mark</span><br /><br /><br /></p>

      <p><b>Summer 2018:</b></p>
      <p>
        Developers:
        <span> Paula Register, Fernando Flores, Jeff Kotowicz, Jeff Latoy </span>
      </p>
      <p>Designer: <span>Alejandro Garzon</span></p>
      <p>Business Analyst: <span>Bryan Gascon</span><br /><br /><br /></p>

      <p><b>Fall 2018:</b></p>
      <p>
        Developers:
        <span> Josh Haber, James De Ricco, Jeff Kotowicz (Part Time), Jeff Latoy </span>
      </p>
      <p>Designer: <span>Ian Mackenzie</span></p>
      <p>Business Analyst: <span>Anjalu Linggi</span><br /><br /><br /></p>

      <p><b>Spring 2019:</b></p>
      <p>
        Developers:
        <span> Olujimi Comedy, Tiffany Ellis, Toni Tran, James De Ricco (Part Time) </span>
      </p>
      <p>Designer: <span>Carolyn Opre</span></p>
      <p>Business Analyst: <span>Emmanuel Betancourth</span><br /><br /><br /></p>

      <p><b>Summer 2019:</b></p>
      <p>
        Developers:
        <span> Olujimi Comedy, Robert Nill, Lukas Yelle, James De Ricco (Part Time) </span>
      </p>
      <p>Designer: <span>Lauren Suter</span></p>
      <p>Business Analyst: <span>Olivia Wang</span><br /><br /><br /></p>

      <p><b>Fall 2019:</b></p>
      <p>
        Developers:
        <span> Rahul Chakraborty, Saurabh Shukla, Kyle Fasanella </span>
      </p>
      <p>Designer: <span>Xander Kaylan</span></p>
      <p>Business Analyst: <span>Antara Sharma</span><br /><br /><br /></p>

      <p><b>Spring 2020:</b></p>
      <p>
        Developers:
        <span> Tejaswini Jagtap, Clare Truell, Caitlin Kimbrell </span>
      </p>
      <p>Designer: <span>Celeste Yost</span></p>
      <p>Business Analyst: <span>John Carr</span><br /><br /><br /></p>

      <p id="starWarsClose">
        SITO races home to their office, where they can employ the power of The New Tiger Center Experience. Their goal?
        To save RIT's students by combating the evils of legacy code, thereby restoring freedom to the galaxy....
      </p>

      <p id="beginningJoke">If you read this far...<br /><br /><br /></p>

      <img src="./assets/svgLib/happyTigerNew.svg/" />
    </div>
  </div>
</body>
