import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '../app.service';

/**
 * @class
 * This Angular injectable service makes calls to get dining balances and dining transaction information
 */
@Injectable()
export class DiningBalancesService {
  constructor(private appService: AppService, private http: HttpClient) {}
}
