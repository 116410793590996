/**
 * Created by jtkisd on 10/30/17.
 */

export class TermStatisticsObject {
  academicCredits = null;

  constructor(data) {
    this.academicCredits = data;
  }

  static update(newValue, object) {
    object.academicCredits = newValue.gradeStatisticsAll[0];

    return object;
  }
}
