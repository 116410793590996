import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import axios from 'axios';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { AppService } from '../app.service';
import { HTTPInterceptorService } from '../HTTPInterceptor/HTTPInterceptor.service';
import { CompatibilityRes } from './class-search-row/Models/CompatibilityRes';
import { AllClassSearchObj } from './Models/AllClassSearchObj';

const classSearchServiceAPI = axios.create({
  baseURL: environment.TC,
  headers: { 'Content-Type': 'application/json;charset=UTF-8' },
});

const autoCompleteServiceAPI = axios.create({
  baseURL: environment.TC,
  params: {
    query: null,
    term: null,
  },
});

const advancedClassSearchServiceAPI = axios.create({
  baseURL: environment.TC,
});

@Injectable()
/**
 * @class This service makes a call to the class search endpoint, gets the data requested
 * and creates a new AllClassSearchObj
 */
export class ClassSearchService {
  public dynamicDropdownRes: any;

  public autoCompleteRes: string[] = [];
  public classCompareObj = {
    'query': '',
    'term': '',
    'courseList': [],
  };
  cartCompatibility = null;
  scheduleCompatibility = null;

  constructor(private appService: AppService, private http: HttpClient, httpInterceptor: HTTPInterceptorService) {
    classSearchServiceAPI.interceptors.response.use(
      (response) => httpInterceptor.responseHandler(response),
      (error) => {
        httpInterceptor.errorHandler(error);
      }
    );

    autoCompleteServiceAPI.interceptors.response.use(
      (response) => httpInterceptor.responseHandler(response),
      (error) => {
        httpInterceptor.errorHandler(error);
      }
    );

    advancedClassSearchServiceAPI.interceptors.response.use(
      (response) => httpInterceptor.responseHandler(response),
      (error) => {
        httpInterceptor.errorHandler(error);
      }
    );
  }

  /**
   * This function kicks off class search by getting the response from the endpoint and creating a
   * new AllClassSearchObj.
   * @param {string} searchParams The parameters that are being searched for.
   * @param {string} currTerm The current Term i.e. 2171
   * @returns {Observable<any>}
   */
  getClassSearchData(searchParams: Object, currTerm: string): Observable<any> {
    return new Observable((observer) => {
      classSearchServiceAPI
        .post('class-search', { searchParams })
        .then((response) => {
          let resObj = new AllClassSearchObj(response.data.searchResults, currTerm);
          observer.next(resObj);
          observer.complete();
        })
        .catch((err) => {
          switch (err.response.status) {
            // Service Unavailable
            case 503:
              this.appService.sisDown = true;
              break;
          }
          console.error(err);
          observer.error(err);
        });
    });
  }

  /**
   * This function will be called whenever a user types three or more characters into the search box. This assigns
   * **autoCompleteRes** to the first five results of the string array returned by the response.
   * @param query
   * @param term
   * @returns {Observable<any>}
   */
  getAutocompleteData(query: string, term: string): Observable<any> {
    return new Observable((observer) => {
      autoCompleteServiceAPI
        .get(`autoComplete?query=${query}&term=${term}`)
        .then((response) => {
          if (response.data.autoResults.length >= 5) {
            this.autoCompleteRes = response.data.autoResults.slice(0, 5);
          } else {
            this.autoCompleteRes = response.data.autoResults;
          }
          observer.complete();
        })
        .catch((err) => {
          switch (err.response.status) {
            // Service Unavailable
            case 503:
              this.appService.sisDown = true;
              break;
          }
          console.error(err);
          observer.error(err);
        });
    });
  }

  /**
   * This function kicks off the advanced class search by grabbing the sessions, campuses, colleges, subjects, and attributes
   * for all the courses. This allows the checkboxes and drop-downs to be dynamic in nature. It will assign the results to
   * **dynamicDropdownRes**.
   * @returns {Observable<any>}
   */
  getAdvancedClassSearchDropdowns(): Observable<any> {
    return new Observable((observer) => {
      advancedClassSearchServiceAPI
        .get('advancedSearchData')
        .then((response) => {
          this.dynamicDropdownRes = response.data;
          observer.complete();
        })
        .catch((err) => {
          switch (err.response.status) {
            // Service Unavailable
            case 503:
              this.appService.sisDown = true;
              break;
          }
          console.error(err);
          observer.error(err);
        });
    });
  }

  compatibility(ppid: string, term: string) {
    let params: HttpParams = new HttpParams().set('term', term).append('ppid', ppid);
    return this.http.get<CompatibilityRes>(environment.TCAuth + 'compatibility', { params: params });
  }
}
