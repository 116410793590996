/**
 * Created by sebisd on 6/19/17.
 */
export class menuItem {
  eventId: null;
  id: null;
  name: null;
  description: null;
  price: null;
  category: null;

  /**
   * This constructor instantiates the variables.
   * @param data
   */
  constructor(data) {
    this.eventId = data.eventId;
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.price = data.price;
    this.category = data.category;
  }
}
