/**
 * Created by sebisd on 7/10/17.
 */
import { LabObject } from './LabObj';

/**
 * @class This model contains all of the labs given to us through our service call.
 */
export class AllLabsObj {
  allLabs: LabObject[] = [];

  /**
   * This constructor populates all the labs into an allLabs array.
   * @param data Given by the service
   */
  constructor(data) {
    if (data != '') {
      for (let loc of data['labLocationList']) {
        let tempLab = new LabObject(loc);
        tempLab.mapData(data);
        //if(!tempLab.name.includes("Tour") && !tempLab.name.includes("Meeting")){
        this.allLabs.push(tempLab);
        //}
      }
    }
  }
}
