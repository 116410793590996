/**
 * Created by sebisd on 7/10/17.
 */

/**
 * @class
 */
export class LabContact {
  contactId: string;
  name: string;
  email: string;
  phone: string;
  activeFlag: string;
  role: string;

  constructor(data) {
    this.name = data['name'];
    this.contactId = data['contact_id'];
    this.email = data['email'];
    this.phone = data['phone'];
    this.activeFlag = data['active_flag'];
    this.role = data['purpose'];
  }
}
