import { Injectable } from '@angular/core';

/**
 * @class
 * This Angular injectable service, that creates the HTTP Request and handles the responses for both all dining
 * information or a single dining object. We use the 3rd party package: axios to create the HTTP request and execute.
 * This is due to this packages' ability to handle CORS for the development process (With RIT's security).
 */
@Injectable()
export class ClassScheduleService {
  enrolledCourses; // Updated whenever the class schedule is downloaded

  constructor() {}
}
