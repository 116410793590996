import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FooterComponent } from '../HeaderAndFooter/Footer/footer.tab.components';
import { HeaderComponent } from '../HeaderAndFooter/Header/header.tab.components';
import { CheckMaintenanceComponent } from '../Maintenance/check-maintenance.component';
import { PageNotFoundComponent } from '../PageNotFound/page-not-found.component';
import { TooManyReqComponent } from '../TooManyRequests/toomanyreq.component';
import { UserInfoComponent } from '../UserInfo/user.info.component';

/**
 * @Module that holds all the Components that are used throughout the rest of the application
 * @author - @ffits
 */
@NgModule({
  imports: [CommonModule],
  declarations: [
    PageNotFoundComponent,
    HeaderComponent,
    FooterComponent,
    UserInfoComponent,
    CheckMaintenanceComponent,
    TooManyReqComponent,
  ],
  exports: [
    PageNotFoundComponent,
    HeaderComponent,
    FooterComponent,
    CheckMaintenanceComponent,
    UserInfoComponent,
    TooManyReqComponent,
  ],
})
export class EssentialModule {}
