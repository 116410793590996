/**
 * Created by kjsisd1 on 6/26/17.
 */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'keys' })
/**
 * @class This Angular 4 Pipe is in charge of the menu logic done in dining.
 */
export class KeysPipe implements PipeTransform {
  /**
   * Takes in a JSON object and extracts the keys and values so both can be used.
   * @param value
   * @param {string[]} args
   * @returns {any}
   */
  transform(value, args: string[]): any {
    let keys = [];
    for (let key in value) {
      keys.push({ key: key, value: value[key] });
    }
    return keys;
  }
}
