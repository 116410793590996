<div class="container-fluid">
  <div class="slowdown-container">
    <div class="slowdown-message-div">
      <div class="slowdown-title">Slow Down!</div>

      <div class="slowdown-message">
        <div>You've made too any requests at once.</div>
        <div>Please wait a moment and try again.</div>
      </div>

      <button class="slowdown-button mat-raised-button" (click)="toHomepage()" type="reset">Return to Homepage</button>
    </div>

    <div class="slowdown-image-div">
      <img class="slowdown-image" src="./assets/images/surprisedTigerNew.png" />
    </div>
  </div>

  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
</div>
