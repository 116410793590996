/**
 * @class This superclass is extended by all three tabs in hours & locations. It contains common instance variables
 * and common functions to be used throughout all of hours & locations.
 */
export class Occurence {
  name = null;
  startTime = null;
  startTimeRaw = null;
  endTime = null;
  endTimeRaw = null;
  startDate = null;
  endDate = null;

  /*
   * These variables are read only and used only for convenience throughout the app.
   */
  static readonly NO_DATA_STATUS = 0;
  static readonly CLOSED_STATUS = 1;
  static readonly RESERVED_STATUS = 2;
  static readonly CLOSING_SOON_STATUS = 3;
  static readonly OPEN_STATUS = 4;

  /**
   * This constructor instantiates all the variables.
   * @param data
   */
  constructor(data) {
    this.name = data.name;
    this.startTime = data.startTime;
    this.endTime = data.endTime;
    this.startDate = data.startDate;
    this.endDate = data.endDate;

    let startTimeHour = Number(this.startTime.split(':')[0]);
    let startTimeMin = Number(this.startTime.split(':')[1]);
    let endTimeHour = Number(this.endTime.split(':')[0]);
    let endTimeMin = Number(this.endTime.split(':')[1]);

    this.startTimeRaw = startTimeHour * 60 + startTimeMin;
    this.endTimeRaw = endTimeHour * 60 + endTimeMin;
  }

  /**
   * Call this.isOnThisDay and if that call comes back true, then we check if the hours align for the location to be
   * open as well. (e.g. if the this.isOnThisDay call determines the event occurs today, then this.isNow will check
   * if the current time is within the advancedSearch time and end time of that specific day an return true or false depending).
   *
   * Think about it like *do I occur within the advancedSearch and end time-frame.*
   * @param today
   * @param currHour
   * @param currMin
   * @returns {boolean}
   */
  isNow(today, currHour, currMin): boolean {
    let currTimeRaw = currHour * 60 + currMin;

    if (this.isOnThisDay(today)) {
      return this.startTimeRaw <= currTimeRaw && this.endTimeRaw > currTimeRaw;
    } else {
      return false;
    }
  }

  /**
   * If the date object passed in is *within the range* of the start and end times of an occurrence (which can be an
   * event, exception, etc...) then true is returned; otherwise, false.
   *
   * Think about it like *do I occur on this specific day.*
   * @param day A date object.
   * @returns {boolean}
   */
  isOnThisDay(day): boolean {
    let startObj = new Date(this.startDate);
    // normalize date to adjust for timezone
    startObj = new Date(startObj.getTime() - startObj.getTimezoneOffset() * -60000);
    startObj.setHours(0, 0, 0, 0);
    // ensure end object has a higher val than today if they are on the same day
    let endObj = new Date(this.endDate);
    endObj = new Date(endObj.getTime() - endObj.getTimezoneOffset() * -60000);
    endObj.setHours(23, 59, 59, 999);

    return startObj <= day && endObj >= day;
  }

  /**
   * Returns true if a location is closing within a 30 minute time window.
   * @param currHour
   * @param currMin
   * @returns {boolean}
   */
  isClosingSoon(currHour, currMin): boolean {
    let currTimeRaw = currHour * 60 + currMin;

    return this.endTimeRaw > currTimeRaw && this.endTimeRaw - currTimeRaw <= 30;
  }
}
