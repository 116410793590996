import { Component, OnInit } from '@angular/core';
import { AppService } from 'app/app.service';
import { LinksResponse } from 'app/ModelInterfaces/LinksResponse';
import { GoogleAnalyticsEventsService } from '../../GoogleAnalytics/google-analytics-events-service';

@Component({
  selector: 'headers',
  templateUrl: './header.tab.template.html',
  styleUrls: ['../../app.style.css'],
})
export class HeaderComponent implements OnInit {
  constructor(private appService: AppService, private gaService: GoogleAnalyticsEventsService) {}

  ngOnInit(): void {
    this.appService.getLinks().subscribe((links) => (this.links = links));
  }

  links: LinksResponse;

  logoImageToUse = './assets/images/RIT_logo_no_dots.png';
  textImageToUse = './assets/images/rochester_it.png';
}
