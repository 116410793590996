import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
/**
 * @class Intercepts HTTPClient requests and searches for specific errors.
 */
export class RxJsInterceptor {
  constructor(private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((response) => {
        if (response instanceof HttpErrorResponse && response.status == 429) {
          this.router.navigate(['slowdown']);
        }
        return throwError(response);
      })
    );
  }
}
