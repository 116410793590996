/**
 * Created by sebisd on 7/10/17.
 */
import { Injectable } from '@angular/core';
import axios from 'axios';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';
import { AppService } from '../../app.service';
import { HTTPInterceptorService } from '../../HTTPInterceptor/HTTPInterceptor.service';
import { AllLabsObj } from './Models/AllLabsObj';

const labServiceAPI = axios.create({
  baseURL: environment.TC,
  withCredentials: true,
  params: {
    date: null,
  },
});

@Injectable()
/**
 * @class This service makes a call to the lab endpoint, gets the data, and creates a new AllLabsObj
 */
export class LabDataService {
  constructor(private appService: AppService, httpInterceptor: HTTPInterceptorService) {
    labServiceAPI.interceptors.response.use(
      (response) => httpInterceptor.responseHandler(response),
      (error) => {
        httpInterceptor.errorHandler(error);
      }
    );
  }

  getLabData(date: String): Observable<any> {
    return new Observable((observer) => {
      labServiceAPI
        .get('lab-info-all?date=' + date)
        .then((response) => {
          let resObj = new AllLabsObj(response.data);
          observer.next(resObj);
          observer.complete();
        })
        .catch((err) => {
          switch (err.response.status) {
            // Service Unavailable
            case 503:
              this.appService.clicDown = true;
              break;
          }
          console.error(err);
          observer.error(err);
        });
    });
  }
}
