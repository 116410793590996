/**
 * Page where users are redirected if they have too many requests
 *
 * @author Robert Nill
 * @created 07/23/2019
 */

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsEventsService } from '../GoogleAnalytics/google-analytics-events-service';

@Component({
  selector: 'toomanyreq',
  templateUrl: './toomanyreq.template.html',
  styleUrls: ['toomanyreq.style.css'],
})
export class TooManyReqComponent {
  constructor(public gaService: GoogleAnalyticsEventsService, private router: Router) {}

  /**
   * Attempt to the homepage
   */
  toHomepage() {
    this.router.navigate(['']);
  }
}
