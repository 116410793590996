/**
 * Created by kjsisd1 on 9/13/17.
 */
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../../shared.module';
import { AdvancedSearchDialogComponent } from './Advanced Search/advanced-search-dialog.component';
import { ClassSearchDialogsService } from './class-search.dialogs.service';
import { CourseCatalogDialogComponent } from './Course Catalog/course-catalog-dialog.component';
import { CourseCatalogService } from './Course Catalog/course-catalog-dialog.service';

@NgModule({
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    HttpClientModule,
    SharedModule,
  ],
  exports: [AdvancedSearchDialogComponent, CourseCatalogDialogComponent],
  declarations: [AdvancedSearchDialogComponent, CourseCatalogDialogComponent],
  providers: [ClassSearchDialogsService, CourseCatalogService],
})
export class ClassSearchDialogsModule {}
