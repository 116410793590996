import { Injectable } from '@angular/core';
import * as $ from 'jquery';

@Injectable()
export class TextbookService {
  bookstore = '';
  scheduleErrors = [];
  sendForm = false;

  /**
   * Based on the user's schedule, parses each individual class and appends to bookstore, which is a string containing
   * the information that will be sent to the Barnes and Noble website, to display the textbooks for the classes
   * @param schedule
   * @returns
   *    bookstore: a string containing the parsed information for all of the user's classes, to be sent to B&N
   *    sendForm: a boolean, true if the data can be sent to B&N, false if not
   *    scheduleErrors: an array of errors
   */
  getTextbooksSchedule(schedule) {
    this.scheduleErrors = [];

    if (schedule.length > 0) {
      this.bookstore = '';
      for (let i = 0; i < schedule.length; i++) {
        this.bookstore += this.setBook(schedule[i]);
      }
      this.sendForm = true;
    } else {
      this.scheduleErrors.push({
        'description': 'You do not have classes in your schedule. Please enroll in classes, and try again.',
      });
      this.sendForm = false;
    }
    return {
      bookstore: this.bookstore,
      sendForm: this.sendForm,
      scheduleErrors: this.scheduleErrors,
    };
  }

  /**
   * @function convertBookTerm
   * @description Converts the given term into one appropriate for the div to retrieve the textbook
   *
   * @param {String} term The current academic term
   *
   * @return {String} bookTerm - The current semester parsed in such a way that the B&N API can read it
   */
  static convertBookTerm(term) {
    let bookTerm = '';
    let year = parseInt(term.charAt(1) + term.charAt(2));
    let p = term.charAt(3);

    if (p == '1') {
      bookTerm = bookTerm + 'F';
    } else if (p == '2') {
      bookTerm = bookTerm + 'T';
      year = year + 1;
    } else if (p == '3') {
      bookTerm = bookTerm + 'T';
      year = year + 1;
    } else if (p == '5') {
      bookTerm = bookTerm + 'W';
      year = year + 1;
    } else if (p == '8') {
      bookTerm = bookTerm + 'A';
      year = year + 1;
    }

    bookTerm = bookTerm + year;
    return bookTerm;
  }

  /**
   * @function setBook
   * @description Sets the appropriate information for the link for the textbook based on the class given
   *
   * @param {Object} course The class needed to find a textbook for
   *
   * @return {String} T
   */
  setBook = function (course) {
    let book = {
      num: {},
      dept: {},
      term: {},
      sect: {},
    };
    book.num = (course.subject + course.catalogNumber).replace(/\s+/g, '');
    book.dept = course.academicGroupShort;
    book.term = TextbookService.convertBookTerm(course.term);
    book.sect = course.classSection;
    return (
      "<course num='" + book.num + "' dept='" + book.dept + "' sect='" + book.sect + "' term='" + book.term + "' />"
    );
  };

  /**
   * @function gotoTextbooks
   * @description Opens the window for the textbook list
   *
   * @param {Boolean} sendForm Whether or not to actually open up the textbook window
   */
  gotoTextbooks(sendForm, formID) {
    if (sendForm) {
      $(document).ready(() => {
        let form = formID == 0 ? document.getElementById('books1') : document.getElementById('books');
        HTMLFormElement.prototype.submit.call(form);
      });
    }
  }
}
