export class VisitingChef {
  name = null;
  visitingChefLocationNames = null;
  locationId = null;
  timeRange = null;

  /**
   * This constructor instantiates the variables.
   * @param data
   */
  constructor(data) {
    this.timeRange = data.timeRange;
    this.name = data.name;
    this.locationId = data.locationId;
    this.visitingChefLocationNames = data.visitingChefLocationNames;
  }
}
