/**
 * Created by kjsisd1 on 7/25/17.
 */
import { TCModel } from '../../../ModelInterfaces/TCModel';
import { eventObject } from './eventObject';

const WILL_BE_OPEN = 6;
const WILL_BE_CLOSED = 5;

export class StudentAffairsObject implements TCModel {
  id = null;
  name = null;
  summary = null;
  description = null;
  mapsUrl = null;
  building = null;
  room = null;
  mdoId = null;
  mapId = null;
  mrkId = null;
  catId = null;
  day = new Date();
  selectedDate = null;
  hasData = false;
  contacts = [];

  events: eventObject[] = [];
  //openNow = 0;
  hideDiv = true;
  filterItems: string[] = []; // replacement for 'department' field to conform to our interface of TCModel.

  /**
   * This constructor initializes all variables and pushes appropriate events onto the events list.
   * > Note: this.getStatus() is used to assign this.openNow
   * @param data
   * @param selectedDate
   */
  constructor(data, selectedDate) {
    this.id = data.id;
    this.name = data.name;
    this.summary = data.summary;
    this.description = data.description;
    this.mapsUrl = data.mapsUrl;
    this.building = data.building;
    this.room = data.room;
    this.mdoId = data.mdoId;
    this.mapId = data.mapId;
    this.mrkId = data.mrkId;
    this.catId = data.catId;
    this.contacts = data.contacts;
    this.filterItems.push(data.department.toUpperCase());
    this.selectedDate = this.parseDate(selectedDate);

    if (data.hasOwnProperty('events')) {
      this.hasData = data.events ? true : false;

      for (let event in data.events) {
        let newEvent: eventObject = new eventObject(data.events[event]);
        if (newEvent.isOnThisDay(this.selectedDate) && newEvent.hasDayOfWeek(this.selectedDate)) {
          this.events.push(newEvent);
        }
      }
    }
    //this.openNow = this.getStatus();
  }

  /**
   * This function determines if a location will be open in the future (if at any point in time it's open, then it will
   * be open, otherwise it will be closed or no data).
   * @returns {number}
   */
  /**
  willBeOpen():number{
	let allEventsClosed = true;

	if (!this.hasData) { return Occurence.NO_DATA_STATUS; }
	if (this.events.length == 0) { return WILL_BE_CLOSED; }

	let currEvent, currException;
	for (let i = 0; i < this.events.length; i++){
		currEvent = this.events[i];

		if (!currEvent.exception){
			allEventsClosed = false;
			break;
		}

		currException = currEvent.exception;

		if ( currException.status != Occurence.OPEN_STATUS && (currEvent.startTimeRaw < currException.startTimeRaw || currEvent.endTimeRaw > currException.endTimeRaw)
			||
			(currException.status == Occurence.OPEN_STATUS && ( (currException.startTimeRaw >= currEvent.startTimeRaw && currException.startTimeRaw <= currEvent.endTimeRaw) ||
				(currException.startTimeRaw <= currEvent.startTimeRaw && currException.endTimeRaw >= currEvent.endTimeRaw) ) )){
			allEventsClosed = false;
			break;
		}
	}

	return !allEventsClosed ? WILL_BE_OPEN : WILL_BE_CLOSED;
  }
   */

  /**
   * This function determines if the location is open at the location level (if within an event, it gets the events
   * open status and assigns it to the object, this.openNow).
   * @returns {number} Returns status of student affairs location (similar number scheme used throughout project).
   */
  /**
  getStatus():number{
    let status;
    status = Occurence.NO_DATA_STATUS;

    for (let event of this.events){
      status = event.openNow;
      if (status != Occurence.CLOSED_STATUS){
        break;
      }
    }
    // locations may not have events today but still have relevant data
    if (status == Occurence.NO_DATA_STATUS && this.hasData){
      status = Occurence.CLOSED_STATUS;
    }

    return status;
  }
   */

  /**
   * This function transforms a date string into a date object, accounting for time zone.
   * @param {string} date a Date string
   * @returns {Date} a Date object
   */
  private parseDate(date: string): Date {
    let dateObj;
    dateObj = new Date(date);
    dateObj = new Date(dateObj.getTime() - dateObj.getTimezoneOffset() * -60000);
    return dateObj;
  }
}
