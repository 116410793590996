<div class="row">
  <p class="col-xs-12 advancedSearchTitleText">{{ title }}</p>
</div>
<div class="row mobileHints">
  <p class="hidden-lg hidden-md hidden-sm col-xs-12 advancedSearchHintText">
    Press 'Save Options' to save and close window. After closing, click search button to apply advanced search
    selections.
  </p>
</div>
<mat-dialog-content style="overflow-x: hidden">
  <div class="row">
    <div class="col-xs-12 col-sm-6" style="margin-bottom: 10px">
      <span class="advancedSearchHeaderText col-xs-12" style="margin-bottom: 10px">Campus and Term Options</span>
      <!--Session-->
      <div class="col-xs-12 advancedSearchNoPaddingLeftRight">
        <p class="advancedSearchSelectHeaders col-xs-6 col-sm-5">Session:</p>
        <div class="styledSelect">
          <select class="styledSelectWidthFix" [(ngModel)]="sessionValue" (change)="setSession()">
            <option [ngValue]="null" selected>All Sessions</option>
            <option
              *ngFor="let session of advancedSearchDynamicDropdowns.sessions[currentTerm] | keys;
                            trackBy: trackByFn;"
              [ngValue]="session.key"
            >
              {{session.value}}
            </option>
          </select>
        </div>
      </div>
      <!--End of Session-->
      <div class="col-xs-12 advancedSearchVerticalSpacer"></div>
      <!--Campus-->
      <div class="col-xs-12 advancedSearchNoPaddingLeftRight">
        <p class="advancedSearchSelectHeaders col-xs-6 col-sm-5">Campus:</p>
        <div class="styledSelect">
          <select class="styledSelectWidthFix" [(ngModel)]="campusValue" (change)="setCampus()">
            <option [ngValue]="''" selected>Choose a Campus</option>
            <option
              *ngFor="let campus of advancedSearchDynamicDropdowns.campuses | keys;
                            trackBy: trackByFn;"
              [ngValue]="campus.key"
            >
              {{campus.value}}
            </option>
          </select>
        </div>
      </div>
      <!--End of Campus-->
      <span class="advancedSearchHeaderText col-xs-12" style="margin-top: 10px; margin-bottom: 10px"
        >Course Type Options</span
      >
      <!--College-->
      <div class="col-xs-12 advancedSearchNoPaddingLeftRight">
        <p class="advancedSearchSelectHeaders col-xs-6 col-sm-5">College:</p>
        <div class="styledSelect">
          <select class="styledSelectWidthFix" [(ngModel)]="collegeValue" (change)="setCollege(); grabSubjects();">
            <option [ngValue]="''" selected>Choose a College</option>
            <option
              *ngFor="let college of advancedSearchDynamicDropdowns.colleges | keys;
                            trackBy: trackByFn;"
              [ngValue]="college.value.id"
            >
              {{college.value.description}}
            </option>
          </select>
        </div>
      </div>
      <!--End of College-->
      <div class="col-xs-12 advancedSearchVerticalSpacer"></div>
      <!--Subject-->
      <div class="col-xs-12 advancedSearchNoPaddingLeftRight">
        <p class="advancedSearchSelectHeaders col-xs-6 col-sm-5">Subject:</p>
        <div class="styledSelect">
          <select class="styledSelectWidthFix" [(ngModel)]="subjectValue" (change)="setSubject()">
            <option *ngIf="collegeValue === ''" [ngValue]="null" disabled selected>Choose a College First</option>
            <option *ngIf="collegeValue !== ''" [ngValue]="null">Choose a Subject</option>
            <option
              *ngFor="let subject of dynamicDropdownSubjectList | keys; trackBy: trackByFn;"
              [ngValue]="subject.key"
            >
              {{subject.value}}
            </option>
          </select>
        </div>
      </div>
      <!--End of Subject-->
      <div class="col-xs-12 advancedSearchVerticalSpacer"></div>
      <!--Instructor-->
      <div class="col-xs-12 advancedSearchNoPaddingLeftRight">
        <p class="col-xs-6 col-sm-5 advancedSearchSelectHeaders" style="padding-right: 0">Instructor:</p>
        <mat-form-field class="advancedSearchInstructorField" (change)="setInstructor()">
          <input
            matInput
            placeholder="Type an instructor's first or last name"
            class="advancedSearchInstructorInput"
            [(ngModel)]="instructorQuery"
          />
        </mat-form-field>
      </div>
      <!--End of Instructor-->
      <div class="col-xs-12 advancedSearchVerticalSpacer"></div>
      <!--Credits Number-->
      <!--If 'between' is not selected-->
      <div *ngIf="creditsPrecisionValue !== 1" class="col-xs-12 advancedSearchNoPaddingLeftRight">
        <p class="advancedSearchSelectHeaders col-xs-6 col-sm-5">Credits:</p>
        <div class="styledSelect">
          <select class="styledSelectWidthFix" [(ngModel)]="creditsMinValue" (change)="setCredits()">
            <option [ngValue]="0" selected>0</option>
            <option [ngValue]="1">1</option>
            <option [ngValue]="2">2</option>
            <option [ngValue]="3">3</option>
            <option [ngValue]="4">4</option>
            <option [ngValue]="5">5</option>
            <option [ngValue]="6">6</option>
            <option [ngValue]="7">7</option>
            <option [ngValue]="8">8</option>
          </select>
        </div>
      </div>
      <!--If 'between' is selected-->
      <div *ngIf="creditsPrecisionValue === 1" class="col-xs-12 advancedSearchNoPaddingLeftRight">
        <p class="advancedSearchSelectHeaders col-xs-6 col-sm-5">Credits:</p>
        <div class="styledSelectHalfLeft">
          <select class="styledSelectWidthFix" [(ngModel)]="creditsMinValue" (change)="setCredits()">
            <option [ngValue]="0" selected>0</option>
            <option [ngValue]="1">1</option>
            <option [ngValue]="2">2</option>
            <option [ngValue]="3">3</option>
            <option [ngValue]="4">4</option>
            <option [ngValue]="5">5</option>
            <option [ngValue]="6">6</option>
            <option [ngValue]="7">7</option>
            <option [ngValue]="8">8</option>
          </select>
        </div>
        <div class="styledSelectHalfRight">
          <select class="styledSelectWidthFix" [(ngModel)]="creditsMaxValue" (change)="setCredits()">
            <option [ngValue]="-1" disabled selected>Number</option>
            <option *ngIf="0 > creditsMinValue" [ngValue]="0">0</option>
            <option *ngIf="1 > creditsMinValue" [ngValue]="1">1</option>
            <option *ngIf="2 > creditsMinValue" [ngValue]="2">2</option>
            <option *ngIf="3 > creditsMinValue" [ngValue]="3">3</option>
            <option *ngIf="4 > creditsMinValue" [ngValue]="4">4</option>
            <option *ngIf="5 > creditsMinValue" [ngValue]="5">5</option>
            <option *ngIf="6 > creditsMinValue" [ngValue]="6">6</option>
            <option *ngIf="7 > creditsMinValue" [ngValue]="7">7</option>
            <option *ngIf="8 > creditsMinValue" [ngValue]="8">8</option>
          </select>
        </div>
      </div>
      <!--End of Credits Number-->
      <div class="col-xs-12 advancedSearchVerticalSpacer"></div>
      <!--Credits Precision-->
      <div class="col-xs-12 advancedSearchNoPaddingLeftRight">
        <p class="col-xs-6 col-sm-5"></p>
        <div class="styledSelect">
          <select
            class="styledSelectWidthFix"
            [(ngModel)]="creditsPrecisionValue"
            (change)="resetCreditsMaxVal(); setCredits() "
          >
            <option [ngValue]="0">Exactly</option>
            <option [ngValue]="1" selected>Between</option>
            <option [ngValue]="2">Less Than</option>
            <option [ngValue]="3">Greater Than</option>
          </select>
        </div>
      </div>
      <!--End of Credits Precision-->
      <div class="col-xs-12 advancedSearchVerticalSpacer"></div>
      <!--Career-->
      <div class="col-xs-12 advancedSearchNoPaddingLeftRight">
        <p class="advancedSearchSelectHeaders col-xs-6 col-sm-5">Career:</p>
        <div class="styledSelect">
          <select class="styledSelectWidthFix" [(ngModel)]="careerValue" (change)="setCareer()">
            <option [ngValue]="''">Both</option>
            <option [ngValue]="'UGRD'">Undergraduate</option>
            <option [ngValue]="'GRAD'">Graduate</option>
          </select>
        </div>
      </div>
      <!--End of Career-->
      <div class="col-xs-12 advancedSearchVerticalSpacer"></div>
      <!--Component-->
      <div class="col-xs-12 advancedSearchNoPaddingLeftRight">
        <p class="advancedSearchSelectHeaders col-xs-6 col-sm-5">Type:</p>
        <div class="styledSelect">
          <select class="styledSelectWidthFix" [(ngModel)]="componentValue" (change)="setComponent()">
            <option [ngValue]="''" selected>Choose a Type</option>
            <option
              *ngFor="let component of advancedSearchDynamicDropdowns.components | keys;
                            trackBy: trackByFn;"
              [ngValue]="component.key"
            >
              {{component.value}}
            </option>
          </select>
        </div>
      </div>
      <!--End of Component-->
      <div class="col-xs-12 advancedSearchVerticalSpacer"></div>
      <!--Instruction Type-->
      <div class="col-xs-12 advancedSearchNoPaddingLeftRight">
        <p class="advancedSearchSelectHeaders col-xs-6 col-sm-5">Instruction Type:</p>
        <div class="styledSelect">
          <select class="styledSelectWidthFix" [(ngModel)]="instructionValue" (change)="setInstruction()">
            <option [ngValue]="''" selected>Choose an Instruction Type</option>
            <option
              *ngFor="let instructionMode of advancedSearchDynamicDropdowns.instructionModes | keys;
                            trackBy: trackByFn;"
              [ngValue]="instructionMode.key"
            >
              {{instructionMode.value}}
            </option>
          </select>
        </div>
      </div>
      <!--End of Instruction Type-->
    </div>
    <div class="col-xs-12 col-sm-6">
      <span class="advancedSearchHeaderText col-xs-12">Course Attribute Options</span>
      <!--Slide Toggle-->
      <div class="col-xs-12" style="margin-top: 10px; margin-bottom: 10px" id="attributeSlider">
        <div class="onoffswitch">
          <input
            [(ngModel)]="filterAnd"
            (click)="setAndOrLogic()"
            type="checkbox"
            name="advancedsearchonoffswitch"
            class="onoffswitch-checkbox"
            id="advancedsearchonoffswitch"
          />
          <label class="onoffswitch-label" for="advancedsearchonoffswitch">
            <span style="display: block" class="onoffswitch-inner"></span>
            <span style="display: block" class="onoffswitch-switch"></span>
          </label>
        </div>
      </div>
      <!--End of Slide Toggle-->
      <!--Checkboxes-->
      <div *ngFor="let checkbox of attributesNameList; index as i">
        <mat-checkbox
          *ngIf="attributesDefHiddenList[i] === false"
          id="{{i}}"
          class="col-xs-12"
          style="padding-right: 0"
          [(ngModel)]="advancedSearchFilterOptionsChecked[i]"
          [checked]="advancedSearchFilterOptions.courseAttributeOptions.indexOf(attributesValueList[i]) !== -1"
          (change)="changeAttributeArray(attributesValueList[i], $event.checked, i);"
        >
          <p class="col-xs-12 advancedSearchCheckboxText">{{attributesNameList[i]}}</p>
        </mat-checkbox>
        <mat-checkbox
          *ngIf="attributesDefHiddenList[i] === true"
          id="{{i}}"
          class="col-xs-12 advancedSearchHiddenCheckboxes"
          style="padding-right: 0"
          [(ngModel)]="advancedSearchFilterOptionsChecked[i]"
          [hidden]="calculateHidden(i)"
          [checked]="advancedSearchFilterOptions.courseAttributeOptions.indexOf(attributesValueList[i]) !== -1"
          (change)="changeAttributeArray(attributesValueList[i], $event.checked, i);"
        >
          <p class="col-xs-12 advancedSearchCheckboxText">{{attributesNameList[i]}}</p>
        </mat-checkbox>
      </div>
      <!--End of Checkboxes-->
    </div>
  </div>
</mat-dialog-content>
<br />
<mat-dialog-actions class="advancedSearchMobileMarginFix">
  <!--Alert user that at least one child checkbox must be selected!-->
  <div *ngIf="checkMandatoryCheckboxes()" class="advancedSearchAlertBox col-xs-12">
    <span>Please select at least one indented checkbox under the following: <strong>{{userTodo}}</strong></span>
  </div>
  <div class="hidden-xs col-sm-2 col-md-2"></div>
  <button
    mat-raised-button
    class="advancedSearchResetButton col-xs-5 col-sm-3 col-md-3"
    (click)="wipeAdvancedSearchOptions()"
  >
    Reset
  </button>
  <div class="col-xs-2 col-sm-2 col-md-2"></div>
  <button
    mat-raised-button
    class="advancedSearchDoneButton col-xs-5 col-sm-3 col-md-3"
    (click)="bundleMasterObject(); dialogRef.close(advancedSearchFilterOptionsBundle)"
    [disabled]="checkMandatoryCheckboxes()"
  >
    Save Options
  </button>
</mat-dialog-actions>
<br />
<br />
<br />
<div class="row">
  <p class="hidden-xs advancedSearchHintText">
    Press 'Save Options' to save and close window. <br />
    After closing, click search button to apply advanced search selections.
  </p>
</div>
