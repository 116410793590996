<div class="row lockContentDesktop">
  <user-info></user-info>

  <div style="display: block; margin: auto; margin-top: 5%">
    <div class="col-xs-3 col-xs-offset-2" style="text-align: center; margin-left: 10%">
      <img src="./assets/svgLib/sadTigerNew.svg" style="width: 70%" alt="Sad Tiger" />
    </div>

    <div class="col-xs-6" style="margin-top: -3%">
      <div class="alert alert-danger" style="text-align: center">
        <h1>Locked Out</h1>
        You are currently locked out of SIS. For more information on the holds of your account, please visit
        <a href="https://sis.rit.edu">sis.rit.edu</a>.
      </div>
    </div>
  </div>
</div>

<div class="lockContentMobile">
  <div class="row">
    <user-info></user-info>

    <div class="col-xs-12">
      <div class="alert alert-danger warningMobile" style="text-align: center">
        <h1>Locked Out</h1>
        You are currently locked out of SIS. For more information on the holds of your account, please visit
        <a href="https://sis.rit.edu">sis.rit.edu</a>.
      </div>
    </div>
  </div>

  <div class="row hidden-sm-up">
    <div class="col-xs-12" style="text-align: center; margin-bottom: 20px; margin-top: 20px">
      <img src="./assets/svgLib/sadTigerNew.svg" style="width: 50%" alt="Sad Tiger" />
    </div>
  </div>
</div>
