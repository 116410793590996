/**
 * Created by jtkisd on 10/30/17.
 */

export class FinalExamsObject {
  academicsFinalExams = null;

  constructor(data) {
    this.academicsFinalExams = data;
  }

  static update(newValue, object) {
    object.academicFinalExams = newValue.finalExams;

    return object;
  }
}
