/**
 * Created by kjsisd1 on 11/28/17.
 */
import { Component } from '@angular/core';

@Component({
  selector: 'star-wars',
  templateUrl: './star-wars.template.html',
  styleUrls: ['./star-wars.style.css'],
})
export class StarWarsComponent {
  stars = [];

  constructor() {
    // Sets the number of stars we wish to display
    const numStars = 100;

    // For every star we want to display
    for (let i = 0; i < numStars; i++) {
      let star = document.createElement('div');
      star.className = 'star';
      var xy = this.getRandomPosition();
      star.style.position = 'absolute';
      star.style.top = xy[0] + 'px';
      star.style.left = xy[1] + 'px';
      this.stars.push(xy);
    }
  }

  // Gets random x, y values based on the size of the container
  getRandomPosition() {
    var y = window.innerWidth;
    var x = window.innerHeight;
    var randomX = Math.floor(Math.random() * x);
    var randomY = Math.floor(Math.random() * y);
    return [randomX, randomY];
  }
}
